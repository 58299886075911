import React, { useEffect, useRef, useState } from "react";
import * as S from "./GenerativeAI.style";
import * as P from "../../../utils/pipes";
import { useTranslation } from "react-i18next";

// Components
import ChatbotGeneralCase from "./components/cases/chatbotGeneralCase";
import ChatbotPatientCase from "./components/cases/chatbotPatientCase";
import ChatbotProcessCase from "./components/cases/chatbotProcessCase";
import ChatbotGuidanceCase from "./components/cases/chatbotGuidanceCase";
import { useStateValue } from "../../../contextAPI/StateProvider";
import useRecordNavigation from "../../../hooks/useRecordNavigation";
import { Actions } from "../../../utils/actionsIds";

// assets
const florenceLogo = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/monaco-ai.svg`


function GenerativeAI({
  closeFunc,
  maxHeight,
}) {    
  const {t, i18n: { language }} = useTranslation("global");
  const { recordAction } = useRecordNavigation();

  const [{ 
    florenceView,
    florencePatient,
    florenceProcess,
    florencePartition
   }, dispatch] = useStateValue();


  const chatbotViews = [
    {
      id: 1,
      name: t("chatbotAI.general.title"),
      description: t("chatbotAI.general.subtitle"),
      action: Actions.FLORENCE.CHAT_GENERAL
    },
    {
      id: 2,
      name: t("globally.patients"),
      description: t("chatbotAI.patients.subtitle"),
      action: Actions.FLORENCE.CHAT_PATIENTS
    },
    {
      id: 3,
      name: t("chatbotAI.guidelines.title"),
      description: t("chatbotAI.guidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_GUIDES
    },
    {
      id: 4,
      name: t("chatbotAI.patientsGuidelines.title"),
      description: t("chatbotAI.patientsGuidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_GUIDES_AND_PATIENTS
    }
  ]

  const changeView = (view) => {
    dispatch({
      type: "SET_FLORENCE_VIEW",
      florenceView: view,
    });
    selectPatient();
    selectProcess();
    selectPartition();
  } 

  const selectPatient = (patient) => {
    dispatch({
      type: "SET_FLORENCE_PATIENT",
      florencePatient: patient,
    });
  }

  const selectProcess = (process) => {
    dispatch({
      type: "SET_FLORENCE_PROCESS",
      florenceProcess: process,
    });
  }

  const selectPartition = (partition) => {
    dispatch({
      type: "SET_FLORENCE_PARTITION",
      florencePartition: partition,
    });
  }

  return (
    <S.GenerativeAI>
      {
        florencePartition &&
        <div className="partition-selected">
          <div className="partition-selected__container">
            <div className="partition-selected__point"></div>
            <div className="partition-selected__text">
              {florencePartition.text}
            </div>
          </div>
        </div>
      }
      <div className="chatbot">
        <Header 
          closeFunc={closeFunc}
          setChatbotViewSelected={changeView}
          chatbotViewSelected={florenceView}
          chatbotViews={chatbotViews}
          processNameSelected={P.dataTranslation(florenceProcess?.processName, language)}
        />
        <div className="chat-container">
          {
            florenceView === 0 &&
            <ChatbotChooseOptions
              setChatbotViewSelected={changeView}
              chatbotViews={chatbotViews}
            />
          }
          {
            florenceView === 1 &&
            <ChatbotGeneralCase/>
          }
          {
            florenceView === 2 &&
            <ChatbotPatientCase
              selectedPatient={florencePatient}
              selectPatient={selectPatient}
            />
          }
          {
            florenceView === 3 &&
            <ChatbotProcessCase
              selectedProcess={florenceProcess}
              selectedPartition={florencePartition}
              selectProcess={selectProcess}
              selectPartition={selectPartition}
            />
          }
          {
            florenceView === 4 &&
            <ChatbotGuidanceCase
              selectedPatient={florencePatient}
              selectedProcess={florenceProcess}
              selectedPartition={florencePartition}
              selectPatient={selectPatient}
              selectProcess={selectProcess}
              selectPartition={selectPartition}
            />
          }
        </div>
      </div>
    </S.GenerativeAI>
  );

}

const Header = ({ 
  closeFunc, 
  setChatbotViewSelected,
  chatbotViewSelected,
  chatbotViews,
  processNameSelected
}) => {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.HeaderDiv>
      <div className="header">
        <div className="header__title">
          <img className="header__title-logo" src={florenceLogo} alt="Florence" />
          <div className="header__subtitle">{chatbotViews.find((item) => item.id === chatbotViewSelected)?.name}</div>
          {
            processNameSelected &&
            <div className="header__process-name">
              {processNameSelected}
            </div>
          }
        </div>
        <div className="header__right">
          {
            chatbotViewSelected !== 0 &&
            <div className="header__reset-button" onClick={() => setChatbotViewSelected(0)}>{t("chatbotAI.reset")}</div>
          }
          <div className="header__close-chat" onClick={() => closeFunc(false)}>
            <i className="header__close-chat-icon icon zaia-icono-cerrar" />
          </div>
        </div>
      </div>
    </S.HeaderDiv>
  );
};

const ChatbotChooseOptions = ({ 
  chatbotViews, 
  setChatbotViewSelected 
}) => {
  const { recordAction } = useRecordNavigation();

  return (
    <S.ChatbotChooseOptions>
      <div className="view-options">
        {
          chatbotViews.map(
            (view) => {
              return(
                <div className="view-option">
                  <button 
                    className="view-option__button" 
                    onClick={() => {
                      recordAction(view.action);
                      setChatbotViewSelected(view.id)
                    }}
                  >
                    {view.name}
                  </button>
                  <div className="view-option__description">
                    {view.description}
                  </div>
                </div>
              )
            }
          )
        }
      </div>
    </S.ChatbotChooseOptions>
  );
};

export default GenerativeAI;
