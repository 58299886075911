import React, { useMemo, useRef, useState, useEffect } from "react";
import * as S from  "./AgendaFilterPanel.style";
import { useTranslation } from "react-i18next";

// ContextAPI
import { useStateValue } from "../../../../../contextAPI/StateProvider";

// Pipes
import * as P from '../../../../../utils/pipes';
import Modal from '../../../../../utils/modal';
import { Actions } from "../../../../../utils/actionsIds";

// Hooks
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// GraphQL
import { useLazyQuery } from "@apollo/client";
import { QUERY_GET_USERS_BY_FILTER } from "../../../../../graphql/queries/User";
import UserSelectorPanel from "../../../shared/userSelectorPanel";
import CalendarDashboard from "../../../dashboard/components/calendarDashboard";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import RangeDatesSelectorPanel from "../../../shared/rangeDatesSelectorPanel";
import ModalAddEventCalendar from "../modalAddEventCalendar";
import ModalRangeCalendar from "../../../shared/modals/modalRangeCalendar";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient";



const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const defaultPatientImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`

function AgendaFilterPanel ({
  eventTypeSelected,
  setEventTypeSelected,
  initialDateRange, 
  setInitialDateRange,
  finishDateRange, 
  setFinishDateRange,
  eventTypeList
}){
  const { t, i18n: { language } } = useTranslation("global");
  const cardSelectedPatient = useRef(null);
  const container = useRef(null);
  const textInput = useRef(null);
  const filtersContainer = useRef(null);
  const filtersContent = useRef(null);
  const { recordAction } = useRecordNavigation();
  const { selectPatient, removePatient } = useAssignSelectPatient();
  const [filtersContainerHeight, setFiltersContainerHeight] = useState(0);
  const [filtersContentHeight, setFiltersContentHeight] = useState(0);

  const [{ selectedPatient }, dispatch] = useStateValue();
  const [activeinput, setActiveinput] = useState(false);
  const [inputText, setInputText] = useState('');
  const [alertTypeFilterCollapsed, setAlertTypeFilterCollapsed] = useState(false);
  const [cardSelectedPatientHeight, setCardSelectedPatientHeight] = useState(0);
  const [rangeDatesFilterActive, setRangeDatesFilterActive] = useState(false);

  const [currentMonth, setCurrentMonth] = useState(new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth(), 1, 0,0,0));
  const [initialDate, setInitialDate] = useState();
  const [finishDate, setFinishDate] = useState(new Date(Date.now()));
  const [overflowActive, setOverflowActive] = useState(false);
  const { height, width } = useWindowDimensions();  

  const [createEventCalendarActive, setCreateEventCalendarActive] = useState(false);
  const [filterOptionsActive, setFilterOptionsActive] = useState(null);
  const [activeModalPatient, setActiveModalPatient] = useState(false);

  // const [initialDateRange, setInitialDateRange] = useState();
  // const [finishDateRange, setFinishDateRange] = useState(new Date(Date.now()));

  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setActiveinput(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const [ getPatients ] = useLazyQuery(
    QUERY_GET_USERS_BY_FILTER
  );

  const activeInput = () => {
    textInput.current.focus(); 
    setActiveinput(true);
  } 

  useEffect(() => {
    if ( inputText.length > 1 && activeinput) {
      setFilterOptionsActive(0);
    }
  }, [inputText]);

  const deletePatientSelected = () => {
    removePatient();
    getPatients(
      {
        variables: {
          text: ``,
        }
      }
    );
  }

  useEffect(() => {
    setCardSelectedPatientHeight(cardSelectedPatient.current?.clientHeight);
  }, [selectedPatient, cardSelectedPatient, filterOptionsActive]);

  useEffect(() => {
    setFiltersContainerHeight(filtersContainer.current?.clientHeight);
    setFiltersContentHeight(filtersContent.current?.clientHeight);
  }, [filtersContainer.current?.clientHeight, filtersContent.current?.clientHeight, height]);

  useEffect(() => {
    if((filtersContainerHeight - 20) > filtersContentHeight) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false)
    }
  }, [filtersContainerHeight, filtersContentHeight]);

  const clearRangeDateFilter = () => {
    setInitialDateRange(null);
    setFinishDateRange(null);
  }

  const clearAllFilters = () => {
    clearRangeDateFilter();
    setInitialDateRange();
    setFinishDateRange();
    setEventTypeSelected(0);
  }

  return(
    <>
    <S.AgendaFilterPanel
      patientSelected={selectedPatient ? true : false}
      panelActive={filterOptionsActive !== null ? true : false}
      cardSelectedPatientHeight={cardSelectedPatientHeight}
      overflowActive={overflowActive}
    >
      <Modal open={activeModalPatient}>
        <ModalSelectPatient
          setModalActive={setActiveModalPatient}
          setPatient={(patient) => selectPatient(patient.id)}
          idAction={Actions.AGENDA.SELECT_PATIENT}
        />
      </Modal>
      <Modal open={filterOptionsActive}>
        <ModalRangeCalendar
          setSeeDoubleCalendarRangeActive={setFilterOptionsActive}
          initialDateRange={initialDateRange}
          finishDateRange={finishDateRange}
          setInitialDateRange={setInitialDateRange}
          setFinishDateRange={setFinishDateRange}
        />
      </Modal>
      <Modal open={createEventCalendarActive}>
        <ModalAddEventCalendar
          setCreateEventCalendarActive={setCreateEventCalendarActive}
          patientID={selectedPatient?.id}
        />
        {/* <ModalAddOutcomesEvent
          setCreateEventCalendarActive={setCreateEventCalendarActive}
          patientID={selectedPatient?.id}
        /> */}
        {/* <CreateInvitePatientModal
          setCreateInvitePatientActive={setCreateEventCalendarActive}
        /> */}
      </Modal>
      <div className="panel">
        <div className="panel__container">
          <div className="panel__content">
            {/* <div className={`filter-patient ${activeinput && "filter-patient--focus"}`}
              onClick={() => activeInput()} ref={container}
            >
              <input 
                className={`filter-patient__input ${activeinput && "filter-patient__input--focus"}`}
                ref={textInput} 
                type="text" 
                placeholder={t("filterPanel.filterByPatient")} 
                onChange={(event) => setInputText(event.target.value)}
              />
              <i className={`filter-patient__icon icon zaia-search ${activeinput && "filter-patient__icon--focus"}`}></i>
            </div> */}
            {/* {
              selectedPatient &&
              <div className="filter-patient-selected" ref={cardSelectedPatient}>
                <div className="patient-info">
                  <div className="close-button" onClick={() => deletePatientSelected()}>
                    <i className="close-button__icon icon zaia-icono-cerrar"/>
                  </div>
                  <div className="patient-info__text">
                    <div className="patient-info__label">{t("globally.filterByedLabel")}</div>
                    <div className="patient-info__name">{selectedPatient?.name + ' ' + selectedPatient?.lastname}</div>
                    
                    <div className="patient-info__process-space"></div>
                    <div className="patient-info__process">
                      {
                        selectedPatient?.userToProcesses.length > 1 && 
                        <>
                          {t("globally.variousProcesses")}
                          <br/>
                        </>
                      }
                      {
                        selectedPatient?.userToProcesses.filter((item) => item.process).map((userToProcess, index) => {
                          return (
                            <>
                              {P.dataTranslation(userToProcess.process.processName, language)}
                              <br/>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            } */}
            {
              !selectedPatient &&
              <div className="patient-selector" onClick={() => setActiveModalPatient(true)}>
                <div className="patient-selector__box-icon">
                  {
                    defaultPatientImg &&
                    <img src={defaultPatientImg} alt="user" className="patient-selector__icon" />
                  }
                </div>
                <div className="patient-selector__text">
                  {t("filterPanel.filterByPatient")}
                </div>
              </div>
            }
            { 
              selectedPatient &&
              <div
                className="filter-patient-selected"
                ref={cardSelectedPatient}
              >
                <img src={selectedPatient?.picture} alt="" className="filter-patient-selected__img" />
                <div className="filter-patient-selected__text-container">
                  <div className="filter-patient-selected__text">
                    <b>{t("globally.filterByedLabel") + " "}</b>
                    {selectedPatient?.name + ' ' + selectedPatient?.lastname}
                  </div>  
                </div>
                <div className="filter-patient-selected__close" onClick={() => deletePatientSelected()}>
                  <i className="filter-patient-selected__icon icon zaia-icono-cerrar"/>
                </div>
              </div>
            }
            <div className="all-filters" >
              <div className="button-container">
                <button 
                  className="create-event-button" 
                  onClick={
                    () => {
                      setCreateEventCalendarActive(true);
                      recordAction(Actions.AGENDA.CREATE_EVENT);
                    }
                  }
                >
                  <div className="create-event-button__text">{t("globally.createEvent")}</div>
                  <i className="create-event-button__icon icon zaia-icono-agregar"/>
                </button>
              </div>
              <div className="all-filters__header">
                <div className="title">
                  <i className="title__icon icon zaia-filter"/>
                  <div className="title__text">{t("globally.filterBy")}</div>
                </div>
                <div className="clear-button" 
                  onClick={() => clearAllFilters()}
                >
                  {t("globally.clear")}
                </div>
              </div>
              <div 
                className="filters-container"
                ref={filtersContainer}
              >
                <div 
                  className="filters-content"
                  ref={filtersContent}
                >
                  <div className="filter">
                    <div 
                      className="time-filter__trigger" 
                      // onClick={
                      //   () => {
                      //     setFilterOptionsActive(filterOptionsActive !== 1 ? 1 : null);
                      //     recordAction(Actions.AGENDA.FILTERS);
                      //   }
                      // }
                    >
                      <Trigger
                        isActive={filterOptionsActive === 1}
                        title={t("globally.filterBy") + " " + t("filterPanel.periodOfTime")}
                        dataSelected={
                          initialDateRange && finishDateRange 
                          ? `${P.shortDate(initialDateRange, t, true, language)} - ${P.shortDate(finishDateRange, t, true, language)}`
                          : ""
                        }
                        clearFilter={clearRangeDateFilter}
                        openFilter={
                          () => {
                            setFilterOptionsActive(filterOptionsActive !== 1 ? 1 : null);
                            recordAction(Actions.AGENDA.FILTERS);
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="filter">
                    <div className="filter__title-collapsible" onClick={() => setAlertTypeFilterCollapsed(!alertTypeFilterCollapsed)}>
                      <div className={`filter__title-collapsible-text ${alertTypeFilterCollapsed && "filter__title-collapsible-text--expanded"}`}>{t("filterPanel.statusType")}</div>
                      <i className={`filter__title-collapsible-icon icon zaia-icono-dropdown-light ${alertTypeFilterCollapsed && "filter__title-collapsible-icon--expanded"}`}/>
                    </div>
                    { alertTypeFilterCollapsed &&
                      <div className="filter__content-collapsible">
                        <div className="filter__content-collapsible-items-list">
                          {
                            eventTypeList.map((alertType, index) => {
                              return (
                                <div 
                                  className={`filter-item ${alertType.id === eventTypeSelected && "filter-item--selected"}`} 
                                  key={index} 
                                  onClick={
                                    () => {
                                      setEventTypeSelected(alertType.id);
                                      recordAction(Actions.AGENDA.FILTERS);
                                    }
                                  }
                                  style={{
                                    color: alertType.color
                                  }} 
                                >
                                  <div className={`filter-item__point ${alertType.id === eventTypeSelected && "filter-item__point--selected"}`} 
                                    style={{
                                      backgroundColor: alertType.color
                                    }} 
                                  />
                                  <div className="filter-item__name">{alertType.name}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                      // : <div className="filter__items-selected-list">
                      //   {
                      //     eventTypeSelected === 0 &&
                      //     eventTypeList.slice(1).map((alertType, index) => {
                      //       return (
                      //         <div className="selected-item" key={index}>
                      //           <div className="selected-item__name">{alertType.name}</div>
                      //         </div>
                      //       )
                      //     })
                      //   }
                      //   {
                      //     (eventTypeSelected !== 0)&&
                      //     <div className="selected-item">
                      //       <div className="selected-item__name">
                      //         {
                      //           eventTypeList.find(alertType => alertType.id === eventTypeSelected)?.name
                      //         }
                      //       </div>
                      //     </div>
                      //   }
                      // </div>
                    }
                  </div>
                  <div className="filter-separator"/>
                  <div 
                    className="filter filter--calendar-container"
                  >
                    <CalendarDashboard
                      initialDate={initialDateRange}
                      setInitialDate={setInitialDateRange}
                      finishDate={finishDateRange}
                      setFinishDate={setFinishDateRange}
                      currentMonth={currentMonth}
                      setCurrentMonth={setCurrentMonth}
                      titleColor="#3E3DA3"
                      arrowsColor="#59599E"
                      textColor="#526479"
                      currentDayColor="#6C6BCC"
                      backgroundColor="#F8F8FF"
                      fontSizeCalendar={!overflowActive ? "7.5px" : "8.2px"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="panel__right-filter-options">
            {
              filterOptionsActive === 0 &&
              <UserSelectorPanel
                setFilterOptionsActive={setFilterOptionsActive}
                inputText={inputText}
              />
            }
            {
              (filterOptionsActive === 1 && width > 1363) &&
              <RangeDatesSelectorPanel
                setFilterOptionsActive={setFilterOptionsActive}
                initialDateRange={initialDateRange}
                finishDateRange={finishDateRange}
                setInitialDateRange={setInitialDateRange}
                setFinishDateRange={setFinishDateRange}
              />
            }
          </div>   */}
        </div>
      </div>
    </S.AgendaFilterPanel>
    </>
  );
}

function Trigger({
  title,
  dataSelected = "1232/12/12 - 1234/12/12",
  clearFilter,
  openFilter
}) {

  const { t, i18n: { language } } = useTranslation("global");

  return (
    <S.Trigger
      dataSelected={dataSelected}
    >
      <div className="trigger" onClick={openFilter}>
        <div className="trigger-title">{title}</div>
        <div className="trigger-data-selected">
          {dataSelected}
        </div>
      </div>
      {
        dataSelected &&
        <div 
          className="trigger-clear-button"
          onClick={clearFilter}
        >{t("globally.clear")}</div>
      }
    </S.Trigger>
  )
}

export default AgendaFilterPanel