import { gql } from "@apollo/client";

export const QUERY_GET_USERS = gql`
  query QUERY_GET_USERS {
    user (
      where: {
        userToProcesses: { 
          isAcepted: {_eq: true}, 
          process: {
            nameKey: {
              _neq: "Police-Homeless"
            }
          } 
        } 
      }
    ) {
      id
      name
      lastname
      picture
      id
      documentNumber
      email
      userToProcesses {
        id
        nextUpdate
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USER_BY_EMAIL = gql`
  query QUERY_GET_USER_BY_EMAIL($emailPatient: String) {
    user(where: {email: {_eq: $emailPatient}}) {
      birthdate
      email
      documentNumber
      documentType
      createdAt
      countryPhoneId
      id
      lastname
    }
  }
`

export const QUERY_GET_USERS_BY_USER_ID = gql`
  query QUERY_GET_USERS_BY_USER_ID($patientId: uuid) {
    user(
      where: {
        id: { _eq: $patientId }
      }
    ) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      gender
      email
      clinicalHistoryPdfUrl
      enDocumentType {
        comment
      }
      userToProcesses {
        id
        isChecked
        nextUpdate
        descript
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          nameKey
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USERS_BY_FILTER = gql`
  query QUERY_GET_USERS_BY_FILTER($text: String) {
    user(
      limit: 10
      where: {
        _or: [
          { name: { _ilike: $text } }
          { lastname: { _ilike: $text } }
          { email: { _ilike: $text } }
        ],
        userToProcesses: {process: {nameKey: {_neq: "Police-Homeless"}}}
      }
    ) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      clinicalHistoryPdfUrl
      phoneCountry {
        phonecode
      }
      carerPhoneCountry {
        phonecode
      }
      enDocumentType {
        comment
      }
      userToProcesses {
        id
        isChecked
        nextUpdate
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USERS_BY_FILTER_POLICE = gql`
  query QUERY_GET_USERS_BY_FILTER($text: String) {
    user(
      limit: 10
      where: {
        _or: [
          { name: { _ilike: $text } }
          { lastname: { _ilike: $text } }
          { email: { _ilike: $text } }
          { userToProcesses: {
            descript: { _ilike: $text }
          }}
        ],
        userToProcesses: {process: {nameKey: {_eq: "Police-Homeless"}}, isAcepted: {_eq: true}}
      }
    ) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      clinicalHistoryPdfUrl
      phoneCountry {
        phonecode
      }
      carerPhoneCountry {
        phonecode
      }
      enDocumentType {
        comment
      }
      userToProcesses(
        where: {
          process: {nameKey: {_eq: "Police-Homeless"}}
        }
      ) {
        id
        isChecked
        nextUpdate
        descript
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USER_BY_PROCESS = gql`
  query QUERY_GET_USER_BY_PROCESS($processId: Int) {
    user(where: { userToProcesses: { processId: { _eq: $processId }, isAcepted: {_eq: true} } }) {
      name
      lastname
      picture
      id
      email
      documentNumber
      gender
      userToProcesses {
      descript
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USERS_WITH_PROCESS = gql`
  query QUERY_GET_USERS_WITH_PROCESS {
    user(where: {userToProcesses: {isAcepted: {_eq: true}}}) {
      name
      lastname
      picture
      id
      email
      documentNumber
      gender
      userToProcesses {
        descript
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USER_BY_ID_AND_PROCESS_ID = gql`
  query QUERY_GET_USER_PERSONAL_INFO($patientId: uuid, $processId: Int) {
    user(
      where: {
        id: { _eq: $patientId }
        userToProcesses: { processId: { _eq: $processId }, isAcepted: {_eq: true} }
      }
    ) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      userToProcesses {
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USER_BY_ID = gql`
  query QUERY_GET_USER_BY_ID($patientId: uuid) {
    user(
      where: {
        id: { _eq: $patientId }, 
        userToProcesses: { 
          isAcepted: {_eq: true}, 
          process: {
            nameKey: {
              _neq: "Police-Homeless"
            }
          } 
        }
      }
    ) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      clinicalHistoryPdfUrl
      enDocumentType {
        comment
      }
      userToProcesses {
        id
        isChecked
        nextUpdate
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          nameKey
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USER_BY_LAST_SYMPTOM = gql`
  query QUERY_GET_USER_BY_LAST_SYMPTOM {
    user(where: {userToProcesses: {isAcepted: {_eq: true}}}, limit: 5, order_by: {userToSideEffects_aggregate: {count: desc}}) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      userToProcesses {
        id
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
      userToSideEffects(order_by: {date: desc}, limit: 1) {
        date
        id
        modifiedDate
        severity
        typeSideEffect
        typeSideEffectVal {
          comment
          value
          groupSideEffect
        }
      }
    }
  }
`;