import styled from 'styled-components';

export const PatientDashboardView = styled.div`
  height: 100%;
  width: 100%;
  background-color: #F2F2FA;
  .header-patient-container {
    /* padding: 20px 30px 25px; */
  }
  .ainavigator-content {
    height: calc(100% - 65.44px);
    border-top: 1px #D4DFF9 solid;
    display: flex;
    position: relative;
    z-index: 200;
    width: 100%;
    position: relative;
    position: relative;
    /* padding: 0px 33px 41px 33px; */
    box-sizing: border-box;
  }
  .ainavigator-content__box {
    border-radius: 14px;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .section-container {
      padding: 20px;  
      &__title {
        display: flex;
        margin-bottom: 21px;
        color: #3E3DA3;
        align-items: center;
        &-icon {
  	      opacity: 0.4;
          margin-right: 6px;
          font-size: 19px;
        }
        &-text {
          font-family: "Nunito Sans Bold";
          font-size: 24px;
        }
      }
    }
    .ainavigator-content__filters-box {
      display: flex;
      justify-content: space-between;
    }
    .ainavigator-content__filters {
      display: flex;
      z-index: 1;
      /* position: fixed; */
      border-radius: 103px;
      border: 1px solid #6C6BCC;
      justify-content: space-between;
      background-color: #FFFFFF;
      padding: 5px;
      margin: 10px 20px;
      width: fit-content;
    }
    .ainavigator-content__button-add-data {
      .button-add-data {
        margin: 11px 20px;
        cursor: pointer;
        display: flex;
        width: 173px;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #6C6BCC;
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 20px;
        &__icon {          
          color: #FFF;
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
    .specific-date-selector-container {
      align-items: center;
      display: flex;
    }
    .specific-date-selector {
      color: #36369B;
      margin: 0px 10px;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      font-family: "Nunito Sans";
      &_date {
        align-items: flex-end;
        strong {
          margin: 0 4px;
          font-size: 0.75rem;
        }
      }
      i {
        color: #6C6BCC; 
        margin-left: 5px;
        margin-top: 2px;
        display: flex;
      }   
      /* z-index: 1;
      position: fixed;
      display: flex;
      justify-content: center; */
      /* right: 600px; */
    }
    .time-filters {
      display: flex;
      .time-filter {
        cursor: pointer;
        border-radius: 60px;
        font-size: 14px;
        padding: 5px 10px;
        /* border: 1px solid #AAAADB; */
        color: #36369B;
        margin: 0 5px;
        background-color: #FFFFFF;
        &:hover {
          /* background-color: #E0E6ED;
          border: 1px transparent solid; */
        }
        &--selected {
          background-color: #36369B;
          border: 1px solid #36369B;
          color: #FFFFFF;
          &:hover {
            background-color: #36369B;
          }
        }
      }
     }
  }
`