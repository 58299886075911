import React, { useMemo } from "react";
import * as S from "./CmpRadioListInfo.style";
import { useTranslation } from "react-i18next";

//Components
import CheckboxRound from "../../../checkboxRound"

// pipes
import * as P from "../../../../../../../utils/pipes";

function CmpRadioListInfo({
  title,
  item,
  insertfunction,  
  customFormChangesList,
  customFormChangeItem,
  customFormFields
}) {

  const {i18n: { language }} = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  // const changeOptionItem = (fieldOption) => {
  //   const userToCustomFormField = {
  //     customFormFieldId: item?.id,
  //     parentKey: item?.customFormTypeField.fieldOptions.find(field => field.key === fieldOption.key).parentKey,
  //     customFormFieldParentId: item?.customFormFieldParentId,
  //     value: P.dataTranslation(fieldOption.names, language),
  //     key: fieldOption.key
  //   }
  //   insertfunction(userToCustomFormField)
  // }

  const handleFunction = (optionKey) => {
    const userToCustomFormField = {
      customFormFieldId: item.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === optionKey
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(
        item?.customFormTypeField.fieldOptions.find(
          (field) => field.key === optionKey
        ).names,
        language
      ),
      key: optionKey,
    };
    insertfunction(userToCustomFormField);
  };

  const listOptions = useMemo(() => {
    let options = item?.customFormTypeField.fieldOptions;
    if (item?.customFormFieldParentId) {
      const parentKey = itemParent
        ? itemParent.key
        : customFormFields.find(
            (field) => field.id === item.customFormFieldParentId
          )?.userToCustomFormFields[0]?.key;
      options = options.filter((field) => field.parentKey === parentKey);
    }
    return options;
  } ,[item, itemParent, customFormFields])

  return (
    <S.CmpRadioListInfo>
      <div className="title">{P.dataTranslation(item?.fieldName, language)}</div>
      <div className="list-scores-container">
        {
          listOptions.map(data => {
            return <div className="score-item" key={data.id}> 
              <CheckboxRound
                fontSize="13px"
                checkFunction={() => handleFunction(data.key)}
                selectedItemId={customFormChangeItem ? customFormChangeItem.key : item?.userToCustomFormFields ? item?.userToCustomFormFields[0]?.key : null}
                itemCheckbox={{
                  id: data.key,
                  name: P.dataTranslation(data.names, language)
                }}
              />
            </div>
          })
        }
      </div>
    </S.CmpRadioListInfo>
  );
}

export default CmpRadioListInfo;
