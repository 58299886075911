import styled from "styled-components";

export const ZaiaSelect = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  opacity: ${({disabled}) => disabled ? "0.5" :'1'};
  .input-container {
    cursor: ${({disabled}) => disabled ? 'default' :'pointer'}; ;
    border: none;
    padding: ${({padding}) => padding ? padding :'7px 7px'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : "8px"};
    display: flex;
    align-items: center;
    min-width: 100px;
    background-color: ${({theme, backgroundColor}) => backgroundColor ? backgroundColor : theme.inputBackground};
    border: 1.8px solid ${
      ({ 
        theme, 
        active, 
        activeBorder,
        borderColor,
        error
      }) => 
        {
          if(activeBorder) {
            if (borderColor) {
              return borderColor;
            } else {
              if (active) {
                return theme.inputSelected;
              } else {
                if (error?.error && error?.touched) {
                  return theme.warning;
                } else {
                  return theme.inputBorder;
                }
              }
            }
          } else {
            return 'transparent';
          }
        }
    };
    .icon-input-form {
      font-size: 14px;
      margin-right: 5px;
    }
    input {
      color: ${({theme, textColor}) => textColor ? textColor : theme.inputText};
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Nunito Sans";
      cursor: ${({disabled}) => disabled ? 'default' :'pointer'};
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      text-overflow: ellipsis;
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme, placeholderTextColor }) => placeholderTextColor ? placeholderTextColor: theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Nunito sans";
      }
    }
    .icon-input-form-arrow {
      /* margin-bottom: ${({listActive}) => listActive ? '2px' : '-5px'};; */
      padding-left: 10px;
      color: ${({ theme, placeholderTextColor, arrowColor }) => arrowColor ? arrowColor : placeholderTextColor ? placeholderTextColor: theme.inputPlaceholder};
      font-size: 12px;
      transition: .3s ease;
      transform: ${({listActive}) => listActive ? 'rotate(180deg)' : 'none'};
      margin-left: auto;
    }
  }
  .select-list {
    position: absolute;
    background-color: ${({theme}) => theme.inputBackground};
    z-index: 20;
    display: ${({listActive}) => listActive ? 'block' :'none' };
    width: 100%;
    max-height: ${({heightList}) => heightList };
    /* max-height: 100px; */
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    font-family: "Nunito Sans";
    font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
    .select-item {
      cursor: pointer;
      padding: 3px 7px;
      label {
        cursor: pointer;
      }
    }
    color: ${({theme, textColor, textItemsColor}) => textItemsColor ? textItemsColor : textColor ? textColor : theme.inputText};
    /* ::-webkit-scrollbar{ */
      /* display: none;
    } */
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    display: flex;
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
`