import { useStateValue } from "../contextAPI/StateProvider";
import { useHistory } from "react-router-dom";

// Utils
import { Routes } from "../utils/constants";

export default function useDoctorData() {
  const [{ doctorData }, dispatch] = useStateValue();
  const history = useHistory();
  const { HOME } = Routes;

  const saveDoctorData = (doctor) => {
    if( doctor ) {
      dispatch({
        type: "SET_DOCTOR_DATA",
        doctorData: doctor,
      });        
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("apollo-cache-persist");
      dispatch({
        type: "SET_AUTH",
        isAuth: false,
      });
      history.push(HOME.route);
    }
  }

  return {
    doctorData: doctorData,
    saveDoctorData: saveDoctorData
  };
}