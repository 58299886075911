import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalAddSurveyData.style";
import * as P from "../../../../../utils/pipes";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { MUTATION_UPDATE_CUSTOM_FORMS, MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../graphql/queries/UserToProcess";

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";
import usePatientList from "../../../../../hooks/usePatientList";
import GenericTemplate from "../../../outcomes/components/outcomesComponents/shared/genericTemplate";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { QUERY_GET_SURVEYS_BY_IDS} from "../../../../../graphql/queries/Surveys";

function ModalAddSurveyData({ 
  patientID,
  processID,
  surveyId,
  setFormSuccess,
  setLoadingData
}) {

  const {patientList} = usePatientList()

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const [editUserToProcess, {loading: editUserToProcessLoading, error: editUserToProcessError}] = useMutation(
    MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS
  );

  const [addSurveysData, {loading: addSurveysDataLoading}] = useMutation(
    MUTATION_UPDATE_CUSTOM_FORMS
  );
  

  const [surveySelected, setSurveySelected] = useState(surveyId);
  const [processSelected, setProcessSelected] = useState(processID);
  const [patientSelected, setPatientSelected] = useState(patientID);
  const [descriptionSelected, setDescriptionSelected] = useState("");
  const [dateSelected, setDateSelected] = useState(P.formatNumberDateInput(new Date(Date.now())));
  const [timeSelected, setTimeSelected] = useState(P.formatNumberTimeInput(new Date(Date.now())));

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected },
  });


  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError,
  } = useQuery(QUERY_GET_SURVEYS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: surveySelected,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });


  useEffect(() => {
    setSurveySelected(surveyId)
  }, [surveyId]);

  const [customFormChangesList, setCustomFormChangesList] = useState([]);

  const insertFormField = async (e, cmpId) => {
    let tempList = customFormChangesList.filter(
      (item) =>
        item.customFormFieldId !== e.customFormFieldId &&
        (item.customFormFieldParentId === e.customFormFieldId
          ? item.parentKey === e.key
          : true)
    );
    setCustomFormChangesList([...tempList, e]);
  };

  const handleSubmit = async () => {

    if(patientSelected && processSelected && surveySelected ) {
      const objectCustomFormChanges = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
        return {
          customFormFieldId: item.customFormFieldId,
          value: item.value,
          key: item.key,
          doctorId: doctorData.id,
          userId: patientSelected,
        };
      });
  
      const changesString = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
        if (item.value instanceof Date)
          return P.shortDate(item.value, t, true, language);
        else return item.value;
      });
  
      editUserToProcessFunction(
        changesString,
        objectCustomFormChanges
      );
    }
  };

  const editUserToProcessFunction = async (
    changesString,
    objectCustomFormChanges
  ) => {

    try {
      await addSurveysData({
        variables: {
          doctorId: doctorData.id,
          userId: patientSelected, 
          customFormId: surveySelected,
          objectsCustomForms: objectCustomFormChanges,
        },
      });
      setSurveySelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(true);
    } catch (err) {
      setSurveySelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(false);
    }
  }

  useEffect(() => {}, [surveySelected, descriptionSelected]);


  useEffect(() => {
    setLoadingData(userToProcessDataLoading || customFormLoading || editUserToProcessLoading);
  }, [
    userToProcessDataLoading,
    customFormLoading,
    editUserToProcessLoading
  ])

  return (
    <S.ModalAddSurveyData>
      {/* <div className="message">{t("surveys.fillOutSurveyModal.note")}</div> */}
      <div className="rows-container">
        <div className="rows">
            {
              (
                <div className="custom-form-inputs">
                  {
                    customForm?.customForm_by_pk.formGroups?.map(
                      (cmp) => {
                        // console.log("aquiiii", cmp);
                        return (
                          <GenericTemplate
                            title={P.dataTranslation(
                              cmp?.customFormName,
                              language
                            )}
                            dataForm={cmp}
                            noDefaultData={true}
                            insertfunction={(e) => insertFormField(e, cmp.id)}
                            customFormChangesList={customFormChangesList}
                          />
                        )
                      }
                    )
                  }
                </div>  
              )
            }
        </div>
      </div>
      <div className="footer">
        <div className="button-container">
          <div onClick={() => customForm?.customForm_by_pk.formGroups[0].customFormFields.length === customFormChangesList.length ? handleSubmit() : null}>
            <ZaiaButton
              title={t("globally.send")}
              backgroundColor={customForm?.customForm_by_pk.formGroups[0].customFormFields.length === customFormChangesList.length ? "#17AF54" : "#EEEDF4"}
              type="button"
              option="primary"
              marginTop="5"
              iconSize={"20"}
              iconMargin={"5"}
              borderRadius="75"
              fontSize="14"
              height="36"
              fontFamily={"Nunito Sans"}
              shadow={false}
              border={false}
              color={customForm?.customForm_by_pk.formGroups[0].customFormFields.length === customFormChangesList.length ? "#FFFFFF" : "#3E3DA3"}
              icon={customForm?.customForm_by_pk.formGroups[0].customFormFields.length === customFormChangesList.length ? "" : "zaia-i-lock-chat"}
              disabled={
                !patientSelected || 
                !processSelected || 
                !surveySelected ||
                customForm?.customForm_by_pk.formGroups[0].customFormFields.length !== customFormChangesList.length
                  ? true 
                  : false
              }
            />
          </div>
        </div>
      </div>
    </S.ModalAddSurveyData>
  );
}

export default ModalAddSurveyData;
