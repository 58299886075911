import React from "react";
import * as S from "./CmpNumberInput.style";
import SelectorForm from "../selectorForm";
import { useTranslation } from "react-i18next";
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Components
import Popup from "reactjs-popup";

function CmpNumberInput({
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  
  const { t, i18n: { language } } = useTranslation("global");

  const changeOptionItem = (value) => {
    const userToCustomFormField = {
      customFormFieldParentId: item?.customFormFieldParentId,
      customFormFieldId: item.id,
      value: value.toString(),
      key: value.toString(),
    }
    insertfunction(userToCustomFormField)
  }

  return (
    <S.CmpNumberInput>
      <div className="title">{item.fieldName && P.dataTranslation(item.fieldName, language)}
        <Popup
          trigger={
            <i className="template-header-item-icon icon zaia-alert"></i>
          }
          on={['hover', 'focus']}
          closeOnDocumentClick
          keepTooltipInside
          position={['right center']}
          nested
        >
          <PopUpPayload>
            <p>
            {P.dataTranslation(item.descript, language)}
            </p>
          </PopUpPayload>
        </Popup>
      </div>
      <div className="selection-container">
        <div className="selection-container__number-icon">
          #
        </div>
        {
          <SelectorForm 
            boxShadow={false}
            type='number'
            label={item.fieldName && P.dataTranslation(item.fieldName, language)}
            dataInput={ customFormChangeItem ? Number(customFormChangeItem.value) : item?.userToCustomFormField ? Number(item?.userToCustomFormFields[0]?.value) : null}
            handleSubmit={changeOptionItem}
            disabled={typeView === 'view'}
          /> 
        }
      </div>
    </S.CmpNumberInput>
  );
}

export default CmpNumberInput;
