import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as S from "./ModalAddOutcomesEvent.style";
import * as P from "../../../../../utils/pipes";
import { ThemeContext } from "styled-components";
import * as Yup from "yup";

// Components
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";
import { MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../graphql/queries/CustomForm";

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";
import usePatientList from "../../../../../hooks/usePatientList";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import GenericTemplate from "../../../outcomes/components/outcomesComponents/shared/genericTemplate";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { QUERY_GET_PROCESS_BY_ID, QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput";
import { MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD, MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD } from "../../../../../graphql/mutations/DoctorToUserProcessBoard";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const defaultUserImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`;

function ModalAddOutcomesEvent({ 
  setCreateEventCalendarActive,
  patientID,
  processID,
  cmpID,
}) {

  const {patientList} = usePatientList()

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const [editUserToProcess, {loading: editUserToProcessLoading}] = useMutation(
    MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS
  );

  const [cmpSelected, setCmpSelected] = useState(null);
  const [processSelected, setProcessSelected] = useState(processID);
  const [patientSelected, setPatientSelected] = useState(patientID);
  const [descriptionSelected, setDescriptionSelected] = useState("");
  const [dateSelected, setDateSelected] = useState(P.formatNumberDateInput(new Date(Date.now())));
  const [timeSelected, setTimeSelected] = useState(P.formatNumberTimeInput(new Date(Date.now())));
  const [formSuccess, setFormSuccess] = useState(null);

  const {data: process} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processSelected,
      },
    }
  );

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected },
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError,
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: process?.process[0].processToCustomForms[0]?.customFormId,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {
    setCmpSelected(
      customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === cmpID)
    )
  }, [cmpID]);

  const [customFormChangesList, setCustomFormChangesList] = useState([]);

  const insertFormField = async (e) => {
    let tempList = customFormChangesList.filter(
      (item) =>
        item.customFormFieldId !== e.customFormFieldId &&
        (item.customFormFieldParentId === e.customFormFieldId
          ? item.parentKey === e.key
          : true)
    );
    setCustomFormChangesList([...tempList, e]);
  };

  useEffect(() => {

    const customFormFieldsInitTemp = customForm?.customForm_by_pk.formGroups?.find(
      (cmp) => cmp.id === cmpSelected?.id
    )?.customFormFields.map(
      (item) => {
        const parentKey = item?.customFormTypeField?.fieldOptions.find(
          (field) => field.key === item?.userToCustomFormFields[0]?.key
        )?.parentKey;
        let value;
        let key;
        if(item?.customFormTypeField?.value === "dateInput") {
          value = item?.userToCustomFormFields[0]?.value ? new Date(item?.userToCustomFormFields[0]?.value) : new Date(Date.now());
          key = item?.userToCustomFormFields[0]?.key ? new Date(item?.userToCustomFormFields[0]?.key) : new Date(Date.now());
        } else {
          value = item?.userToCustomFormFields[0]?.value;
          key = item?.userToCustomFormFields[0]?.key;
        }
        return {
          customFormFieldId: item.id,
          parentKey: parentKey ? parentKey : null,
          customFormFieldParentId: item?.customFormFieldParentId,
          value: value,
          key: key,
        };
      }
    );

    setCustomFormChangesList(customFormFieldsInitTemp);
  }, [cmpSelected]);

  const handleSubmit = async () => {

    if(patientSelected && processSelected && cmpSelected ) {
      const objectCustomFormChanges = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
        return {
          customFormFieldId: item.customFormFieldId,
          value: item.value,
          key: item.key,
          doctorId: doctorData.id,
          userId: patientSelected,
        };
      });
  
      const changesString = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
        if (item.value instanceof Date)
          return P.shortDate(item.value, t, true, language);
        else return item.value;
      });
  
      editUserToProcessFunction(
        changesString,
        objectCustomFormChanges
      );
    }
  };

  const editUserToProcessFunction = async (
    changesString,
    objectCustomFormChanges
  ) => {
    try {
      await editUserToProcess({
        variables: {
          processId: processSelected,
          userId: patientSelected,
          doctorId: doctorData.id,
          stageId: "enroll",
          descript: descriptionSelected,
          customFormFieldChanges: changesString.map((item, index) => {return (index === 0 ? item : "*-,&" + item)}).toString(),
          latestCustomFormId: cmpSelected.id,
          customFormId: cmpSelected.id,
          // updated_at: new Date(Date.now())
          objectsCustomForms: objectCustomFormChanges,
        },
      });
      setCmpSelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(true);
    } catch (err) {
      setCmpSelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(false);
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setCreateEventCalendarActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {}, [cmpSelected, descriptionSelected]);

useEffect(() => {
  if(cmpSelected?.userToProcessHistories[0]?.descript) {
    setDescriptionSelected(cmpSelected?.userToProcessHistories[0]?.descript);
  } else {
    setDescriptionSelected("");
  }
}, [cmpSelected]);


  if(
    userToProcessDataLoading || customFormLoading || editUserToProcessLoading
  ) 
    return(
      <S.ModalAddOutcomesEvent>
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <ZaiaReaction
            widthImg={"150px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalAddOutcomesEvent>
    )

  if(formSuccess)
    return(
      <S.ModalAddOutcomesEvent>
        <div className="modal-background" onClick={() => setCreateEventCalendarActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setCreateEventCalendarActive(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            title={t("outcomes.modalOutcomes.successForm")}
          />
        </div>
      </S.ModalAddOutcomesEvent>
    )

  if(formSuccess === false)
    return(
      <S.ModalAddOutcomesEvent>
        <div className="modal-background" onClick={() => setCreateEventCalendarActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setCreateEventCalendarActive(null)}
            formSuccess={formSuccess}
            title={t("globally.errorForm")}
            subtitle={t("outcomes.modalOutcomes.errorForm")}
            button1Text={t("globally.accept")}            
          />
        </div>
      </S.ModalAddOutcomesEvent>
    )

  return (
    <S.ModalAddOutcomesEvent>
      <div className="modal-background" onClick={() => setCreateEventCalendarActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("outcomes.modalOutcomes.title")}</div>
            <div className="header__subtitle">{t("outcomes.modalOutcomes.subtitle1")} <strong>{t("outcomes.modalOutcomes.subtitle2")}</strong> {t("outcomes.modalOutcomes.subtitle3")}</div>
          </div>
          <div className="header__close" onClick={() => setCreateEventCalendarActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="rows-container">
          <div className="rows">
            <div className="principal-input">
              {/* <Formik
                initialValues={{
                  cmpId: "",
                  description: descriptionSelected,
                  name: null,
                  patient: patientSelected,
                  userToProcessId: null,
                }}
                validationSchema={
                  Yup.object().shape(
                    {
                      cmpId: Yup.string()
                        .required(),
                      description: Yup.string()
                        .required(),
                      patient: Yup.string()
                        .required(),
                      userToProcessId: Yup.string()
                        .required(),
                    },
                  )
                }
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <>
                    <form 
                      className="principal-input__form"
                      onSubmit={props.handleSubmit}> */}
                      <EventOutcomesInputs
                        patientID={patientID}
                        processID={processID}
                        cmpID={cmpID}

                        setCmpSelected={setCmpSelected}
                        cmpSelected={cmpSelected}

                        patientSelected={patientSelected}
                        setPatientSelected={setPatientSelected}
                        processSelected={processSelected}
                        setProcessSelected={setProcessSelected}
                        setDescriptionSelected={setDescriptionSelected}
                        descriptionSelected={descriptionSelected}
                        dateSelected={dateSelected}
                        setDateSelected={setDateSelected}
                        timeSelected={timeSelected}
                        setTimeSelected={setTimeSelected}
                      />
                    {/* </form>
                  </> */}
                {/* )}
              </Formik> */}
            </div>
              {
                // cmpSelected 
                // ? 
                (
                  <div className="custom-form-inputs">
                    <GenericTemplate
                      title={P.dataTranslation(
                        cmpSelected?.customFormName,
                        language
                      )}
                      dataForm={customForm?.customForm_by_pk.formGroups?.find(
                        (cmp) => cmp.id === cmpSelected?.id
                      )}
                      insertfunction={insertFormField}
                      customFormChangesList={customFormChangesList}
                    />
                  </div>  
                )
                // : null
              }
          </div>
        </div>
        <div className="button-container">
          <div onClick={() =>  handleSubmit()}>
            <ZaiaButton
              type="button"
              option="primary"
              marginTop="5"
              backgroundColor={"#17AF54"}
              title={"Create event"}
              // title={t("outcomes.tableOutcomes.modalNewEvent.add")}
              borderRadius="5"
              fontSize="32.25"
              height="64"
              fontFamily={"Nunito Sans"}
              shadow={false}
              disabled={
                !patientSelected || !processSelected || !cmpSelected ? true : false
              }
            />
          </div>
        </div>
      </div>
    </S.ModalAddOutcomesEvent>
  );
}

const EventOutcomesInputs = ({
  patientID,
  processID,
  cmpID,
  setCmpSelected,
  cmpSelected,
  patientSelected,
  setPatientSelected,
  processSelected,
  setProcessSelected,
  setDescriptionSelected,
  descriptionSelected,
  dateSelected,
  setDateSelected,
  timeSelected,
  setTimeSelected,
  // customFormsList
}) => {
  const { data: userData, error } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientSelected },
  });
  const { doctorData } = useDoctorData();
  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const [customFormsList, setCustomFormsList] = useState();
  const { t, i18n: { language } } = useTranslation("global");

  const {data: process, loading: processLoading, error: processError} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processSelected,
      },
    }
  );

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected }, 
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: process?.process[0]?.processToCustomForms[0]?.customFormId,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {
    if(customForm) {
      setCustomFormsList([...customForm?.customForm_by_pk.formGroups]);
    } else {
      setCustomFormsList([]);
    }
  }, [customForm])


  const {patientList} = usePatientList();

  const handleChangesSelect = (e) => {
    setCmpSelected(
      customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === e)
    );
  };

  const deleteBoardData = async (doctorToUserProcessBoardId) => {
    try {
      await deleteDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
        },
      });
      const cmpSelectedTemp = JSON.parse(JSON.stringify(cmpSelected));
      setCmpSelected(null);
      cmpSelectedTemp.DoctorToUserProcessBoards = [];
      setCmpSelected(cmpSelectedTemp);
    } catch (err) {
      console.log(err);
    }
  };


  const insertBoardData = async (customFormId) => {
    try {
      const response = await insertDoctorToUserProcessBoard({
        variables: {
          customFormId: customFormId,
          typeView: "new",
          doctorId: doctorData.id,
          userToProcessId: userToProcessData.userToProcess[0].id,
        },
      });

      const cmpSelectedTemp = JSON.parse(JSON.stringify(cmpSelected));
      cmpSelectedTemp.DoctorToUserProcessBoards = [];
      cmpSelectedTemp.DoctorToUserProcessBoards.push(
        response.data.insert_doctorToUserProcessBoard_one
      );
      setCmpSelected(null);
      // console.log(response.data.insert_doctorToUserProcessBoard_one        );
      // cmpSelectedTemp.DoctorToUserProcessBoards = response.data.insert_DoctorToUserProcessBoard;
      setCmpSelected(cmpSelectedTemp);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
  }, [cmpSelected]);

  return (
    <div className="principal-input__container">
      {/* <div className="input-box-time">
        <i className="input-box-time__icon icon zaia-timeline"/>
        <div className="input-content">
          <span className="input-content__label-bold">{t("globally.date")}</span>
          <ZaiaInput
            placeholder={''}
            type="date"
            backgroundColor="#EFF3F6"
            textColor="#36369B"
            placeholderTextColor="#888888"
            activeBorder={false}
            fontSize={"17px"}
            height={"33px"}
            errorOption={false}
            borderRadius={"4px"}
            padding={"5px 10px"}
            getText={(e) => setDateSelected(e)}
            value={dateSelected}
            // disabled={event && editionEvent ? true : false}
          />
        </div>
        <div className="input-content input-content--margin-left">
          <span className="input-content__label-bold">{t("globally.hour")}</span>
          <ZaiaInput
            placeholder={''}
            type="time"
            backgroundColor="#EFF3F6"
            textColor="#36369B"
            placeholderTextColor="#888888"
            activeBorder={false}
            fontSize={"17px"}
            height={"33px"}
            errorOption={false}
            borderRadius={"4px"}
            padding={"5px 10px"}
            getText={(e) => setTimeSelected(e)}
            value={timeSelected}
            // disabled={event && editionEvent ? true : false}
          />
        </div>
        <div className="input-box-time__separator"></div>
        <button className="button-add-favourite">
          <i className="button-add-favourite__icon icon zaia-star-off"/>
        </button>
      </div> */}
      
      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-person"/>
          {t("globally.selectPatient")}
        </div>
        <ZaiaSelect
          type="select"
          name="patient"
          placeholder={t("globally.selectPatient")}
          padding={"20px"}
          borderRadius={"14px"}
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          setValue={(e) => setPatientSelected(e)}
          value={patientSelected}
          items={
            patientList?.map(
              patient => {
                return {
                  value: patient.id, 
                  text: `${patient.name} ${patient.lastname}`
                }
              }
            ).sort(function(a,b){
              return a.text.localeCompare(b.text);
            })
          }
          shadow={false}
          fontSize="15px"
          errorOption={false}
          disabled={patientID? true : false}
          heightList={"300px"}
        />
      </div>

      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-save-book"/>
          {t("globally.selectProgram")}
        </div>
        <ZaiaSelect
          type="select"
          name="userToProcessId"
          placeholder={t("globally.selectProgram")}
          padding={"20px"}
          borderRadius={"14px"}
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          setValue={(e) => setProcessSelected(e)}
          value={processSelected}
          items={userData?.user[0]?.userToProcesses?.map(
            user => {
              return {
                value: user?.process?.id, 
                text: `${P.dataTranslation(user?.process?.processName, language)}`
              }
            }
          ).sort(function(a,b){
            return a.text.localeCompare(b.text);
          })}
          shadow={false}
          fontSize="15px"
          errorOption={false}
          disabled={(!patientSelected || processID)? true : false}
          heightList={"300px"}
        />
      </div>

      <div className="input-box input-box--border">
        <div className="label-and-button">
          <div className="input-box__label">
            <i className="input-box__label-icon icon zaia-i-add-event"/>
            {t("globally.typeOfEvent")}
          </div>
          {
            cmpSelected &&
            <div 
              className={`highlight-button ${ cmpSelected?.DoctorToUserProcessBoards[0] && "highlight-button--active"}`} 
              onClick={
                () => 
                cmpSelected?.DoctorToUserProcessBoards[0] 
                ? deleteBoardData(cmpSelected?.DoctorToUserProcessBoards[0].id)
                : insertBoardData(cmpSelected?.id)
              }>
              <i className={`highlight-button__icon icon ${ cmpSelected?.DoctorToUserProcessBoards[0] ? "zaia-star-on" : "zaia-star-off"}`}></i>
              <div className="highlight-button__text">{t("aiNavigator.outcomes.filterByHighlights")}</div>
            </div>
          }
        </div>
        <ZaiaSelect
          type="select"
          name="cmpId"
          placeholder={t("outcomes.modalOutcomes.event")}
          padding={"20px"}
          borderRadius={"14px"}
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          heightList={"200px"}
          items={customFormsList?.map((customForm) => {
            return {
              value: customForm.id,
              text: P.dataTranslation(
                customForm.customFormName,
                language
              ),
            };
          })}
          setValue={(e) => setCmpSelected(
            customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === e)
          )}
          value={cmpSelected?.id}
          shadow={false}
          fontSize="15px"
          disabled={(!patientSelected || !processSelected || cmpID || customFormsList?.length === 0)? true : false}
          errorOption={false}
        />
        <div className="shape-container" 
          style={{
            // opacity 0.5
            opacity: (!patientSelected || !processSelected || cmpID || customFormsList?.length === 0)? 0.5 : 1
          }}
        >
          <div className="shape">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 25 32" fill="none">
              <path d="M0 31.5C-2.16635e-07 26.544 4.32006 22.5263 9.64912 22.5263L15.3509 22.5263C20.6799 22.5263 25 26.544 25 31.5L25 0.499999C25 5.45603 20.6799 9.47368 15.3509 9.47368L9.64912 9.47368C4.32006 9.47368 -1.13842e-06 5.45603 -1.35505e-06 0.5L0 31.5Z" fill="#6C6BCC"/>
            </svg>
          </div>
        </div>
      </div>
      <div className="input-box input-box--last">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-message-outline"/>
          {t("outcomes.modalOutcomes.addNotes")}
        </div>
        <ZaiaInput
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          fontSize={"15px"}
          placeholder={t("globally.eventDescription")}
          type="text"
          name="description"
          padding={"20px"}
          borderRadius={"14px"}
          textarea={true}
          errorOption={false}
          height={"100%"}
          getText={(e) => setDescriptionSelected(e)}
          value={descriptionSelected}
        />
      </div>
    </div>
  )
}


export default ModalAddOutcomesEvent;
