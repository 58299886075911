import styled from "styled-components";
import { getHexOpacity } from "../../../../../../utils/pipes";

export const ModalSymptoms = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 960px;
    margin: auto;
    box-sizing: border-box;
    /* max-height: 80%; */
    /* overflow-y: auto; */
    border-radius: 14px;
    .header {
      padding: 20px 40px 0 40px;
      display: flex;
      justify-content: space-between;
      &__title {
        color: #68688D;
        font-family: "Nunito Sans Black";
        font-size: 32.25px;
      }
      &__subtitle {
        color: #333365;
        font-family: "Nunito Sans Light";
        font-size: 19px;
      }
      &__close {
        width: 28px;
        height: 28px;
        background-color: #36369B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #fff;
      }
    }
    .modal-tabs {
      display: flex;
      padding: 20px 40px 0;
      .tab {
        cursor: pointer;
        display: flex;
        width: 188px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 4px 18.5px -3px rgba(0, 0, 255, 0.06) inset;
        font-family: "Nunito Sans";
        font-size: 14px;
        margin-right: 10px;
        color: #6C6BCC;
        background: #F0F0FF;
        &--selected {
          font-family: "Nunito Sans Bold";
          color: #36369B;
          background: #D2D2FF;
        }
      }
    }
    .inputs {
      height: calc(100% - 199px);
      max-height: 500px;
      overflow: auto;
      .rows {
        display: flex;
        padding: 20px 40px 0px;
        height: 100%;
        box-sizing: border-box;
      }
      .principal-input {
        padding-right: 20px;
        width: 100%;
        &__form {
          height: 100%;
        }
        &__container {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
      .custom-form-inputs {
        padding: 20px 20px 20px 15px;
        box-sizing: border-box;
        /* margin-left: 5px; */
        background-color: #F1F3F5;
        width: 100%;
        border-radius: 10px;
        border-left: 5px solid #6C6BCC;
        /* overflow-y: auto; */
        &--hidden {
          visibility: hidden;
        }
        .symptoms-inputs {
          height: 100%;
          /* overflow: hidden; */
          display: none;
          &--selected {
            display: block;
          }
        }
        .summary {
          height: 100%;
          overflow: auto;
          .summary-title {
            margin-top: 20px;
            color: #3E3DA3;
            font-family: Nunito Sans;
            font-size: 20px;
          }
          .summary-items {
            margin-top: 25px;
            .summary-item {
              margin-bottom: 20px;
              &__title {
                display: flex;
                align-items: center;
                color: #3E3E5B;
                font-family: Nunito Sans;
                font-size: 15px;
                margin-bottom: 10px;
              }
              &__icon {
                font-size: 13px;
                margin-right: 8px;
              }
              &__info {
                color: #6C6BCC;
                font-family: Nunito Sans Bold;
                font-size: 16px;
              }
            }
          }
        }
      }
      .input-box-time {
        padding: 12px 0px 30px 0px;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        &__icon {
          color: #3E3E5B; 
          margin-right: 10px;
        }
        .input-content {
          align-items: center;
          display: flex;      
          margin-right: 20px;
          &__label-bold {
            color: #3E3E5B; 
            white-space: nowrap;
            margin-right: 10px;
            font-size: 19px;
          }
        }
        &__separator {
          border-left: 1px solid #E6E6E6;
          height: 16px;
          margin-left: 9px;
          margin-right: 29px;
        }
        .button-add-favourite {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 14px;
          border: 1px solid #AAAADB;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          &__icon {
            font-size: 13px;
            color: #36369B;
          }
        }
      }
      .input-box {
        padding-bottom: 20px;
        color: #3E3E5B;
        display: flex;
        flex-direction: column;
        position: relative;
        &--border {
          .input-container {
            border-top: 2px solid #6C6BCC;
            border-right: 5px solid #6C6BCC;
            border-bottom: 2px solid #6C6BCC;
            border-left: 2px solid #6C6BCC;
          }
        }
        &--last {
          padding-bottom: 0px;
          height: 100%;
        }
        &__label {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-bottom: 6px;
          &-icon {
            margin-right: 10px;
          }
        }
        .input-value {
          color: #36369B;
          font-family: "Nunito Sans Bold";
          font-size: 19px;
          padding: 10px 26px 0px 26px;
        }
        .label-and-button {
          display: flex;
          justify-content: space-between;
          .highlight-button {
            display: flex;
            opacity: 0.5;
            color: #36369B;
            align-items: center;
            margin-bottom: 6px;
            cursor: pointer;
            &__icon {
              margin-right: 5px;
            }
            &--active {
              opacity: 1;
              font-family: "Nunito Sans Bold";
              color: #FFA800;
            }
          }
        }
        .shape-container {
          position: absolute;
          right: -23px;
          top: 49px;
        }

        &__input {
        }
      }
    }  
    .button-container {
      padding: 20px 40px 20px;
    }
  }
  
  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;

export const InputOption = styled.div`
  cursor: pointer;
  padding: 12px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-width: 100px;
  background-color: ${({theme, backgroundColor}) => backgroundColor ? backgroundColor : theme.inputBackground};
  margin-bottom: 10px;
  position: relative;
  border: ${
    ({ 
      selected
    }) => 
      {
        if(selected) {
          return '2px solid #6C6BCC'
        } else {
          return '1px solid #36369B'
        }
      }
  };
  .input-icon {
    font-size: 18px;
    margin-right: 5px;
    color: #36369B;
  }
  .input-text {
    font-family: "Nunito Sans";
    font-size: 15px;
    color: ${({selected, filled}) => selected ? "#36369B" : filled ? "#6C6BCC": "#3E3E5B"};
    cursor: pointer;
    b {
      margin-left: 5px;
    }
  }
  .input-icon-arrow {
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    font-size: 12px;
    transition: .3s ease;
    transform: ${({selected}) => selected ? 'rotate(270deg)' : 'rotate(90deg)'};
    margin-left: auto;
  }
  .input-icon-check {
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    font-size: 11px;
    margin-left: auto;
  }
  .shape-container {
    position: absolute;
    right: -23px;
    top: 10px;
  }
`