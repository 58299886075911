import styled from "styled-components";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const UserAINavigatorBoxes = styled.div`
  position: relative;
  height: 100%;
  min-height: 467px;
  .options-row {
    display: flex;
    width: 100%;
    &--first {
        margin-bottom: 20px;
    }
    .box {
        text-align: center;
        box-sizing: border-box;
        width: 32%;
        background-color: #FFF;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 223px;
        position: relative;
        &:not(:last-child) {
        margin-right: 19px;
        }
        &__title {
          margin: 20px 20px 0px 20px;
          color: #36369B;
          font-family: "Nunito Sans Bold";
          font-size: 18.232px;
          display: flex;
          align-items: center;
          justify-content: center;
          &--health {
            margin: 30px 20px 0px 20px;
          }
          &-icon {
            color: #FF4A4A;
            margin-left: 10px;
          }
        }
        &__subtitle {
          color: #36369B;
          padding: 0px 20px;
          text-shadow: 0px 0px 11.4px rgba(255, 255, 255, 0.73);
          font-family: "Nunito Sans";
          font-size: 13px;
          margin-top: 5px;
          &--program {
            color: #494973;
            text-align: center;
            font-family: "Nunito Sans";
            font-size: 14px;
            margin-top: 0px;
          }
        }
        .select-container {
          margin: 10px 19px 0;
          text-align: center;
          justify-content: center;
          align-self: center;
          text-align-last: center;
        }
        &__image {
          margin: 15px 0px 10px -28px;
          width: 362px;
          position: absolute;
          margin-top: 100px;
          z-index: 100;
        }
        &__icon {
          font-size: 78px;
          &--heart {
              margin: 15px auto;
              color: #DB9DC6;
          }
          &--calendar {
              margin: 20px auto ;
              color: #E0E0FF;
              font-size: 57px;
          }
          &--outcomes {
              font-size: 57px;
              margin: 15px auto;
              color: #E0E0FF;
          }
        }
        &__buttons {
          display: flex;
          padding-bottom: 15px;
          justify-content: center;
          z-index: 200;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
        &__button {
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background: #3E3DA3;
          box-shadow: 0px 7px 7px 0px rgba(35, 34, 157, 0.00);
          color: #FFF;
          font-family: "Nunito Sans";
          font-size: 14px;
          border: none;
          outline: none;
          cursor: pointer;
          &:not(:last-child) {
              margin-right: 5px;
          }
        }
        .next-following {
          display: flex;
          padding: 10px 0;
          background-color: #EDEBF7;
          justify-content: center;
          align-items: center;
          margin: 10px 19px 0;
          border-radius: 5px;
          flex-direction: column;
          box-sizing: border-box;
          .label {
            text-align: center;
            display: flex;
            align-items: center;
            &__color {
              display: flex;
              padding: 10px auto;
              justify-content: center;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 5px;
              align-items: center;
            }
            &__text {
              font-family: "Nunito Sans Light";
              font-size: 14px;
            }
          }
        }
        .other-followings {
          margin: 21px 19px 0;
          display: flex;
          justify-content: space-between;
          .following {
            display: flex;
            justify-content: flex-start;
            align-items: start;
            flex-direction: column;
            box-sizing: border-box;
            .label {
              text-align: center;
              display: flex;
              align-items: center;
              &__color {
                  display: flex;
                  padding: 10px auto;
                  justify-content: center;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  margin-right: 5px;
                  align-items: center;
              }
              &__text {
                  font-family: "Nunito Sans Light";
                  font-size: 13px;
              }
            }
            .date {
              display: flex;
              text-align: start;
              color: #000;
              text-align: center;
              font-family: "Nunito Sans";
              font-size: 13px;
            }
          }
        }
        .alert-items {
        padding: 0 14px;
        display: flex;
        margin-top: 10px;
        justify-content: space-evenly;
        .alert-item {
            width: 33%;
            text-align: center;
            display: flex;
            flex-direction: column;
            &__icon {
            padding: 11px;
            margin: auto;
            font-size: 17px;
            color: #36369B;
            &--health-indicators {
                font-size: 25px;
            }
            }
            &__name{
            display: flex;
            justify-content: center;
            padding: 2px 10px;
            border-radius: 13px;
            background: #EFEEF8;
            color: #6C6BCC;
            text-align: center;
            text-shadow: 0px 0px 11.4px rgba(255, 255, 255, 0.73);
            font-family: "Nunito Sans";
            font-size: 11px;
            height: 15px;
            overflow: hidden;
            }
            &:not(:last-child) {
            margin-right: 5px;
            }
        }
        }
        .alert-counters {
        display: flex;
        margin: 25px 0 20px;
        padding: 5px; 
        justify-content: center;
        .alert-counter {
            padding-left: 10px;
            padding-right: 10px;
            border-left: 1px solid #D2D7DC;
            display: flex;
            align-items: center;
            &__text {
            margin: 0px 5px 0px 5px;
            color: #36369B;
            font-family: "Nunito Sans";
            font-size: 12px;
            font-style: normal;
            }
            &__number {
            color: var(--CTA-I, #36369B);
            font-family: "Nunito Sans";
            font-size: 12px;
            }
            &__color {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            }
            &--first {
            border-left: none;
            padding-left: 0px;
            }
        }
        }
        &--follow-up {
          cursor: pointer;
        }
        &--health {
          cursor: pointer;
        }
        &--alerts {
          cursor: pointer;
        }
        &--florence {
        cursor: pointer;
        background-color: white;
        border-radius: 10px;
        justify-content: center;
        box-sizing: border-box;
        text-align: center;
        background-image: url(${bgFlorence});
        background-repeat: no-repeat;
        /* background-size: cover; */
        background-position: center;
        display: flex;
        padding: 24.5px 12px 24.5px 12px;
        }
        &__florence-text {
        margin-top: -30px;
        color: #FFF;
        }
        &__florence-title {
        font-family: "Nunito Sans Bold";
        font-size: 17px;
        padding: 0 25px;
        }
        &__florence-subtitle {
        font-family: "Nunito Sans Light";
        font-size: 18px;
        }
        &__florence-image {
        width: 150px;
        height: 150px;
        margin: -30px auto 0 auto;
        }
    }
    /* .box-florence {
        cursor: pointer;
        width: 65%;
        background-color: white;
        border-radius: 10px;
        justify-content: center;
        box-sizing: border-box;
        text-align: center;
        background-image: url(${bgFlorence});
        background-position: center;
        &__text {
        margin-top: -30px;
        color: #FFF;
        }
        &__title {
        font-family: "Nunito Sans Bold";
        font-size: 20px;
        }
        &__subtitle {
        font-family: "Nunito Sans Light";
        font-size: 18.232px;
        }
    } */
  }
`;