import { useState, useEffect } from 'react'

export default function useUploadTempDoc(doc) {

  const [dataDoc, setDataDoc] = useState();
  const [tempDoc, setTempDoc] = useState(); 
  const [errorFormat, setErrorFormat] = useState(false); 

  useEffect(() => {
    setTempDoc(doc)
  }, [doc])

  const getExtensionFile = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return undefined;
    }
    return filename.slice(lastDotIndex + 1);
  }
  
  const uploadTempDoc = (event, onlyPdf) => {
    try {
      const fileUploaded = event.target.files[0];

      if (fileUploaded) {
        let ext = getExtensionFile(fileUploaded.name);
        let condition;
        if(onlyPdf) {
          condition =  ext && ext === 'pdf';
        } else {
          condition =  ext && (ext === 'pdf' || ext === 'jpeg' || ext === 'png' || ext === 'jpg');
        }

        if (condition) {
          setErrorFormat(false);
          setDataDoc(fileUploaded);
          setTempDoc(event.target.files[0]);
        } else {
          setErrorFormat(true);
        }
      }
    } catch(err) {
      console.log(err);
    }
  };
  
  const deleteDoc = () => {
    console.log('sdkjflkajsdflkasjd');
    setDataDoc();
    setTempDoc();
  }  

  return { 
    dataDoc,
    tempDoc,
    errorFormat,
    uploadTempDoc, 
    deleteDoc
  }
}