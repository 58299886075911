import React, { useEffect, useMemo, useState } from "react";
import * as S from "./SurveyQuestionsList.style";
import { useTranslation } from "react-i18next";
import { Actions } from "../../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../../hooks/useRecordNavigation";
import * as P from "../../../../../../utils/pipes";
import AliceCarousel from "react-alice-carousel";
import { v4 as uuidv4 } from 'uuid';

// GraphQL
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES, SUBSCRIPTION_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES, SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID } from "../../../../../../graphql/subscriptions/CustomForm";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS, QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES, QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES } from "../../../../../../graphql/queries/CustomForm";
import OutcomesTable from "./components/outcomesTable";
import Popup from "reactjs-popup";
import ZaiaSelect from "../../../../../zaiaComponents/zaiaSelect";
import CurrentState from "../../../../outcomes/components/outcomesComponents/shared/currentState";
import CalendarDashboard from "../../../../dashboard/components/calendarDashboard";
import { MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD, MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD, MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD } from "../../../../../../graphql/mutations/DoctorToUserProcessBoard";
import useDoctorData from "../../../../../../hooks/useDoctorData";
import Modal from "../../../../../../utils/modal";
import ModalSendSurvey from "../../../../surveys/components/modalSendSurvey/ModalSendSurvey";
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const zaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;

function SurveyQuestionsList({
  patientID,
  processID,
  customFormId,
}) {
  const {t, i18n: { language }} = useTranslation("global");
  const subsActive = window.navigator.userAgent.indexOf('Mac') === -1;
  const [highlightsFilter, setHighlightsFilter] = useState(false);
  const { doctorData } = useDoctorData();
  const [dateSelected, setDateSelected] = useState();

  const [initialDateRange, setInitialDateRange] = useState();
  const [finalDateRange, setFinalDateRange] = useState();

  const { data: userToProcessData } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientID, processId: processID },
  });

  useEffect(() => {
    if(dateSelected) {
      setInitialDateRange(new Date(dateSelected.initialDate))
      setFinalDateRange(new Date(dateSelected.finalDate))
    }
  }, [dateSelected])

  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [updateDoctorToUserProcessBoard] = useMutation(
    MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const deleteBoardData = async (doctorToUserProcessBoardId) => {
    try {
      await deleteDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const insertBoardData = async (customFormId) => {
    try {
      await insertDoctorToUserProcessBoard({
        variables: {
          customFormId: customFormId,
          typeView: "new",
          doctorId: doctorData.id,
          userToProcessId: userToProcessData.userToProcess[0].id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const carouselButton = (position, disabled) => {
    return (
      <S.CarouselButton position={position} disabled={disabled} type="button">
        <i
          className={`icon ${
            position === "prev" ? "zaia-icono-atras" : "zaia-i-next"
          }`}
        />
      </S.CarouselButton>
    );
  };

  const {
    data: customFormHistories,
    error: customFormHistoriesError,
    loading: customFormHistoriesLoading,
  } = useQuery(
    QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES,
    {
      variables: {
        userId: patientID,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
      },
    }
  );

  const getDateInFormat = (dateString, initial) => {
    const date = new Date(dateString);
    
    if(initial) {
      return new Date(date.setHours(0, 0, 0, 0));
    } else {
      return new Date(date.setHours(23, 59, 59, 59));
    }
  }

  const datesFilterList = useMemo(() => {
    
    console.log("qwerty", customFormHistories);
    console.log("asdfg", customFormHistories?.customForm_by_pk.userCustomFormHistories)    

    const datesList = customFormHistories?.customForm_by_pk.userCustomFormHistories.map((record) => {
      return {
        date: new Date(record.created_at).toLocaleDateString(),
        dateCreation: new Date(record.created_at),
        savedByDoctor: record.savedByDoctor
      };
    });

    const uniqueDatesList = [];
    const seenDates = new Set();

    datesList?.forEach((item) => {
      if (!seenDates.has(item.date)) {
        uniqueDatesList.push(item);
        seenDates.add(item.date);
      }
    });

    const datesListComplete = uniqueDatesList.map((date) => {
      return {
        dateText: date.date,
        initialDate: getDateInFormat(date.dateCreation, true),
        finalDate: getDateInFormat(date.dateCreation, false),
        savedByDoctor: date.savedByDoctor
      }
    })

    setDateSelected(datesListComplete[0]);

    return datesListComplete
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormHistories]);

  
  const { 
    data: customFormSub, 
    loading: customFormLoadingSub ,
    error: customFormErrorSub
  } = useSubscription(
    datesFilterList.length !== 0 
    ? SUBSCRIPTION_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES
    : SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID,
    {
      variables: 
      datesFilterList.length !== 0 
      ? {
        userId: patientID,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
        initialDate: initialDateRange,
        finalDate: finalDateRange
      }
      : {
        userId: patientID,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id
      },
    }
  );

  const { 
    data: customForm, 
    loading: customFormLoading,
    error: customFormError
  } = useQuery(
    datesFilterList.length !== 0 
    ? QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES
    : QUERY_GET_CUSTOM_FORMS_BY_IDS,
    {
      variables: 
      datesFilterList.length !== 0 
      ? {
        userId: patientID,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
        initialDate: initialDateRange,
        finalDate: finalDateRange
      }
      : {
        userId: patientID,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id
      },
    }
  );

  const getOptionsWithPayload = (formFields) => {
    return formFields.map((option) => {
      const { customFormTypeField, userToCustomFormFields } = option;
  
      if (
        !customFormTypeField?.fieldOptions?.length ||
        !userToCustomFormFields?.length
      ) {
        return { ...option, payload: null };
      }
  
      const fieldOption = customFormTypeField.fieldOptions.find(
        (field) => field.key === userToCustomFormFields[0].key
      );
  
      if (!fieldOption?.payload) {
        return { ...option, payload: null };
      }
  
      let payloadFormated;
  
      try {
        payloadFormated = JSON.parse(fieldOption.payload);
        option.payload =
          "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        option.payload = null;
      }
  
      return option;
    });
  }

  const customFormOrder = useMemo(() => {

    const customFormData = subsActive ? customFormSub : customForm;

    let formGroupsFilter = 
      highlightsFilter
      ? customFormData?.customForm_by_pk.formGroups.filter(
          (formGroup) => formGroup.DoctorToUserProcessBoards[0]
        )
      :
       customFormData?.customForm_by_pk.formGroups;
  
    if(formGroupsFilter?.length === 0) {
      formGroupsFilter = customFormData?.customForm_by_pk.formGroups;
    }

    return formGroupsFilter?.map((formGroup) => [    
      formGroup,    
      getOptionsWithPayload(formGroup.customFormFields)
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormSub, customForm, highlightsFilter]);

  function getFormattedValue(item) {
    const userField = item.userToCustomFormFields[0];
    let formattedValue = "–";
    if (userField) {
      formattedValue =
        item.typeFieldValue === "dateInput"
          ? P.formatNumberDate(userField.value, true, language)
          : userField.value;
    }
    return formattedValue;
  }

  const renderCustomFormGroupsIndex0 = (formGroup, language) => { 
    return (
      <td
        key={uuidv4()}
        style={{
          color: "",
          fontFamily: "Nunito ExtraBold",
          verticalAlign: "middle",
        }}
        className="td-index0"
      >
          {formGroup.DoctorToUserProcessBoards[0] ? (
            <div className="highlight-button highlight-button--active">
              <i
                className="icon-save-form-field icon zaia-star-on"
                onClick={() =>
                  deleteBoardData(
                    formGroup.DoctorToUserProcessBoards[0].id
                  )
                }
              ></i>
            </div>
          ) : (
            <div className="highlight-button">
              <i
                className="icon-save-form-field icon zaia-star-off"
                onClick={() => insertBoardData(formGroup.id)}
              ></i>
            </div>
          )}
        {/* {formGroup.DoctorToUserProcessBoards[0] && (
          <i
            className="icon-save-form-field icon zaia-timeline"
            style={{
              color:
                formGroup.DoctorToUserProcessBoards[0]
                  .typeView === "history"
                  ? theme.inputSelected
                  : theme.onBackgroundSoft,
            }}
            onClick={() =>
              formGroup.DoctorToUserProcessBoards[0]
                .typeView === "history"
                ? updateBoardData(
                    formGroup.DoctorToUserProcessBoards[0].id,
                    true
                  )
                : updateBoardData(
                    formGroup.DoctorToUserProcessBoards[0].id,
                    false
                  )
            }
          />
        )} */}
        <div className="event-type">
          <div className="event-type__name">
            {P.dataTranslation(formGroup.customFormName, language)}
            {formGroup.descript && (
              <Popup
                trigger={
                  <i className="template-header-item-icon icon zaia-alert"></i>
                }
                on={["hover", "focus"]}
                closeOnDocumentClick
                keepTooltipInside
                position={["right"]}
                nested
              >
                <S.PopUpPayload>
                  <p>
                    {P.dataTranslation(
                      formGroup.descript,
                      language
                    )}
                  </p>
                </S.PopUpPayload>
              </Popup>
            )}
          </div>
        </div>
      </td>
    );
  }

  const renderCustomFormGroupsindex1 = (formGroup, language) => {
    return (
      <td key={uuidv4()}>
        {formGroup.length <= 0 && "-"}
        {formGroup.length > 0 && 
          formGroup.map((item, index) => {
            return (
              <div key={item.id}>
                {
                  index !== 0 && 
                  <br/>
                }

                <div className="detail">
                  <div className="detail-item">
                    {P.dataTranslation(item.fieldName, language)}
                    {item.descript && (
                      <Popup
                        trigger={
                          <i className="template-header-item-icon icon zaia-alert"></i>
                        }
                        on={["hover", "focus"]}
                        closeOnDocumentClick
                        keepTooltipInside
                        position={["right center"]}
                        nested
                      >
                        <S.PopUpPayload>
                          <p>
                            {P.dataTranslation(item.descript, language)}
                          </p>
                        </S.PopUpPayload>
                      </Popup>
                    )}
                  </div>
                  {getFormattedValue(item)}
                  {item.payload && (
                    <Popup
                      trigger={
                        <i className="template-header-item-icon icon zaia-alert"></i>
                      }
                      on={["hover", "focus"]}
                      closeOnDocumentClick
                      keepTooltipInside
                      position={["right"]}
                      nested
                    >
                      <S.PopUpPayload>
                        <p>{item.payload}</p>
                      </S.PopUpPayload>
                    </Popup>
                  )}
                </div>
              </div>
            );
          })
        }
      </td>
    );
  }

  return (
    <S.SurveyQuestionsList>
      <div className="filters-section">
        <div className="filters-section__left">
          <div className={`highlights-button ${ highlightsFilter && "highlights-button--active"}`} onClick={() => setHighlightsFilter(!highlightsFilter)}>
            <i className={`highlights-button__icon icon ${ highlightsFilter ? "zaia-star-on" : "zaia-star-off"}`}></i>
            <div className="highlights-button__text">{t("aiNavigator.outcomes.filterByHighlights")}</div>
          </div>
        </div>
        <div className="filters-section__right">
          {/* <div className="results-label">Results</div>
          <div className="status">
            <div className="status__icon status__icon--delicate">
              <i className="icon zaia-alert"></i>
            </div>
            <div className="status__text">
              Normal Delicate <b>9</b>
            </div>
          </div>
          <div className="status">
            <div className="status__icon status__icon--normal">
              <i className="icon zaia-i-check-circle"></i>
            </div>
            <div className="status__text">
              Normal Result <b>9</b>
            </div>
          </div> */}
        </div>
      </div>
        {
          datesFilterList.length !== 0 &&
          <div className="dates-container">
            <div className="slider-container">
              <AliceCarousel
                // mouseTracking
                disableDotsControls
                innerWidth
                autoWidth
                renderPrevButton={(e) => carouselButton("prev", e.isDisabled)}
                renderNextButton={(e) => carouselButton("next", e.isDisabled)}
              >
                { 
                  datesFilterList
                  .map((filter) => {
                    return (
                      <S.SliderBox 
                        selected={filter.dateText === dateSelected?.dateText}
                      // active={data.key === optionKey} key={data.id}
                      >
                        <div
                          className="slider-box"
                          onClick={() => {
                            setDateSelected(filter)
                          }}  
                        >
                          <div className="slider-box-title">
                            {
                              filter.dateText
                            }
                          </div>
                          <div className="slider-box-filled">
                            {t("globally.filledBy")} <b>
                              {
                                filter.savedByDoctor
                                ? t("globally.medicalPersonnel")
                                : t("globally.patient")
                              }
                            </b>
                          </div>
                        </div>
                      </S.SliderBox>
                    );
                  })
                }
              </AliceCarousel>
            </div>
          {/* <Popup
            trigger={
              <div className="button-calendar">
                <i className="button-calendar__icon icon zaia-i-time"></i>
              </div>
            }
            // on={["hover", "focus"]}
            closeOnDocumentClick
            keepTooltipInside
            position={["bottom"]}
            nested
          >
            <S.CalendarContainer>
              <CalendarDashboard
                initialDate={initialDateRange}
                setInitialDate={setInitialDateRange}
                finishDate={finishDateRange}
                setFinishDate={setFinishDateRange}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                titleColor="#424469"
                arrowsColor="#3E3DA3"
                textColor="#6A6898"
                currentDayColor="#6A6898"
                backgroundColor="#FFFFFF"
                // fontSizeCalendar={!overflowActive ? "7.5px" : "8.2px"}
              />
              <div className="marker">
                <div className="marker__point marker__point--green"></div>
                <div className="marker__text">Survey filled without delicate results</div>
              </div>
              <div className="marker">
                <div className="marker__point marker__point--red"></div>
                <div className="marker__text">Survey filled with delicate results</div>
              </div>
            </S.CalendarContainer>
          </Popup> */}
        </div>
      }
      
      <div className="view-container">
        <table className="questions-table">
          <thead>
            <tr>
              <th className="th-event-type">{customFormOrder?.length} {t("surveys.modules")}</th>
              <th className="th-event-type">{t("surveys.questions")}</th>
              {/* <th className="th-event-type">{t("surveys.answer")}</th> */}
              {/* <th>{"Status"}</th> */}
            </tr>
          </thead>
          <QuestionsList
            renderCustomFormGroupsIndex0={renderCustomFormGroupsIndex0}
            renderCustomFormGroupsindex1={renderCustomFormGroupsindex1}
            customFormOrder={customFormOrder}
            customFormLoading={customFormLoading}
            customFormLoadingSub={customFormLoadingSub}
            customFormError={customFormError}
            customFormErrorSub={customFormErrorSub}
          />
        </table>
      </div>
    </S.SurveyQuestionsList>
  );
}

// body as a fuction 

function QuestionsList(
  {
    renderCustomFormGroupsIndex0,
    renderCustomFormGroupsindex1,
    customFormOrder,
    customFormLoading,
    customFormLoadingSub,
    customFormError,
    customFormErrorSub
  }
) {

  const {t, i18n: { language }} = useTranslation("global");

  if(customFormLoadingSub || customFormLoading) 
    return(
      <div className="survey-list-reaction">
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        /> 
      </div>
    )

  if(customFormErrorSub || customFormError)
    return (
      <div className="survey-list-reaction">
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={"Error loading the patients"}
          sizeText="15px"
          widthText="240px"
        />
      </div>
      )

    if(customFormOrder.length === 0) 
      return (
        <div className="survey-list-reaction">
          <ZaiaReaction
            zaiaImg={zaiaEmpty}
            text={t("surveys.noSurveys")}
            // subText={}
            sizeText="33.25px"
            sizeSubText="15px"
            textColor="var(--blue-color)"
            widthImg="210px"
          />
        </div>
      )

  return (  
    <tbody>
      {
        customFormOrder?.map((formGroup) => {
          return (
            <tr key={formGroup[0].id}>
              {formGroup.map((formGroup, index) => {
                if (index === 0) {
                  return(renderCustomFormGroupsIndex0(formGroup, language))
                }
                if (index === 1) {
                  return(renderCustomFormGroupsindex1(formGroup, language))
                }
              })}
              {/* <td>
                <div className="status">
                  {
                    true 
                    ? <div className="status__icon status__icon--delicate">
                      <i className="icon zaia-alert"></i>
                    </div>
                    : <div className="status__icon status__icon--normal">
                      <i className="icon zaia-i-check-circle"></i>
                    </div>
                  }
                  <div className="status__text">
                    Normal Result
                  </div>
                </div>
              </td> */}
            </tr>
          );
        })
      }
    </tbody>
  );
  
}

export default SurveyQuestionsList;
