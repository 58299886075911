import CmpSelect from "../outcomesComponents/shared/cmpSelect";
import CmpYesNoList from "../outcomesComponents/shared/cmpYesNoList";
import CmpDiagnostics from "../outcomesComponents/shared/cmpDiagnostics";
import CmpRadioListInfo from "../outcomesComponents/prostateCancer/cmpRadioListInfo";
import CmpDateSelection from "../outcomesComponents/shared/cmpDateSelection";
import CmpNumberInput from "../outcomesComponents/shared/cmpNumberInput";
import CmpTextInput from "../outcomesComponents/shared/cmpTextInput";
import CmpTextAreaInput from "../outcomesComponents/shared/cmpTextAreaInput";
import CmpVerticalOptions from "../outcomesComponents/prostateCancer/cmpVerticalOptions";
import CmpSliderHorizontalOptions from "../outcomesComponents/prostateCancer/cmpSliderHorizontalOptions";
import CmpCheckMultipleOptionList from "../outcomesComponents/shared/cmpCheckMultipleOptionList";
import CmpScrollMultipleOptionList from "../outcomesComponents/shared/cmpScrollMultipleOptionList";
import CmpBoxesContainer from "./prostateCancer/cmpBoxesContainer";
import CmpMarkerInput from "./shared/cmpMarkerInput";

const outcomesComponentsList = [
  {
    id: "dropdown",
    key: "dropdown",
    component: (props) => <CmpSelect {...props} />,
  },
  {
    id: "cmpYesNoList",
    key: "cmpYesNoList",
    component: (props) => <CmpYesNoList {...props} />,
  },
  {
    id: "cmpDiagnostics",
    key: "cmpDiagnostics",
    component: (props) => <CmpDiagnostics {...props} />,
  },
  {
    id: "cmpRadioListInfo",
    key: "cmpRadioListInfo",
    component: (props) => <CmpRadioListInfo {...props} />,
  },
  {
    id: "dateInput",
    key: "dateInput",
    component: (props) => <CmpDateSelection {...props} />,
  },
  {
    id: "textAreaInput",
    key: "textAreaInput",
    component: (props) => <CmpTextAreaInput {...props} />,
  },
  {
    id: "textInput",
    key: "textInput",
    component: (props) => <CmpTextInput {...props} />,
  },
  {
    id: "numberInput",
    key: "numberInput",
    component: (props) => <CmpNumberInput {...props} />,
  },
  {
    id: "floatInput",
    key: "floatInput",
    component: (props) => <CmpNumberInput {...props} />,
  },
  {
    id: "markerInput",
    key: "markerInput",
    component: (props) => <CmpMarkerInput {...props} />,
  },
  {
    id: "cmpVerticalOptions",
    key: "cmpVerticalOptions",
    component: (props) => <CmpVerticalOptions {...props} />,
    // component: (props) => <CmpSliderHorizontalOptions {...props} />,
    // component: (props) => <CmpBoxesContainer {...props} />,
  },
  {
    id: "cmpSliderHorizontalOptions",
    key: "cmpSliderHorizontalOptions",
    component: (props) => <CmpSliderHorizontalOptions {...props} />,
  },
  {
    id: "cmpScrollMultipleOptionList",
    key: "cmpScrollMultipleOptionList",
    component: (props) => <CmpScrollMultipleOptionList {...props} />,
  },
  {
    id: "cmpCheckMultipleOptionList",
    key: "cmpCheckMultipleOptionList",
    component: (props) => <CmpCheckMultipleOptionList {...props} />,
  },
  {
    id: "cmpBoxesContainer",
    key: "cmpBoxesContainer",
    component: (props) => <CmpBoxesContainer {...props} />,
  },
];

export default outcomesComponentsList;
