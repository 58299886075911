import styled from 'styled-components';

export const SurveyList = styled.div`
  height: 100%;
  .header {
    align-items: flex-end;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    &__options {
      align-items: flex-end;
      display: flex;
      .send-survey {
        cursor: pointer;
        align-items: center;
        padding: 6.5px 10px;
        border-radius: 10px;
        font-family: Nunito Sans;
        display: flex;
        background-color: #36369B;
        border: none;
        outline: none;
        margin-left: 12px;
        font-size: 13px;
        color: #FFFFFF;
        height: fit-content;
        &__icon {
          margin-left: 5px;
          font-size: 14px;
        }
      }
    }
  }
  .principal-selects-container {
    background-color: #F0F0FF;
    border-radius: 12px;
    padding: 10px;
    display: flex;
    .select-container {
      &__label {
        font-family: Nunito Sans;
        font-size: 15px;
        margin-left: 12px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
      &--survey {
        width: 296px;
      }
    }
  }
  .filters-section {
    display: flex;
    margin: 0px 31px 0px 31px;
    justify-content: space-between;
    align-items: flex-end;
    &__left {
      display: flex;
      .filter-text {
        width: 294px;
      }
      .filter-favourite {
        align-items: center;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 60px;
        font-family: Nunito Sans Semibold;
        font-size: 17px;
        display: flex;
        background-color: #FFA800;
        border: none;
        outline: none;
        margin-left: 12px;
        color: #FFFFFF;
        cursor: pointer;
        &__icon {
          margin-right: 5px;
        }
      }
    }
    &__right {
      display: flex;
      .filter-order {
        cursor: pointer;
        align-items: center;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 60px;
        font-family: Nunito Sans Semibold;
        font-size: 17px;
        display: flex;
        background-color: #FFFFFF;
        border: none;
        outline: none;
        margin-left: 12px;
        color: #6C6BCC;
        border: solid 1px #6C6BCC;
        &__icon {
          margin-right: 5px;
        }
      }
    }
  }
  .labels {
    display: flex;
    border-top: #DDDDDD 1px solid;
    margin: 0px 32px;
    padding: 50px 17px 0 17px;
    justify-content: space-between;
    color: #1F1F52;
    font-family: Nunito Sans;
    font-size: 15px;
  }
  .surveys-section {
    height: calc(100% - 205px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 20px 14px 0px 14px; 
    box-sizing: border-box;
    .survey-list-header {
      padding: 20px 40px;
      margin: 20px 18px 16px 18px;
      background-color: #F6F5F9;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &__left {
        display: flex;
        align-items: center;
      }
      &__point {
        background-color: #00A600;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 16.5px;
      }
      &__name {
        font-family: Nunito Sans Bold;
        font-size: 22px;
        margin-right: 42px;
        color: #3E3DA3;
      }
      &__number {
        color: #3E3DA3;
        font-family: Nunito Sans Light;
        font-size: 25.14px;
      }
      &__icon {
        color: #3E3DA3;
        font-size: 18px;
        transition: all 0.3s ease;
        &--rotate {
          transform: rotate(180deg);
        }
      }
    }
    .labels {
      display: flex;
    }
    .survey-list {
      /* margin: 0px 18px; */
      &__no-more{
        font-family: Nunito Sans;
        font-size: 15px;
        text-align: center;
        color: #707582;
        margin: 40px;
      }
      &__reaction {
        margin: auto;
      }
      .survey-item {
        align-items: center;
        justify-content: space-between;
        padding: 7px 18px;
        display: flex;
        margin-bottom: 20px;
        &__center {
          display: flex;
        }
        &__favourite {
          margin: 0 20px 0 10px;
          border-radius: 14px;
          border: 1px solid #AAAADB;
          color: #38379C;
          padding: 8px;
          cursor: pointer;
          &--active {
            border: none;
            padding: 9px;
            background-color: #FFA800;
            color: white;
          }
          &-icon {
            display: flex;
          }
        }
        &__info {
          width: 330px;
          margin-left: 17px;
          &-title {
            font-family: Nunito Sans Light;
            font-size: 16px;
            color: #3E3DA3;
          }
          &-date {
            font-family: Nunito Sans;
            font-size: 16px;
            color: #707582;
          }
        }
        &__number-sendings {
          width: 123px;
          font-family: Nunito Sans;
          font-size: 16px;
          margin-left: 17px;
          color: #707582;
        }
        &__number-answers {
          width: 123px;
          font-family: Nunito Sans;
          font-size: 16px;
          color: #707582;
          margin: 0 20px;
        }
        &__delicate-results {
          display: flex;
          align-items: center;
          margin: 0 17px;
          &-icon-container {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: flex;
            margin-right: 5px;
            background-color: #F0F0FF;
            color: #3E3E5B;
            &--alert {
              background-color: #FFECEC;
              color: #FF3030;
            }
          }
          &-icon {
            font-size: 15px;
            margin: auto;
          }
          &-text {
            font-family: Nunito Sans;
            font-size: 16px;
            color: #707582;
          }
        }
        &__buttons {
          .button-preview {
            font-family: Nunito Sans;
            font-size: 16px;
            min-width: 100px;
            height: 40px;
            background-color: #EFEEF8;
            border: none;
            outline: none;
            border-radius: 40px;
            padding: 0px 11px;
            margin: 5px 0px 5px 10px;
            color: #5C5BEF;
            cursor: pointer;
            float: right;
            &--last {
              color: #FFFFFF;
              background-color: #3E3DA3;
            }
            &--disabled {
              opacity: 0.3;
              cursor: default
            }
          }
        }
      }
    }
  }

  .surveys-filtered-list {
    margin: 36px 31px 0px 31px;
    height: calc(100% - 173px);
    overflow: auto;
    table {
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      th {
        padding: 0 20px 20px 20px;
        text-align: start;
        color: #707582;
        font-family: Nunito Sans Light;
        font-size: 16px;
        &:first-child {
          /* text-align: center !important; */
          padding: 0 20px 20px 95px;
        }
      }
      td {
        padding: 20px;
      }
    }
  }
`