import React, { useRef, useState, useEffect, useContext } from "react";
import { ThemeContext } from 'styled-components';
import * as S from "./ZaiaSelect.style.js";

function ZaiaSelect({ 
  placeholder, 
  icon,
  padding,
  items= [],
  value,
  setValue,
  fontSize,
  disabled,
  backgroundColor                                                ,
  textColor,
  placeholderTextColor,
  activeBorder=true,
  borderColor,
  borderRadius,
  heightList,
  arrowColor,
  textItemsColor
}) {

  const container = useRef(null);
  const [listActive, setlistActive] = useState(false);
  const textInput = useRef(null);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setlistActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function handleChange(item) {
    setValue(item)
    setlistActive(false);
  }
  

  return (
    <S.ZaiaSelect 
      padding={padding} 
      listActive={listActive}
      fontSize={fontSize}
      disabled={disabled}
      backgroundColor={backgroundColor}
      textColor={textColor}
      placeholderTextColor={placeholderTextColor}
      activeBorder={activeBorder}
      borderColor={borderColor}
      borderRadius={borderRadius}
      heightList={heightList}
      arrowColor={arrowColor}
      textItemsColor={textItemsColor}
    >
      <div onClick={(e) => {}} ref={container}>
        <div className="input-container" onClick={
          (e) => {
            if(!disabled) {
              textInput.current.focus(); 
              setlistActive(e);
            }
          }
        }>
          <i className={'icon-input-form icon zaia-' + icon} style={{ color: listActive ? theme.inputSelected : theme.inputPlaceholder }} />
          <input 
            ref={textInput}
            // {...field} 
            // {...props}
            value={items?.find( item => item.value === value)?.text ? items?.find( item => item.value === value)?.text : ''}
            placeholder={placeholder}
            readOnly
          />
          {
            disabled
            ? null
            : <i className={'icon-input-form-arrow icon zaia-icono-dropdown-light'} />
          }
        </div>
        <div className="select-list">
          {
            disabled
            ? null
            : items?.map(item => {
              return (
                <div className="select-item" key={item.value} onClick={() => handleChange(item.value)}>
                  <label>{item.text} </label>
                </div>
              )
            })
          }
        </div>
      </div>
    </S.ZaiaSelect>
  );
}

export default ZaiaSelect;
