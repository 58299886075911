import React, { useContext, useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import * as S from "./InvitationsManagement.style";

// components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaToggle from "../../../../zaiaComponents/zaiaToggle";
import ZaiaRoundInput from "../../../../zaiaComponents/zaiaRoundInput";
import InvitePatientButton from "../../../patients/components/invitePatientButton";

// GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED, QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED_NULL } from "../../../../../graphql/queries/UserToProcess";
import { MUTATION_DELETE_USER_TO_PROCESS_BY_ID, MUTATION_UPDATE_USER_TO_PROCESS_IS_ACEPTED } from "../../../../../graphql/mutations/UserToProcess";


// Utils
import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";

// Assets
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ModalShareAccessToPatient from '../modalShareAccessToPatient';
import ZaiaModalResponseContent from '../../../../zaiaComponents/zaiaModalResponseContent';
import ZaiaReaction from '../../../../zaiaComponents/zaiaReaction';

const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function InvitationsManagement() {
  const { t } = useTranslation("global");
  const theme = useContext(ThemeContext);
  const [activedModal, setActivedModal] = useState(false);
  const [userToProcessSelected, setUserToProcessSelected] = useState();
  const [currentIsAcepted, setCurrentIsAcepted] = useState();

  const [deleteUserToProcess] = useMutation(MUTATION_DELETE_USER_TO_PROCESS_BY_ID);
  const [updateUserToProcessIsAcepted] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_IS_ACEPTED);
  const [getListAcepted, { data: userToProcessListAcepted, loading: userToProcessListAceptedLoading, error: userToProcessListAceptedError, refetch: refetchListAcepted}] = useLazyQuery(QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED, {variables: {isAcepted: true}});
  const [getListNotAcepted, { data: userToProcessListNotAcepted, loading: userToProcessListNotAceptedLoading, error: userToProcessListNotAceptedError, refetch: refetchListNotAcepted}] = useLazyQuery(QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED, {variables: {isAcepted: false}});
  const [getListIsAceptedNull, { data: userToProcessListNull, loading: userToProcessListNullLoading, error: userToProcessListNullError, refetch: refetchListNull}] = useLazyQuery(QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED_NULL);

  const [activeTab, setActiveTab] = useState(1);
  const [ inputText, setInputText ] = useState('');
  const [ shareAccesToPatientActive, setShareAccesToPatientActive] = useState(false);
  
  const [ responseResendInvitationModal, setResponseResendInvitationModal ] = useState();

  const tabs = [
    {id: 1, name: t('profile.invitationManagement.current')},
    {id: 2, name: t('profile.invitationManagement.invited')}
  ]

  useEffect(() => {
    getListAcepted();
    getListNotAcepted();
    getListIsAceptedNull();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateUserToProcessHandler = async (idUserToProcess, currentState) => {
    try {
      await updateUserToProcessIsAcepted({
        variables: {
          isAcepted: currentState === null ? false : null,
          id: idUserToProcess
        },
      });

      if(currentState === null) {
        try {
          await updateUserToProcessIsAcepted({
            variables: {
              isAcepted: null,
              id: idUserToProcess
            },
          });

          // setActivedModalResend(true);
          setActivedModal(false);
          setCurrentIsAcepted(null);
          setResponseResendInvitationModal(true)
        } catch (err) {
          setResponseResendInvitationModal(false)
        }
      } else {
        setActivedModal(false);
        setCurrentIsAcepted(null);
        setResponseResendInvitationModal(true)
      }
      
    } catch (err) {
      setResponseResendInvitationModal(false)
    }
  }

  const deleteUserToProcessHandler = async (idUserToProcess) => {
    try {
      const { 
        data: returning
      } = await deleteUserToProcess({
        variables: {
          id: idUserToProcess.id
        },
      });
      setActivedModal(false);
      if (returning.delete_userToProcess_by_pk.isAcepted) {
        refetchListAcepted();
      } else {
        refetchListNotAcepted();
        refetchListNull();
      }
    } catch (err) {
      console.log(err);
    }
  }

  const filteredItem = (patient) => {
    return inputText 
    ? String(P.removeAccent(patient.user?.documentNumber)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase()) ||
      String(P.removeAccent(patient.user?.name + " " + patient.user?.lastname)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase()) ||
      String(P.removeAccent(patient.user?.email)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase()) 
    : true
  }
  
  return (
    <S.InvitationsManagement>
      <Modal open={shareAccesToPatientActive}>
        <ModalShareAccessToPatient
          setModalActive={setShareAccesToPatientActive}
          patientInfo={userToProcessSelected}
        />
      </Modal>
      <Modal open={responseResendInvitationModal === true || responseResendInvitationModal === false}>
        <S.ModalResponseReaction>
          <div className="modal-background" onClick={() => setResponseResendInvitationModal(null)}>
          </div>
          <div className="modal-container">
            <ZaiaModalResponseContent
              button1={() => setResponseResendInvitationModal(null)}
              formSuccess={responseResendInvitationModal}
              title={responseResendInvitationModal === true ? t('profile.invitationManagement.sendInvitationSuccess') : responseResendInvitationModal === false ? t('profile.invitationManagement.sendInvitationError') : ""}
              button1Text={t('profile.invitationManagement.backInvitationManagement')}
            />
          </div>
        </S.ModalResponseReaction>
      </Modal>
      <div className="card">
        <div className="card-info">
          <div className="card-info__header">
            <S.ToggleContainer>
              <ZaiaToggle
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                color={theme.primaryColor}
                backgroundColor={'transparent'}
                borderRadius={'10'}
                sizeFont={'15px'}
              />
            </S.ToggleContainer>
            <div className="input-container">
              <ZaiaRoundInput
                placeholder={t("globally.search")} 
                padding="8px 20px" 
                type={'text'} 
                getText={(e) => setInputText(e)}
              />
            </div>
          </div>
          <div className="card-info__invitations-list">
            { 
              activeTab === 1 &&
              <CurrentPatientsList 
                filteredItem={filteredItem}
                setUserToProcessSelected={setUserToProcessSelected}
                setActivedModal={setActivedModal}

                userToProcessListAcepted={userToProcessListAcepted}
                userToProcessListAceptedLoading={userToProcessListAceptedLoading}
                setShareAccesToPatientActive={setShareAccesToPatientActive}
              />
            }
            {
              activeTab === 2 &&
              <InvitationsList
                filteredItem={filteredItem}
                setUserToProcessSelected={setUserToProcessSelected}
                setActivedModal={setActivedModal}

                userToProcessListNotAcepted={userToProcessListNotAcepted}
                userToProcessListNotAceptedLoading={userToProcessListNotAceptedLoading}
                userToProcessListNull={userToProcessListNull}
                userToProcessListNullLoading={userToProcessListNullLoading}
                inputText={inputText}
                updateUserToProcessHandler={updateUserToProcessHandler}
                setCurrentIsAcepted={setCurrentIsAcepted}
                activedModal={activedModal}
                activeTab={activeTab}
                deleteUserToProcessHandler={deleteUserToProcessHandler}
                currentIsAcepted={currentIsAcepted}
                userToProcessSelected={userToProcessSelected}
              />
            }
          </div>
        </div>
      </div>
    </S.InvitationsManagement>
  );
}

const DeleteInvitedPatientModal = ({setActivedModal, deleteUserToProcessHandler, updateUserToProcessHandler, idUserToProcess, currentIsAcepted}) => {
  const [t] = useTranslation("global");

  return (
    <S.deleteInvitationModal>
      <div className="content-delete">
        <img className="zaia-image" src={logoZaiaAlert} alt="ZAIA" />
        <h1 className="content-delete__title">{t('profile.invitationManagement.deleteInvitationConfirmation')}</h1>
        <div className="message">{t('profile.invitationManagement.deleteInvitationWarning')}</div>
        <div className="button-container-principal" onClick={() => updateUserToProcessHandler(idUserToProcess, currentIsAcepted)}>
          <ZaiaButton 
            title={t('profile.invitationManagement.resendInvitation')}
            shadow={true}
          />
        </div>
        <div  className="cancel-button" onClick={() => deleteUserToProcessHandler(idUserToProcess)}>
          {t('globally.delete')}
        </div>
      </div>
      <div onClick={() => setActivedModal(false)}>
        <InvitePatientButton activate={true} />
      </div>
    </S.deleteInvitationModal>
  )
}

const DeletePatientModal = ({setActivedModal, deleteUserToProcessHandler, idUserToProcess}) => {
  const [t] = useTranslation("global");

  return (
    <S.deleteInvitationModal>
      <div className="content-delete">
        <img className="zaia-image" src={logoZaiaAlert} alt="ZAIA" />
        <h1 className="content-delete__title">{t('profile.invitationManagement.deletePatientConfirmation')}</h1>
        <div className="message">{t('profile.invitationManagement.deletePatientWarning')}</div>
        <div className="button-container-principal" onClick={() => setActivedModal(false)}>
          <ZaiaButton 
            title={t('profile.invitationManagement.keepPatient')}
            shadow={true}
          />
        </div>
        <div className="cancel-button" onClick={() => deleteUserToProcessHandler(idUserToProcess)}>
          {t('globally.delete')}
        </div>
      </div>
      <div onClick={() => setActivedModal(false)}>
        <InvitePatientButton activate={true} />
      </div>
    </S.deleteInvitationModal>
  )
}

const CurrentPatientsList = ({
  userToProcessListAcepted,
  userToProcessListAceptedLoading,
  userToProcessListAceptedError,
  filteredItem,
  setShareAccesToPatientActive,
  setUserToProcessSelected,
  setActivedModal,
  activedModal,
  activeTab,
  deleteUserToProcessHandler,
  userToProcessSelected
}) => {
  const { t, i18n: { language } } = useTranslation("global");

  if(userToProcessListAceptedError)
    return (
      <div className="reaction">
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          sizeText="20px"
          textColor="var(--blue-color)"
          widthImg="200px"
          text={t("globally.noData")}
        />
      </div>
    )

  if(userToProcessListAceptedLoading)
    return (
      <div className="reaction">
        <ZaiaReaction
          widthImg={"150px"}
          zaiaImg={logoZaiaLoading}
          padding={"200px 0px"}
        />
      </div>
    )

  if(userToProcessListAcepted?.userToProcess.filter((patient) => filteredItem(patient)).length === 0) 
    return (
      <div className="reaction">
        <ZaiaReaction
          zaiaImg={eventsCalendarEmpty}
          text={t("globally.noData")}
          subText={t("globally.noDataDescription")}
          sizeText="33.25px"
          sizeSubText="15px"
          textColor="var(--blue-color)"
          widthImg="210px"
          />
      </div>
    )

  return (
    <>
      <div className="card-info__invitations-list-header">
        <div className="item-text">{t("globally.name")}</div>
        <div className="item-text">{t("profile.invitationManagement.actions")}</div>
      </div>
      {
        userToProcessListAcepted?.userToProcess
        .filter((patient) => filteredItem(patient)).map((patient) => {
          return (
            <S.PatientBox key={patient.id}>
              <div className="box-info">
                <ZaiaImage 
                  img={patient.user?.picture} 
                  alt="Zaia user" 
                  size={"40"} 
                  defaultImg={defaultUser}
                  rounded={true}
                />
                <div className='box-info__text'>
                  <div className="box-info__text-name">{patient.user?.name + ' ' + patient.user?.lastname}</div>
                  <div className="box-info__text-email">{patient.user?.email}</div>
                  <p className="box-info__text-process">{P.dataTranslation(patient?.process?.processName, language)}</p>
                  {/* <p className="second-text">
                    {patient.user?.enDocumentType.comment.code}: {patient.user?.documentNumber}
                  </p> */}
                  {/* <p className="first-text">{P.dataTranslation(patient?.process?.processName, language)}</p> */}
                </div>
              </div>
              <div className="box-options">
                <button className="box-option__button" onClick={() => {setShareAccesToPatientActive(true); setUserToProcessSelected(patient)}}>
                  <div className="box-option__button-text">{t("profile.invitationManagement.sharePatientData")}</div>
                  <i className="box-option__button-icon icon zaia-i-back-2"></i>
                </button>
                <button className="box-option__button" onClick={() => {setActivedModal(true); setUserToProcessSelected(patient)}}>
                  <div className="box-option__button-text">{t("globally.delete")}</div>
                  <i className="box-option__button-icon icon zaia-i-trash-can"></i>
                </button>
              </div>
              {
                <Modal open={(activedModal && activeTab === 1)}>
                  <DeletePatientModal 
                    setActivedModal={setActivedModal} 
                    deleteUserToProcessHandler={deleteUserToProcessHandler} 
                    idUserToProcess={userToProcessSelected}
                  />
                </Modal>
              }
            </S.PatientBox>
          )
        })
      }
    </>
  )
}


const InvitationsList = ({
  userToProcessListNotAcepted,
  userToProcessListNotAceptedLoading,
  userToProcessListNotAceptedError,
  userToProcessListNull,
  userToProcessListNullLoading,
  userToProcessListNullError,
  filteredItem,
  inputText,
  updateUserToProcessHandler,
  setActivedModal,
  setCurrentIsAcepted,
  setUserToProcessSelected,
  activedModal,
  activeTab,
  deleteUserToProcessHandler,
  currentIsAcepted,
  userToProcessSelected
}) => {

  const { t, i18n: { language } } = useTranslation("global");

  if(userToProcessListNotAceptedError || userToProcessListNullError)
    return (
      <div className="reaction">
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          sizeText="20px"
          textColor="var(--blue-color)"
          widthImg="200px"
          text={t("globally.noData")}
        />
      </div>
    )

  if(userToProcessListNotAceptedLoading || userToProcessListNullLoading)
    return (
      <div className="reaction">
        <ZaiaReaction
          widthImg={"150px"}
          zaiaImg={logoZaiaLoading}
          padding={"200px 0px"}
        />
      </div>
    )

  if(userToProcessListNotAcepted?.userToProcess.concat(userToProcessListNull?.userToProcess).filter((patient) => filteredItem(patient)).length === 0 ) 
    return (
      <div className="reaction">
        <ZaiaReaction
          zaiaImg={eventsCalendarEmpty}
          text={t("globally.noData")}
          subText={t("globally.noDataDescription")}
          sizeText="33.25px"
          sizeSubText="15px"
          textColor="var(--blue-color)"
          widthImg="210px"
          />
      </div>
    )

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>{t("globally.name")}</th>
            <th>{t("globally.status")}</th>
            <th>{t("profile.invitationManagement.lastInvitationSent")}</th>
            <th>{t("profile.invitationManagement.actions")}</th>
          </tr>
        </thead>
        <tbody>
          {
            userToProcessListNotAcepted?.userToProcess.concat(userToProcessListNull?.userToProcess).filter((patient) => filteredItem(patient)).map((patient) => {
              const filterinputText = (
                inputText 
                  ? String(patient.user?.name).startsWith(inputText) ||
                    String(patient.user?.lastname).startsWith(inputText) 
                  : true
              )

              return (
                ( filterinputText) 
                ?
                <tr>
                  <td>
                    <S.PatientBox key={patient.id}>
                      <div className="box-info">
                        <ZaiaImage 
                          img={patient.user?.picture} 
                          alt="Zaia user" 
                          size={"40"} 
                          defaultImg={defaultUser}
                          rounded={true}
                        />
                        <div className="box-info__text">
                        <div className="box-info__text-name">{patient.user?.name + ' ' + patient.user?.lastname}</div>
                        <div className="box-info__text-email">{patient.user?.email}</div>
                          <p className="box-info__text-process">{P.dataTranslation(patient?.process?.processName, language)}</p>
                        </div>
                      </div>
                    </S.PatientBox>
                  </td>
                  <td>
                    {
                      patient.isAcepted === false &&
                      <div className="box-status box-status--rejected">
                        <div className="box-status__point box-status__point--rejected"></div>
                        <div className="box-status__text">{t("globally.rejected")}</div>
                      </div>
                    }
                    {
                      patient.isAcepted === null &&
                      <div className="box-status box-status--pending">
                        <div className="box-status__point box-status__point--pending"></div>
                        <div className="box-status__text">{t("globally.pending")}</div>
                      </div>
                    }
                  </td>
                  <td>
                    <div className="box-date">
                      {P.longDate(patient?.updated_at, t, language)}
                    </div>
                  </td>
                  <td>
                    <div className="box-options">
                      <button className="box-option__button" onClick={() => {updateUserToProcessHandler(patient.id, patient.isAcepted)}}>
                        <div className="box-option__button-text">{t("profile.invitationManagement.resendInvitation")}</div>
                        <i className="zaia-reenviar-icono box-option__button-icon"></i>
                      </button>
                      
                      <button className="box-option__button" onClick={() => {setActivedModal(true); setCurrentIsAcepted(patient.isAcepted); setUserToProcessSelected(patient.id)}}>
                        <div className="box-option__button-text">{t("globally.cancel")}</div>
                      </button>
                    </div>
                  </td>
                </tr>
              : null 
              )
            })
          }                  
        </tbody>
      </table>
      <Modal open={(activedModal && activeTab === 2)}>
        <DeleteInvitedPatientModal 
          setActivedModal={setActivedModal} 
          deleteUserToProcessHandler={deleteUserToProcessHandler} 
          updateUserToProcessHandler={updateUserToProcessHandler}
          currentIsAcepted={currentIsAcepted}
          idUserToProcess={userToProcessSelected}
        />
      </Modal>
    </>
  )
}

export default InvitationsManagement;
