import { gql } from "@apollo/client";

// $doctorId: uuid
export const SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID = gql`
  subscription DashBoardProstate( 
    $userId: uuid
    $customFormId: bigint!
    $userToProcessId: uuid!
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: asc}) {
          created_at
          descript
          id
          customFormFieldChanges
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {_or: {userId: {_eq: $userId}}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

//(where: {_or: {doctorId: {_eq: $doctorId}, userId: {_eq: $userId}}}, order_by: {created_at: desc})
export const SUBSCRIPTION_GET_USER_TO_CUSTOM_FORM_HISTORY = gql`
  subscription SUBSCRIPTION_GET_USER_TO_CUSTOM_FORM_HISTORY($userID: uuid, $customFormIds: [bigint!]!) {
    userToCustomFormHistory(where: {customFormId: {_in: $customFormIds}, userId: {_eq: $userID}}) {
      created_at
      customFormId
      doctorId
      id
      updated_at
      userId
      customForm {
        id
        customFormName
        color
      }
      customFormFields {
        created_at
        customFormFieldId
        customFormHistoryId
        doctorId
        id
        key
        updated_at
        userId
        value
        customFormField {
          fieldName
          id
          descript
          typeFieldProcess
          typeFieldValue
          typeFieldVariant
          customForm {
            id
            customFormName
          }
        }
      }
    }
  }
`

export const SUBSCRIPTION_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES = gql`
  subscription SUBSCRIPTION_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES( 
    $userId: uuid
    $customFormId: bigint!
    $userToProcessId: uuid!
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: desc}) {
          created_at
          descript
          id
          customFormFieldChanges
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {userId: {_eq: $userId}, created_at: {_gte: $initialDate, _lte: $finalDate}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`