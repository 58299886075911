import React, { useEffect, useMemo, useState } from "react";
import * as S from "./PatientsList.style";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import ProcessName from "../../../../../shared/processName";

// pipes
import * as P from "../../../../../../../utils/pipes";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import useRecordNavigation from "../../../../../../../hooks/useRecordNavigation";
import { Actions } from "../../../../../../../utils/actionsIds";
import usePatientList from "../../../../../../../hooks/usePatientList";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function PatientsList({
  setSelectedPatient,
  inputText,
  filterProcess,
  filterGender
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const { recordAction } = useRecordNavigation();

  const {patientList, patientListError, patientListLoading} = usePatientList()

  const getFilterText = (text) => {
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }

  if (patientListError)
    return (
      <S.PatientsList>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              widthImg="100px"
              text={"Error loading the patients"}
              sizeText="15px"
              widthText="240px"
            />
          </div>
        </div>
      </ S.PatientsList>
    )

  if(patientListLoading) 
    return(
      <S.PatientsList>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              widthImg="150px"
              zaiaImg={logoZaiaLoading}
            />  
          </div>
        </div>
      </S.PatientsList>
    )

  const filteredPatients = () => {
    return patientList?.filter((patient) => (
      (getFilterText(`${patient.name} ${patient.lastname}`) || 
      getFilterText(patient.email) ) &&
      (
        filterProcess.length > 0 
        ? filterProcess.find((process) => patient.userToProcesses.find((item) => item.process.id === process.id))
        : true
      ) && 
      (
        filterGender
        ? patient.gender === filterGender
        : true
      )
    ))
  } 

  return (
    <S.PatientsList>
      <div className="header">
        
      </div>
      <div className="table-container">
        <table className="suggestion-table">
          <thead className="suggestion-table__head">
            <tr>
              <th className="title-patient-name"> 
                {t("globally.patientName")}
              </th>
              <th className="title-email">
                {t("globally.email")}
              </th>
              <th className="title-program">
                {t("globally.program")}
              </th>
            </tr>
          </thead>
          <tbody className="suggestion-table__body">
            { 
              filteredPatients()?.map((patient, index) => {
                return (
                  <tr 
                    onClick={
                      () => {
                        recordAction(Actions.AINAVIGATOR.OPEN_MODAL_SUGGEST_PATIENT);
                        setSelectedPatient(patient);
                      }
                    }
                    className="suggestion-table__item">
                    <td>
                      <div className="patient-content">
                        <ZaiaImage
                          img={patient?.picture}
                          alt=""
                          size={40}
                          rounded={true}
                        />
                        <div className="patient-content__info">
                          <div className="patient-content__info-name-psa">
                          {`${patient.name} ${patient.lastname}`}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="patient-email">
                        {patient.email}
                      </div>
                    </td>
                    <td>
                      <div className="patient-program">
                        {
                          <Popup
                            trigger={
                              <div className="patient-option__info-program-detail">
                                {
                                  patient?.userToProcesses.length > 1 
                                  ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                  <div>{
                                    patient?.userToProcesses[0].process?.processName
                                    && P.dataTranslation(patient?.userToProcesses[0].process?.processName, language)
                                  }</div>
                                }
                              </div>
                            }
                            keepTooltipInside
                            nested
                            // position={"right bottom"}
                            on="hover"
                            disabled={patient?.userToProcesses.length > 1 ? false : true}
                          >
                            {
                              <ProcessName userToProcesses={patient?.userToProcesses}/>
                            }
                          </Popup>
                        }
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>  
      </div>
    </S.PatientsList>
  );
}

export default PatientsList;
