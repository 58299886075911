import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalHealthIndicators.style";

// graphQL
import { useQuery, useMutation } from "@apollo/client";

// Hooks
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";
import ZaiaButton from "../../../../../zaiaComponents/zaiaButton";
import ZaiaInput from "../../../../../zaiaComponents/zaiaInput";
import ZaiaModalResponseContent from "../../../../../zaiaComponents/zaiaModalResponseContent";

import HealthIndicatorFrecuency from "./components/healthIndicatorFrecuency";
import HealthIndicatorSelector from "./components/healthIndicatorSelector";
import HealthIndicatorMeasure from "./components/healthIndicatorMeasure";
import HealthIndicatorMeasureType from "./components/healthIndicatorMeasureType";
import { QUERY_GET_USER_TO_VITAL_SIGNS, QUERY_GET_VITAL_SIGNS_VALUES } from "../../../../../../graphql/queries/VitalSigns";
import { INSERT_USER_VITAL_SIGN_HISTORY, INSERT_VITAL_SIGN } from "../../../../../../graphql/mutations/PatientActions";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalHealthIndicators({ 
  setModalActive,
  patient,
  option=1
}) {

  const {
    t,
  } = useTranslation("global");

  const {data: vitalSignsList, loading: vitalSignsLoading, error: vitalSignsError} = useQuery(QUERY_GET_VITAL_SIGNS_VALUES);
  const {data: userTovitalSignsList, loading: userTovitalSignsListLoading, error: userTovitalSignsListSignsError} = useQuery(QUERY_GET_USER_TO_VITAL_SIGNS,
    {
      variables: {
        patientId: patient.id
      }
    }  
  );
  
  const [insertVitalSign, {loading: vitalSignAddedLoading}] = useMutation(INSERT_VITAL_SIGN);
  const [insertVitalSignHistory, {loading: vitalSignHistoryAddedLoading}] = useMutation(INSERT_USER_VITAL_SIGN_HISTORY);

  const [formSuccess, setFormSuccess] = useState(null); 
  const [summaryView, setSummaryView] = useState(false);

  const healthIndicatorFormInitial = [
    {
      healthIndicator: {
        value: null,
        text: ""
      },
      measureType: {
        value: null,
        text: "",
      },
      interval: {
        value: null,
        text: ""
      },
      startingHour: {
        value: null,
        text: ""
      },
      description: {
        value: null,
        text: ""
      },
    },
    {
      healthIndicator: {
        value: null,
        text: ""
      },
      measure: {
        value: null,
        text: ""
      },
      measureType: {
        value: null,
        text: "",
      },
      description: {
        value: null,
        text: ""
      },
    }
  ]

  const healthIndicatorTabs = [
    {
      id: 1,
      tabName: t("globally.createAlert"),
      inputs: [
        {
          id: 1,
          placeholder: t("healthAddData.healthIndicators.selectHealthIndicator"),
          icon: "zaia-icono-signos-vitales",
          form: ["healthIndicator"]
        },
        {
          id: 2,
          placeholder: t("healthAddData.healthIndicators.unitMeasure"),
          icon: "zaia-i-frequency",
          form: ["measureType"]
        },
        {
          id: 3,
          placeholder: t("globally.frequency"),
          icon: "zaia-i-time",
          form: ["interval", "startingHour"]
        },
      ]
    },
    {
      id: 2,
      tabName: t("healthAddData.registerMeasure"),
      inputs: [
        {
          id: 1,
          placeholder: t("healthAddData.healthIndicators.selectHealthIndicator"),
          icon: "zaia-icono-signos-vitales",
          form: ["healthIndicator"]
        },
        {
          id: 2,
          placeholder: t("healthAddData.registerMeasure"),
          icon: "zaia-i-frequency",
          form: ["measure", "measureType"]
        },
      ]
    }
  ]

  const [healthIndicatorForm, setHealthIndicatorForm] = useState(healthIndicatorFormInitial);
  const [tabSelected, setTabSelected] = useState(healthIndicatorTabs[option - 1]);
  const [inputSelected, setInputSelected] = useState(healthIndicatorTabs[option - 1].inputs[0])

  const goNextInput = () => {
    if(inputSelected.id < healthIndicatorTabs[option - 1].inputs.length - 1) {
      setInputSelected(healthIndicatorTabs[option - 1].inputs[inputSelected.id])
    }
  }  

  const changeFormField = (changesList) => {
    const healthIndicatorFormTemp = JSON.parse(JSON.stringify(healthIndicatorForm));
    for (const change of changesList) {
      if(healthIndicatorFormTemp[tabSelected.id - 1][change.fieldName]) {
        healthIndicatorFormTemp[tabSelected.id - 1][change.fieldName].value = change.value;
        healthIndicatorFormTemp[tabSelected.id - 1][change.fieldName].text = change.text;
      }
    }
    setHealthIndicatorForm(healthIndicatorFormTemp);
  }

  const handleSubmit = async () => {  
    if(summaryView === false) {
      setSummaryView(true);
    } else {
      if(tabSelected.id === 1) {
        insertUserToVitalSignFunction();
      } else {
        insertUserToVitalSignHistoryFunction()
      }
    }
    // editUserToProcessFunction();
  };

  const insertUserToVitalSignFunction = async () => {
    const form = await healthIndicatorForm[tabSelected.id - 1];

    const horaString = form.startingHour.value;
    const fechaLocal = new Date();

    const [horas, minutos] = horaString.split(':');

    fechaLocal.setUTCHours(horas);
    fechaLocal.setUTCMinutes(minutos);
    fechaLocal.setUTCSeconds(0);
    fechaLocal.setUTCMilliseconds(0);

    const fechaUTC = new Date(fechaLocal.getTime() + fechaLocal.getTimezoneOffset() * 60000);

    const dataVitalSign = {
      vitalSign: form.healthIndicator.value,
      frequency: Number(form.interval.value),
      frequencyType: "hours",
      isNotify: true,
      unit: form.measureType.value,
      startHour: fechaUTC.toISOString(),
      userId: patient.id
    }

    console.log(dataVitalSign);

    try {
      await insertVitalSign({
        variables: {
          data: dataVitalSign
        }
      });
      setFormSuccess(true)
    } catch (err) {
      setFormSuccess(false)
    }
  }

  const insertUserToVitalSignHistoryFunction = async () => {
    const form = await healthIndicatorForm[tabSelected.id - 1];
;    const dataMedication = {
      vitalSign: form.healthIndicator.value,
      unit: form.measureType.value,
      measure1: form.measure.value ? form.measure.value : 0.0,
      measure2: 0.0,
      isTaken: true,
      comment: form.description.value,
      userId: patient.id
    }
    try {
      await insertVitalSignHistory({
        variables: {
          data: dataMedication
        },
      });
      setFormSuccess(true)
    } catch (err) {
      setFormSuccess(false)
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const selectedHealthIndicator = useMemo(() => {
    return vitalSignsList?.enVitalSign.find((vitalSign) => vitalSign.value === healthIndicatorForm[tabSelected.id - 1].healthIndicator.value)
  }, [healthIndicatorForm])

  const inputsVerification = useMemo(() => {

    let verification = true;
  
    tabSelected?.inputs.forEach((input) => {      
      if(input.form.filter((form) =>  healthIndicatorForm[tabSelected.id - 1][form].value !== null).length !== input.form.length) {
        verification = false;
      };
    })
    
    const descriptionValue = healthIndicatorForm[tabSelected.id - 1].description.value;

    if( descriptionValue === "" || descriptionValue === null) {
      verification = false;
    }

    return verification;
    
  }, [tabSelected, healthIndicatorForm])

  const healthIndicatorList = useMemo(() => {

    let healthIndicatorsFilter = []
    const idsUnicos = new Set();
    healthIndicatorsFilter = userTovitalSignsList?.userToVitalSign.map((userVitalSign) => userVitalSign.vitalSignVal).filter(objeto => {
      if (!idsUnicos.has(objeto.value)) {
        idsUnicos.add(objeto.value);
        return true;
      }
      return false;
    });

    if(tabSelected.id === 1) {
      return vitalSignsList?.enVitalSign.filter((vitalSign) => {
        return !healthIndicatorsFilter?.find((userVitalSign) => userVitalSign.value === vitalSign.value)
      });
    } else {
      return healthIndicatorsFilter
    }
  }, [tabSelected, vitalSignsList, userTovitalSignsList])

  const getDinamicInputsViews = () => {

    if(summaryView) {
      return (
        <div className="custom-form-inputs">
          <div className="summary">
            <div className="summary-title">
              {t("globally.summary")}
            </div>
            <div className="summary-items">
              {
                tabSelected.inputs.map((input) => {  
                  return (
                    <div className="summary-item" key={input.id}>
                      <div className="summary-item__title">
                        <i className={'summary-item__icon icon ' + input.icon} />
                        <div className="summary-item__name input-text__input-name">{input.placeholder}</div>
                      </div>
                      <div className="summary-item__info">
                        {input.form.map((form) => {return healthIndicatorForm[tabSelected.id - 1][form]?.text})}
                      </div>
                    </div>
                  )
                })
              }
              <div className="summary-item">
                <div className="summary-item__title">
                  <i className={'summary-item__icon icon zaia-i-message-outline'} />
                  <div className="summary-item__name input-text__input-name">{t("globally.description")}</div>
                </div>
                <div className="summary-item__info">
                  {healthIndicatorForm[tabSelected.id -1].description.text}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    
    if (vitalSignsError || userTovitalSignsListSignsError)
    return (
      <div className="custom-form-inputs">
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          text={"Error loading healthIndicators"}
          sizeText="20px"
          textColor="var(--blue-color)"
          widthImg="110px"
        />
      </div>
    )
    
  
    if (vitalSignsLoading || userTovitalSignsListLoading)
    return (
      <div className="custom-form-inputs">
        <ZaiaReaction
          zaiaImg={logoZaiaLoading}
          sizeText="20px"
          textColor="var(--blue-color)"
          widthImg="110px"
        />
      </div>
    )

    if (tabSelected.id === 1) {      

      return(
        <div className="custom-form-inputs">
          {
            <div className={`health-indicator-inputs ${inputSelected.id === 1 && 'health-indicator-inputs--selected'}`}>
              <HealthIndicatorSelector
                selectedHealthIndicator={healthIndicatorForm[0].healthIndicator}
                changeFormField={changeFormField}
                healthIndicatorList={healthIndicatorList}
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`health-indicator-inputs ${inputSelected.id === 2 && 'health-indicator-inputs--selected'}`}>
              <HealthIndicatorMeasureType
                selectedMeasure={healthIndicatorForm[0].measureType}
                selectedHealthIndicator={healthIndicatorForm[0].healthIndicator}
                measureList={selectedHealthIndicator?.comment.unit}
                changeFormField={changeFormField}
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`health-indicator-inputs ${inputSelected.id === 3 && 'health-indicator-inputs--selected'}`}>
              <HealthIndicatorFrecuency
                selectedInterval={healthIndicatorForm[0].interval}
                selectedStartingHour={healthIndicatorForm[0].startingHour}
                frecuencyList={vitalSignsList?.enFrequencyType}
                changeFormField={changeFormField}
              />
            </div>
          }
        </div>
      )
    }
    
    if (tabSelected.id === 2) {
      return(
        <div className="custom-form-inputs">

          <div className={`health-indicator-inputs ${inputSelected.id === 1 && 'health-indicator-inputs--selected'}`}>
            <HealthIndicatorSelector
              selectedHealthIndicator={healthIndicatorForm[1].healthIndicator}
              changeFormField={changeFormField}
              healthIndicatorList={healthIndicatorList}
              goNextInput={goNextInput}
              />
          </div>

          <div className={`health-indicator-inputs ${inputSelected.id === 2 && 'health-indicator-inputs--selected'}`}>
            <HealthIndicatorMeasure
              selectedMeasure={healthIndicatorForm[1].measure}
              selectedMeasureType={healthIndicatorForm[1].measureType}
              selectedHealthIndicator={healthIndicatorForm[1].healthIndicator}
              changeFormField={changeFormField}
              measureList={selectedHealthIndicator?.comment.unit}
            />
          </div>

        </div>
      )    
    }
  }

  if(
    vitalSignAddedLoading ||
    vitalSignHistoryAddedLoading
  ) 
    return(
      <S.ModalHealthIndicators>
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <ZaiaReaction
            widthImg={"150px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalHealthIndicators>
    )

  if(formSuccess)
    return(
      <S.ModalHealthIndicators>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            title={tabSelected.id === 1 ? t("healthAddData.healthIndicators.successfulAlertCreation") : t("healthAddData.healthIndicators.failedAlertCreation")}
          />
        </div>
      </S.ModalHealthIndicators>
    )

  if(formSuccess === false)
    return(
      <S.ModalHealthIndicators>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={formSuccess}
            title={t("globally.errorForm")}
            subtitle={tabSelected.id === 1 ? t("healthAddData.healthIndicators.successfulRecordCreation") : t("healthAddData.healthIndicators.recordMedicine")}        
            button1Text={t("globally.accept")}                
          />
        </div>
      </S.ModalHealthIndicators>
    )

  return (
    <S.ModalHealthIndicators>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("globally.healthIndicators")}</div>
            <div className="header__subtitle">{t("healthAddData.healthIndicators.subtitle")}</div>
          </div>
          <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="modal-tabs">
          {
            healthIndicatorTabs.map((tab) => {
              return (
                <div
                  key={tab.id} 
                  className={`tab ${tab.id === tabSelected?.id && "tab tab--selected"}`}
                  onClick={() => 
                    {
                      setSummaryView(false)
                      setTabSelected(tab)
                    }
                  }
                >
                  {
                    tab.tabName
                  }
                </div>
              )
            })
          }
        </div>
        <div className="inputs">
          <div className="rows">
            <div className="principal-input">
              <HealthIndicatorInputs
                inputsList={tabSelected.inputs}
                patient={patient}
                inputSelected={inputSelected}
                setInputSelected={setInputSelected}
                healthIndicatorForm={healthIndicatorForm[tabSelected.id - 1]}
                changeFormField={changeFormField}
                setSummaryView={setSummaryView}
              />
            </div>
            {getDinamicInputsViews()}
          </div>  
        </div>
        <div className="button-container">
          <div onClick={() => handleSubmit()}>
            <ZaiaButton
              type="button"
              option="primary"
              marginTop="5"
              backgroundColor={ inputsVerification ? "#17AF54" : "#E0E6ED"}
              title={summaryView ? tabSelected.id === 1 ? t("globally.createAlert") : t("healthAddData.registerMeasure") : t("globally.done")}
              borderRadius="5"
              fontSize="32.25"
              height="64"
              fontFamily={"Nunito Sans"}
              shadow={false}
              border={false}
              color={inputsVerification ? "#FFFFFF" : "#8A939D"}
              disabled={!inputsVerification}
            />
          </div>
        </div>
      </div>
    </S.ModalHealthIndicators>
  );
}

const HealthIndicatorInputs = ({
  patient,
  inputsList, 
  inputSelected,
  setInputSelected,
  healthIndicatorForm,
  changeFormField,
  setSummaryView
}) => {

  const { t } = useTranslation("global");

  return (
    <div className="principal-input__container">      
      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-person"/>
          {t("globally.patient")}
        </div>
        <div className="input-value">
          {`${patient?.name} ${patient?.lastname}`}
        </div>
      </div>
      {
        inputsList.map((input) => {
          const filledVerification = input.form.filter((form) => healthIndicatorForm[form].value !== null).length === input.form.length;

          return (
            <div className="input-box__input" key={input.id} onClick={() => {
              setInputSelected(input)
              setSummaryView(false);
            }}>
              <S.InputOption
                selected={inputSelected?.id === input.id}
                filled={filledVerification}
              >
                <i className={'input-icon icon ' + input.icon} />
                <div className="input-text">
                  {input.placeholder}
                  <b>{input.form.map((form) => {return healthIndicatorForm[form].text})}</b>
                </div>
                {
                  !filledVerification 
                  ? <i className={'input-icon-arrow icon zaia-icono-dropdown-light'} />
                  : <i className={'input-icon-check icon zaia-i-check-1'} />
                }
                { 
                  inputSelected?.id === input.id &&
                  <div className="shape-container">
                    <div className="shape">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 25 32" fill="none">
                        <path d="M0 31.5C-2.16635e-07 26.544 4.32006 22.5263 9.64912 22.5263L15.3509 22.5263C20.6799 22.5263 25 26.544 25 31.5L25 0.499999C25 5.45603 20.6799 9.47368 15.3509 9.47368L9.64912 9.47368C4.32006 9.47368 -1.13842e-06 5.45603 -1.35505e-06 0.5L0 31.5Z" fill="#6C6BCC"/>
                      </svg>
                    </div>
                  </div>
                }
              </S.InputOption>
            </div>
          )
        })
      }

      <div className="input-box input-box--last">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-message-outline"/>
          {t("healthAddData.addNotes")}
        </div>
        <ZaiaInput
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          fontSize={"15px"}
          placeholder={t("globally.eventDescription")}
          type="text"
          name="description"
          padding={"20px"}
          borderRadius={"14px"}
          textarea={true}
          errorOption={false}
          height={"100%"}
          getText={(e) => changeFormField([{
            value: e, 
            text: e, 
            fieldName: "description"
          }])}
          value={healthIndicatorForm.description.text}
        />
      </div>
    </div>
  )
}


export default ModalHealthIndicators;
