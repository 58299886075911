import Dashboard from "../pages/private/dashboard";
import Patients from "../pages/private/patients";
import Algorithms from "../pages/private/algorithms";
import Outcomes from "../pages/private/outcomes";
import Chat from "../pages/private/chat";
import Profile from "../pages/private/profile";
import GenerativeAI from "../pages/private/generativeAI";
import Agenda from "../pages/private/agenda";
import AINavigator from "../pages/private/aiNavigator";
import ModalPDFReader from "../pages/private/shared/modals/modalPDFReader/ModalPDFReader";
import Surveys from "../pages/private/surveys";
import Police from "../pages/private/police";

const privateRoutesList = [
  {
    id: "alerts",
    key: "alerts",
    path: "/alerts",
    name: "globally.dashboard",
    icon: "zaia-icono-home-relleno",
    component: () => <Dashboard />,
  },
  // {
  //   id: "patients",
  //   key: "patients",
  //   path: "/patients/:patientID?",
  //   name: "globally.patients",
  //   icon: "zaia-icono-pacient",
  //   component: () => <Patients />,
  // },
  {
    id: "algorithms",
    key: "algorithms",
    path: "/algorithms/:processNameKey?", 
    name: "globally.algorithms",
    icon: "zaia-icono-algoritmos-1",
    component: () => <Algorithms />,
  },
  // {
  //   id: "outcomes",
  //   key: "outcomes",
  //   path: "/outcomes/:processNameKey?/:patientID?",
  //   name: "globally.outcomes",
  //   icon: "zaia-icono-resultados",
  //   component: () => <Outcomes />,
  // },
  {
    id: "chat",
    key: "chat",
    path: "/chat/:chatID?",
    name: "globally.chat",
    icon: "zaia-icono-chat",
    component: () => <Chat />,
  },
  {
    id: "generativeai",
    key: "generativeai",
    path: "/generativeai/:processNameKey?", 
    name: "globally.generativeai",
    icon: "zaia-icono-chat",
    component: () => <GenerativeAI/>,
  },
  {
    id: "profile",
    key: "profile",
    path: "/profile",
    name: "globally.profile",
    icon: "zaia-icono-nombre",
    component: () => <Profile />,
  },
  {
    id: "agenda",
    key: "agenda",
    path: "/agenda",
    name: "globally.agenda",
    component: () => <Agenda />
  },
  {
    id: "ainavigator",
    key: "ainavigator",
    path: "/ainavigator/:patientID?",
    name: "globally.agenda",
    component: () => <AINavigator />
  },
  {
    id: "file-reading",
    key: "file-reading",
    path: "/file-reading",
    name: "globally.fileReading",
    component: () => <ModalPDFReader />
  },
  {
    id: "surveys",
    key: "surveys",
    path: "/surveys",
    name: "globally.surveys",
    component: () => <Surveys />
  },
  {
    id: "police",
    key: "police",
    path: "/police/:patientID?",
    name: "globally.police",
    component: () => <Police />,
  }
];

export default privateRoutesList;
