import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalAddOutcomesEvent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 960px;
    margin: auto;
    box-sizing: border-box;
    /* max-height: 80%; */
    /* overflow-y: auto; */
    border-radius: 14px;
    .header {
      padding: 20px 40px 0 40px;
      display: flex;
      justify-content: space-between;
      &__title {
        color: #68688D;
        font-family: "Nunito Sans Black";
        font-size: 32.25px;
      }
      &__subtitle {
        color: #333365;
        font-family: "Nunito Sans Light";
        font-size: 19px;
      }
      &__close {
        width: 28px;
        height: 28px;
        background-color: #36369B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #fff;
      }
    }
    .rows-container {
      height: calc(100% - 199px);
      overflow: auto;
      
    }
    .rows {
      display: flex;
      padding: 20px 40px 0px;
      max-height: 591px;
    }
    .principal-input {
      padding-right: 20px;
      width: 100%;
      &__form {
        height: 100%;
      }
      &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .custom-form-inputs {
      padding: 20px 20px 20px 15px;
      /* margin-left: 5px; */
      background-color: #F1F3F5;
      width: 100%;
      border-radius: 10px;
      border-left: 5px solid #6C6BCC;
      overflow-y: auto;
    }
    .input-box-time {
      padding: 12px 0px 30px 0px;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      &__icon {
        color: #3E3E5B; 
        margin-right: 10px;
      }
      .input-content {
        align-items: center;
        display: flex;      
        margin-right: 20px;
        &__label-bold {
          color: #3E3E5B; 
          white-space: nowrap;
          margin-right: 10px;
          font-size: 15px;
        }
      }
      &__separator {
        border-left: 1px solid #E6E6E6;
        height: 16px;
        margin-left: 9px;
        margin-right: 29px;
      }
      .button-add-favourite {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #AAAADB;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        &__icon {
          font-size: 13px;
          color: #36369B;
        }
      }
    }
    .input-box {
      padding-bottom: 20px;
      color: #3E3E5B;
      display: flex;
      flex-direction: column;
      position: relative;
      &--border {
        .input-container {
          border-top: 2px solid #6C6BCC;
          border-right: 5px solid #6C6BCC;
          border-bottom: 2px solid #6C6BCC;
          border-left: 2px solid #6C6BCC;
        }
      }
      &--last {
        padding-bottom: 0px;
        height: 100%;
      }
      &__label {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 6px;
        &-icon {
          margin-right: 10px;
        }
      }
      .label-and-button {
        display: flex;
        justify-content: space-between;
        .highlight-button {
          display: flex;
          opacity: 0.5;
          color: #36369B;
          align-items: center;
          margin-bottom: 6px;
          cursor: pointer;
          &__icon {
            margin-right: 5px;
          }
          &__text {
            font-size: 14px;
          }
          &--active {
            opacity: 1;
            font-family: "Nunito Sans Bold";
            color: #FFA800;
          }
        }
      }
      .shape-container {
        position: absolute;
        right: -23px;
        top: 41px;
      }
    }
    .button-container {
      padding: 20px 40px 20px;
    }
  }
  
  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;