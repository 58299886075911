import React, { useState } from "react";
import * as S from "./Profile.style";

// Components
import ProfileForm from "./components/profileForm";
import InvitationsManagement from "../patients/components/invitationsManagement";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t, i18n: { language } } = useTranslation("global");

  const tabs = [
    {
      id: 1,
      title: t("profile.myAccount"),
      icon: "zaia-i-person-1"
    },
    {
      id: 2,
      title: t("profile.patientsManagement"),
      icon: "zaia-i-patient-mangement"
    }
  ]

  const [tabSelected, setTabSelected] = useState(tabs[0])

  return (
    <S.ProfileDiv>
      <div className="profile-tabs">
        {
          tabs.map((tab) =>
            {
              return (
                <div
                  className={`profile-tab ${tab.id === tabSelected.id && "profile-tab--active"}`}
                  key={tab.id}
                  onClick={() => setTabSelected(tab)}
                >
                  <i className={`profile-tab__icon icon ${tab.icon}`}></i>
                  <div className="profile-tab__text">
                    {tab.title}
                  </div>
                </div>
              )
            }
          ) 
        }
      </div>
      <div className="profile-content">
      {
        tabSelected.id === 1 &&
          <ProfileForm />
        }
      {
        tabSelected.id === 2 &&
          <InvitationsManagement/>
      }
      </div>
      {/* <div className="profile__right-side">
        <MedicalInstitutions />
      </div> */}
    </S.ProfileDiv>
  );
}

export default Profile;
