import React, { useState } from "react";
import * as S from "./PatientHeaderSections.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { useHistory } from "react-router-dom";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// Components
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";

// pipes
import * as P from "../../../../../utils/pipes";
import ProcessName from "../../../shared/processName";
import Modal from "../../../../../utils/modal";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const defaultPatientImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`

function PatientHeaderSections({ 
  patientID, 
  sections,
  selectedSection, 
  setSelectedSection,
  patientDeselectRedirectionRoute,
  selectPatientAction,
  patientSelectedVisible=true
}) {

  const { t, i18n: { language }}  = useTranslation("global");
  const [additionalInfoActive, setAdditionalInfoActive] = useState(false);
  const history = useHistory();
  const { recordAction } = useRecordNavigation();
  const [activeModalPatient, setActiveModalPatient] = useState(false);
  const { selectPatient, removePatient } = useAssignSelectPatient();

  const { data: userData } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientID },
  });

  const patient = {
    id: userData?.user[0]?.id,
    img: userData?.user[0]?.picture,
    name: userData?.user[0]?.name ? userData?.user[0]?.name + " " + userData?.user[0]?.lastname : '',
    process: userData?.user[0]?.userToProcesses?.length > 1 ? t("globally.variousProcesses") : P.dataTranslation(userData?.user[0]?.userToProcesses[0]?.process?.processName, language),
    phone: userData?.user[0]?.phone,
    weight: userData?.user[0]?.weight,
    height: userData?.user[0]?.height,
    birthdate: P.getAge(userData?.user[0]?.birthdate),
    nameCarer: userData?.user[0]?.nameCarer,
    phoneCarer: userData?.user[0]?.phoneCarer
  }

  const [{ selectedPatient }] = useStateValue();

  const removePatientAction = () => {
    removePatient();
    if(patientDeselectRedirectionRoute) {
      history.push(patientDeselectRedirectionRoute); 
    }
  }

  return (
    <S.PatientHeaderSections additionalInfoActive={additionalInfoActive}>
      { 
        <Modal open={activeModalPatient}>
          <ModalSelectPatient
            setModalActive={setActiveModalPatient}
            setPatient={(patient) => selectPatient(patient.id)}
            idAction={selectPatientAction}
          />
        </Modal>
      }
      <div className="dashboard-patient-header">
        {
          patientSelectedVisible
          ? <div className="dashboard-patient-header__left">
            {
              selectedPatient
              ? <>
                <button className="dashboard-patient-header__left-close" onClick={() => removePatientAction()}>
                  <i className="dashboard-patient-header__left-close-icon icon zaia-icono-cerrar"></i>
                </button>
                <div className="dashboard-patient-header__left-img">
                  <ZaiaImage 
                    img={patient?.img} 
                    alt="" 
                    size={40} 
                    defaultImg={defaultUser} 
                    rounded
                  />
                </div>
                <div className="dashboard-patient-header__left-text">
                  <div className="dashboard-patient-header__left-text-title">
                    {patient.name}
                  </div>
                  {/* <div className="dashboard-patient-header__left-text-programs">
                    <strong>{t("globally.program")}</strong>
                    {
                      <Popup
                        trigger={
                          <div className="patient-option__info-program-detail">
                            {
                              userData?.user[0]?.userToProcesses.length > 1 
                              ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                              <div>{P.dataTranslation(userData?.user[0]?.userToProcesses[0].process?.processName, language)}</div>
                              
                            }
                          </div>
                        }
                        keepTooltipInside
                        nested
                        on="hover"
                        disabled={userData?.user[0]?.userToProcesses.length > 1 ? false : true}
                      >
                        {
                          <ProcessName userToProcesses={userData?.user[0]?.userToProcesses}/>
                        }
                      </Popup>
                    }                  
                  </div> */}
                </div>
              </>
              : <div className="patient-selector" onClick={() => setActiveModalPatient(true)}>
                <div className="patient-selector__box-icon">
                  {
                    defaultPatientImg &&
                    <img src={defaultPatientImg} alt="user" className="patient-selector__icon" />
                  }
                </div>
                <div className="patient-selector__text">
                  {t("filterPanel.filterByPatient")}
                </div>
              </div>
            }
          </div>
          : <div
            className="dashboard-patient-header__left"
          ></div>
        }
        <div className="dashboard-patient-header__right">
          <div className="patient-options">
            {
              sections.map((section) => {
                return(
                  <div
                    key={section.id} 
                    className={`patient-option ${selectedSection.id === section.id && 'patient-option--selected'}`} 
                    onClick={
                      () => {
                        if(section.action) recordAction(section.action);
                        setSelectedSection(section);
                      }
                    }
                  >
                    <div className="patient-option__text">{section.name}</div>
                    <div className={`shape__container ${selectedSection.id === section.id && 'shape__container--selected'}`}>
                      <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 31 25" fill="none">
                          <path d="M0 0C4.95603 0 8.97368 4.32006 8.97368 9.64912V15.3509C8.97368 20.6799 4.95603 25 0 25H31C26.044 25 22.0263 20.6799 22.0263 15.3509V9.64912C22.0263 4.32006 26.044 0 31 0H0Z" fill="white"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </S.PatientHeaderSections>
  );
}

export default PatientHeaderSections;
