import styled from "styled-components";

export const FollowUpBox = styled.div`
  /* width: 100%; */
  padding: 10px;
  display: flex;
  background-color: ${({backgroundColor}) => backgroundColor};
  border-radius: 14px;
  margin: 10px 15px 0 38px;
  cursor: pointer;
  .left-content {
    width: 38%;
    display: flex;
    align-items: center;
    .img-container {
      margin-right: 10px;
      .patient-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .patient-info {
      &__frecuency {
        color: var(--textiii-grey, #707582);
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
        text-align: start;
        b {
          margin-left: 5px;
        }
      }
      &__name{
        color: #3E3DA3;
        font-family: "Nunito Sans";
        font-size: 17px;
        margin: 5px 0;
      }
      .program-info {
        width: fit-content;
        display: flex;
        color: #1F1F52;
        font-family: "Nunito Sans";
        font-size: 14px;
        border-radius: 5.552px;
        background: #FFF;
        &__risk {
          margin-left: 16px;
          font-family: "Nunito Sans Light";
          b {
            margin-left: 5px;
          }
        }
      }
    }
  }
  .right-content {
    width: 62%;
    display: flex;
    border-radius: 14px;
    background: ${({color}) => color};
    padding: 20px;
    transition: background-color 0.8s;
    &:hover {
      background-color: ${({hoverColor}) => hoverColor};
    }
    .dates-box {
      display: flex;
      align-items: center;
      width: 100%;
      .date-container {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        &__icon {
          margin-right: 10px;
          font-size: 15px;
        }
        &__info-date {
          color: #FFF;
          font-family: "Nunito Sans";
          font-size: 17px;
        }
        &__info-state {
          display: flex;
          align-items: center;
          &-days-time {
            margin-left: 5px;
          }
          b {
            font-size: 18.789px;
          }
        }
        &--initial-date {
          border-right: 1px solid #FFFFFF;
          padding-right: 10px;
          width: 52%;
        }
        &--final-date {
          width: 48%;
          padding-left: 28px;
        }
      }
    }
  }
`;