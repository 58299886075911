import React, { useState } from "react";
import * as S from "./ModalFiltersAiNavigator.style";
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";

// Routes
import { useEffect } from "react";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../graphql/queries/Process";
import { useQuery } from "@apollo/client";

function ModalFiltersAiNavigator({ 
  filterProcess,
  setFilterProcess,
  filterGender,
  setFilterGender,
  setFiltersOptionsActived
}) {


  const {t, i18n: { language }} = useTranslation("global");
  const { selectPatient, removePatient } = useAssignSelectPatient();

  const [filterProcessLocal, setFilterProcessLocal] = useState(filterProcess);
  const [filterGenderLocal, setFilterGenderLocal] = useState(filterGender);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27)  {
        closeModal();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);


  const closeModal = () => {
    setFiltersOptionsActived(false);
  }

  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES);

  const addProcess = (item) => {
    if(!filterProcessLocal.find((process) => process.id === item.id)) {
      setFilterProcessLocal([...filterProcessLocal, item]);
    }
  }

  const deleteProcess = (item) => {
    setFilterProcessLocal(filterProcessLocal.filter(process => process.id !== item.id));
  }

  const setFilters = () => {
    setFilterGender(filterGenderLocal);
    setFilterProcess(filterProcessLocal);
    closeModal()
  }

  const filtersActived = () => {
    return (
      // filterGenderLocal 
      filterProcessLocal.length > 0
    )
  }

  return (
    <S.ModalFiltersAiNavigator>
      <div className="modal-background" onClick={() => closeModal()}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="title">
            <i className="title__icon icon zaia-i-filter"></i>
            <div className="title__text">{t("globally.filterBy")}</div>
          </div>
          <div className="close" onClick={() => closeModal()}>
            <i className="close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="content">
          <div className="filter-space">
            <div className="filter-space__title">{t("globally.program")}</div>
            <div className="filter-space__subtitle">{t("globally.selectOneOrMorePrograms")}</div>
            <div className="filter-space__content">
              <div className="process-list">
                {
                  processTypes?.process.map((item) => {
                    return (
                      <div 
                        className={`process-item ${filterProcessLocal?.find((process) => process.id === item.id) && "process-item--actived"}`}
                        key={item.id}
                        onClick={
                          () => {
                            if(filterProcessLocal.find((process) => process.id === item.id)) {
                              deleteProcess(item);
                            } else {
                              addProcess(item);
                            }
                          }
                        }
                      >
                        {
                          filterProcessLocal.find((process) => process.id === item.id)
                          ? <i className="process-item__check-icon process-item__check-icon--on zaia-i-checkbox-on"></i>
                          : <i className="process-item__check-icon process-item__check-icon--off zaia-i-checkbox-off"></i>
                        }
                        <div className="process-item__text">
                          {P.dataTranslation(item.processName, language)}
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="filter-space">
            <div className="filter-space__title"></div>
            <div className="filter-space__subtitle">{t("globally.gender")}</div>
            <div className="filter-space__content">
              <div className="gender-tabs">
                <div 
                  className={`gender-item ${filterGenderLocal === "masculine" && 'gender-item--actived'}`}
                  onClick={() => {
                    setFilterGenderLocal("masculine")
                  }}
                >
                  <i className="gender-item__icon icon zaia-mars"></i>
                  <div className="gender-item__text">{t("globally.male")}</div>
                </div> 
                <div 
                  className={`gender-item ${filterGenderLocal === "femenine" && 'gender-item--actived'}`}
                  onClick={() => {
                    setFilterGenderLocal("femenine")
                  }}
                >
                  <i className="gender-item__icon icon zaia-venus"></i>
                  <div className="gender-item__text">{t("globally.female")}</div>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer__label">{t("globally.selectOneOrMoreOptions")}</div>
          <div className="footer__button">
            <button 
              onClick={() => filtersActived() ? setFilters() : null}
              className={`filter-button ${filtersActived() && "filter-button--actived"}`} 
            >
              {
                !filtersActived() &&
                <div className="filter-button__icon icon zaia-i-lock-chat"></div>
              }
              <div className="filter-button__text">
                {t("globally.filterBy")}
              </div>
            </button>
          </div>
        </div>
      </div>
    </S.ModalFiltersAiNavigator>
  );
}

export default ModalFiltersAiNavigator;
