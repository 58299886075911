import React, { useMemo } from "react";
import * as S from "./CmpBoxesContainer.style";
import * as P from "../../../../../../../utils/pipes";
import { useTranslation } from "react-i18next";
import useChatVerification from "../../../../../../../hooks/useGetListOptions";

function CmpBoxesContainer({
  title,
  item,
  insertfunction,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  isHistorical,
}) {
  const { i18n: { language } } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const {listOptions} = useChatVerification(item, itemParent, customFormFields, isHistorical);

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === fieldOption.key
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key,
    };
    insertfunction(userToCustomFormField);
  };

  const optionKey = customFormChangeItem
    ? customFormChangeItem.key
    : item?.userToCustomFormFields[0]?.key;

  const currentPayload = useMemo(() => {
    const currentOption = listOptions.find((item) => item.key === optionKey);
    let payload;
    if (P.dataTranslation(currentOption.payload, language)) {
      payload = P.dataTranslation(currentOption.payload, language);
    } else {
      const payloadFormated = JSON.parse(currentOption.payload);
      try {
        payload = "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        payload = null;
      }
    }

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionKey]);

  return (
    <S.CmpBoxesContainer>
      <h5>{title}</h5>
      <div className="boxes-container">
        {listOptions.map((data) => {
          return (
            <S.Box
              active={data.key === optionKey}
              key={data.id}
              onClick={() => changeOptionItem(data)}
            >
              <div className="risk-group-box-text">
                {P.dataTranslation(data.names, language)}
              </div>
            </S.Box>
          );
        })}
      </div>
      {!currentPayload ? null : (
        <div className="big-info-card">
          <h4>{currentPayload}</h4>
        </div>
      )}
    </S.CmpBoxesContainer>
  );
}

export default CmpBoxesContainer;
