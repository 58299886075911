import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../../../contextAPI/StateProvider";  
import * as S from "./DashboardFilterPanel.style";

// Utils
import { Actions } from "../../../../../utils/actionsIds";

// Hooks
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// Pipes
import * as P from '../../../../../utils/pipes';

// GraphQL
import { useLazyQuery, useQuery } from "@apollo/client";
import { QUERY_GET_USERS_BY_FILTER } from "../../../../../graphql/queries/User";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";

// Components
import UserSelectorPanel from "../../../shared/userSelectorPanel";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import RangeDatesSelectorPanel from "../../../shared/rangeDatesSelectorPanel";
import Modal from "../../../../../utils/modal";
import ModalRangeCalendar from "../../../shared/modals/modalRangeCalendar";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";
import ModalSelectProgram from "../../../shared/modals/modalSelectProgram";

function DashboardFilterPanel({
  processesFiltered,
  setProcessesFiltered,
  levelReport,
  setLevelReport,
  filterSelected,
  setFilterSelected,
  severitiesList,
  initialDateRange,
  finishDateRange,
  setInitialDateRange,
  setFinishDateRange,
}) {
  
  const { t, i18n: { language } } = useTranslation("global");
  
  const cardSelectedPatient = useRef(null);
  const container = useRef(null);
  const filtersContainer = useRef(null);
  const filtersContent = useRef(null);

  const [filtersContainerHeight, setFiltersContainerHeight] = useState(0);
  const [filtersContentHeight, setFiltersContentHeight] = useState(0);
  
  const [activeinput, setActiveinput] = useState(false);
  const [inputText] = useState('');
  const [severityFilterCollapsed, setSeverityFilterCollapsed] = useState(false);
  const [alertTypeFilterCollapsed, setAlertTypeFilterCollapsed] = useState(false);
  const [commentsFilterActive, setCommentsFilterActive] = useState(false);
  const [temporalLevelReport, setTemporalLevelReport] = useState(0);
  const [cardSelectedPatientHeight, setCardSelectedPatientHeight] = useState(0);
  const [overflowActive, setOverflowActive] = useState(false);
  const { height } = useWindowDimensions();

  const [filterOptionsActive, setFilterOptionsActive] = useState(null);
  
  const [{ selectedPatient }] = useStateValue();
  const { recordAction } = useRecordNavigation();

  useEffect(() => {
  }, [selectedPatient, cardSelectedPatient.current?.clientHeight]);
  
  useEffect(() => {
    const handleClickOutside = e => {
      if (!container?.current.contains(e.target)) {
        setActiveinput(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if ( inputText.length > 1 && activeinput) {
      setFilterOptionsActive(0);
    }
  }, [inputText]);

  const deleteProcess = (process) => {
    setProcessesFiltered(processesFiltered.filter(item => item !== process));
  }

  const selectLevelReport = (commentActive, level) => {
    if(commentActive) {
      if(commentsFilterActive) {
        setCommentsFilterActive(false);
        setLevelReport(temporalLevelReport);
      } else {
        setCommentsFilterActive(true);
        setLevelReport(4);
      }
    } else {
      setTemporalLevelReport(level);
      setLevelReport(level);
      setCommentsFilterActive(false);
    }
  }

  const alertTypeList = [
    {
      id: 0,
      name: t("globally.all"),
      icon: "home",
    },
    {
      id: 1,
      name: t("globally.symptoms"),
      icon: "sintomas",
    },
    {
      id: 2,
      name: t("globally.medications"),
      icon: "medicina",
    },
    {
      id: 3,
      name: t("globally.vitalSign"),
      icon: "signos-vitales",
    }
  ]

  useEffect(() => {
    setCardSelectedPatientHeight(cardSelectedPatient.current?.clientHeight);
  }, [selectedPatient, cardSelectedPatient, filterOptionsActive]);
  
  useEffect(() => {
    setFiltersContainerHeight(filtersContainer.current?.clientHeight);
    setFiltersContentHeight(filtersContent.current?.clientHeight);
    
  }, [filtersContainer.current?.clientHeight, filtersContent.current?.clientHeight, height]);

  useEffect(() => {
    if((filtersContainerHeight - 40) > filtersContentHeight) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false)
    }
  }, [filtersContainerHeight, filtersContentHeight]);

  const PanelRightOptions = () => {
    return (
      <>
        {
          filterOptionsActive === 0 &&	
          <UserSelectorPanel
            setFilterOptionsActive={setFilterOptionsActive}
            inputText={inputText}
          />
        }
        {
          filterOptionsActive === 1 &&
          <RangeDatesSelectorPanel
            setFilterOptionsActive={setFilterOptionsActive}
            initialDateRange={initialDateRange}
            finishDateRange={finishDateRange}
            setInitialDateRange={setInitialDateRange}
            setFinishDateRange={setFinishDateRange}
          />
        }
        {
          filterOptionsActive === 2 &&	
          <ProcessFiltered
            
          />
        }
      </>
    )
  }

  const SeverityFilter = () => {
    return (
      <>
        <div className="filter__title-collapsible" onClick={() => setSeverityFilterCollapsed(!severityFilterCollapsed)}>
          <i className="filter__title-collapsible-principal-icon icon zaia-i-calendar-2"/>
          <div className={`filter__title-collapsible-text ${severityFilterCollapsed && "filter__title-collapsible-text--expanded"}`}>{t("globally.severity")}</div>
          <i className={`filter__title-collapsible-icon zaia-icono-dropdown-light ${severityFilterCollapsed && "filter__title-collapsible-icon--expanded"}`}/>
        </div>
        { severityFilterCollapsed &&
          <div className="filter__content-collapsible">
            <div className="filter__content-collapsible-items-list">
              {
                severitiesList.map((severity, index) => {
                  return (
                    <div 
                      id={Actions.ALERTS.FILTERS}
                      className={`filter-item ${severity.id === levelReport && "filter-item--selected"}`} 
                      key={index} 
                      style={{
                        color: severity.color
                      }} 
                      onClick={
                        () => {
                          recordAction(Actions.ALERTS.FILTERS);
                          selectLevelReport(false, severity.id);
                        }
                      }
                    >
                      <div className={`filter-item__point ${severity.id === levelReport && "filter-item__point--selected"}`} 
                        style={{
                          backgroundColor: severity.color
                        }} 
                      />
                      <div className={`"filter-item__name"`}>{severity.name}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </>
    )
  }

  const AlertTypeFilter = () => {
    return (
      <>
        <div className="filter__title-collapsible" onClick={() => setAlertTypeFilterCollapsed(!alertTypeFilterCollapsed)}>
          <i className="filter__title-collapsible-principal-icon icon zaia-bell-icon"/>
          <div className={`filter__title-collapsible-text ${alertTypeFilterCollapsed && "filter__title-collapsible-text--expanded"}`}>{t("filterPanel.alertType")}</div>
          <i className={`filter__title-collapsible-icon icon zaia-icono-dropdown-light ${alertTypeFilterCollapsed && "filter__title-collapsible-icon--expanded"}`}/>
        </div>
        { alertTypeFilterCollapsed &&
          <div className="filter__content-collapsible">
            <div className="filter__content-collapsible-items-list">
              {
                alertTypeList.map((alertType, index) => {
                  return (
                    <div 
                      id={Actions.ALERTS.FILTERS}
                      className={`filter-item filter-item--symptoms ${alertType.id === filterSelected && "filter-item--selected-symptom"}`} 
                      key={index} 
                      onClick={
                        () => {
                          recordAction(Actions.ALERTS.FILTERS);
                          setFilterSelected(alertType.id)
                        }
                      }
                    >
                      <i className={`filter-item__icon icon zaia-icono-${alertType.icon}`}/>
                      <div className="filter-item__name">{alertType.name}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        }
      </>
    )
  }

  const activePeriodOfTimeFilter = () => {
    setFilterOptionsActive(filterOptionsActive !== 1 ? 1 : null)
  }

  const activeProgramsFilter = () => {
    setFilterOptionsActive(filterOptionsActive !== 2 ? 2 : null)
  }

  const clearAllFilters = () => {
    clearRangeDateFilter();
    clearProgramsFilter();
    selectLevelReport(false, 0);
    setFilterSelected(0);
  }

  const clearRangeDateFilter = () => {
    setInitialDateRange(null);
    setFinishDateRange(null);
  }

  const clearProgramsFilter = () => {
    setProcessesFiltered([]);
  }

  return (
    <>
    <S.DashboardFilterPanel
      patientSelected={false}
      // panelActive={filterOptionsActive !== null ? true : false}®
      cardSelectedPatientHeight={cardSelectedPatientHeight}
      overflowActive={overflowActive}
    >
      <Modal open={filterOptionsActive === 1}>
        <ModalRangeCalendar
          setSeeDoubleCalendarRangeActive={setFilterOptionsActive}
          initialDateRange={initialDateRange}
          finishDateRange={finishDateRange}
          setInitialDateRange={setInitialDateRange}
          setFinishDateRange={setFinishDateRange}
        />
      </Modal>
      <Modal open={filterOptionsActive === 2}>
        <ModalSelectProgram
          setFilterProgramsActive={setFilterOptionsActive}
          processesFiltered={processesFiltered}
          setProcessesFiltered={setProcessesFiltered}
          deleteProcess={deleteProcess}
        />
      </Modal>
      <div className="panel">
        <div className="panel__container">
          <div className="panel__content">
            {/* <div 
              className={`filter-patient ${activeinput && "filter-patient--focus"}`}
              onClick={() => activeInput()} 
              ref={container}
            >
              <input 
                className={`filter-patient__input ${activeinput && "filter-patient__input--focus"}`}
                ref={textInput} 
                type="text" 
                placeholder={t("filterPanel.filterByPatient")} 
                onChange={(event) => setInputText(event.target.value)}
              />
              <i className={`filter-patient__icon icon zaia-search ${activeinput && "filter-patient__icon--focus"}`}></i>
            </div> */}
            {/* {
              !selectedPatient && 
              <div
                className="filter-patient-selected"
                ref={cardSelectedPatient}
              >
                
              </div>
            } */}
            {/* {
              selectedPatient &&
              <div 
                className="filter-patient-selected" 
                ref={cardSelectedPatient}
              >
                <div className="patient-info">
                  <div className="close-button" onClick={() => deletePatientSelected()}>
                    <i className="close-button__icon icon zaia-icono-cerrar"/>
                  </div>
                  <div className="patient-info__text">
                    <div className="patient-info__label">{t("globally.filterByedLabel")}</div>
                    <div className="patient-info__name">{selectedPatient?.name + ' ' + selectedPatient?.lastname}</div>
                    
                    <div className="patient-info__process-space"></div>
                    <div className="patient-info__process">
                      {
                        selectedPatient?.userToProcesses.length > 1 && 
                        <>
                          {t("globally.variousProcesses")}
                          <br/>
                        </>
                      }
                      {
                        selectedPatient?.userToProcesses.map((userToProcess, index) => {
                          return (
                            <>
                              {P.dataTranslation(userToProcess.process?.processName, language)}
                              <br/>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            } */}
            <div 
              className="all-filters"
            >
              <div className="all-filters__header">
                <div className="title">
                  <i className="title__icon icon zaia-filter"/>
                  <div className="title__text">{t("globally.filterBy")}</div>
                </div>
                <div className="clear-button" 
                  onClick={() => clearAllFilters()}
                >
                  {t("globally.clear")}
                </div>
              </div>
              <div 
                className="filters-container"
                ref={filtersContainer}
            >
                <div 
                  className="filters-content"
                  ref={filtersContent}
                >
                  <div className="filter">
                    {/* <div className="filter__title">{t("globally.filterBy") + " " + t("filterPanel.periodOfTime")}</div> */}
                    <div 
                      id={Actions.ALERTS.FILTERS}
                      className="time-filter__trigger" 
                      // onClick={
                      //   () => {
                      //     recordAction(Actions.ALERTS.FILTERS);
                      //     activePeriodOfTimeFilter();
                      //   }
                      // }
                    >
                      <Trigger
                        isActive={filterOptionsActive === 1}
                        title={t("globally.filterBy") + " " + t("filterPanel.periodOfTime")}
                        dataSelected={
                          initialDateRange && finishDateRange 
                          ? `${P.shortDate(initialDateRange, t, true, language)} - ${P.shortDate(finishDateRange, t, true, language)}`
                          : ""
                        }
                        clearFilter={clearRangeDateFilter}
                        openFilter={
                          () => {
                            recordAction(Actions.ALERTS.FILTERS);
                            activePeriodOfTimeFilter();
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="filter">
                    <div 
                      id={Actions.ALERTS.FILTERS}
                      className={`filter__comments-check icon ${ commentsFilterActive && "filter__comments-check--selected" }`} 
                    >
                      {/* shortcut__button-icon icon zaia-i-message-outline */}
                      <i className="filter__comments-check-icon icon zaia-i-message-outline"/>
                      {/* <i className={`filter__comments-check-icon icon ${ !commentsFilterActive ? "zaia-i-checkbox-off" : "zaia-i-checkbox-on"}`}/> */}
                      <div className="filter__comments-check-text">{t("globally.comments")}</div>
                      <div className="toogle-container">
                        <ZaiaToggleCircular
                          id={'patient-alert-toggle'}
                          color={"#7877BF"}
                          toggle={() => {
                            selectLevelReport(true)
                            recordAction(Actions.ALERTS.FILTERS)
                          }}
                          active={commentsFilterActive}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-separator"/>
                  <div className="filter">
                    <SeverityFilter/>
                  </div>
                  {/* <div className="filter-separator"/> */}
                  <div className="filter">
                    <AlertTypeFilter/>
                  </div>
                  {/* <div className="filter-separator"/> */}
                  <div className="filter">
                    {/* <div className="filter__title">{t("globally.programs")}</div> */}
                    <div 
                      id={Actions.ALERTS.FILTERS}
                      className="filter__trigger" 
                      // onClick={
                      //   () => {
                      //     recordAction(Actions.ALERTS.FILTERS)
                      //     activeProgramsFilter()
                      //   }
                      // }
                    >
                      <Trigger
                        isActive={filterOptionsActive === 2}
                        title={t("globally.filterBy") + " " + t("globally.program")}
                        clearFilter={clearProgramsFilter}
                        dataSelected={
                          processesFiltered.length > 0 
                          ? 
                            
                              processesFiltered.map((process, index) => {
                                return P.dataTranslation(process.processName, language) + (index !== processesFiltered.length - 1 ? " - " : "")
                              })
                            
                          : ""
                        }
                        openFilter={
                          () => {
                            recordAction(Actions.ALERTS.FILTERS)
                            activeProgramsFilter()
                          }
                        }
                      />
                    </div>
                    {/* {
                      processesFiltered.length > 0 &&
                      <div className="filter__tags-list">
                        {
                          processesFiltered.map((process, index) => {
                            return (
                              <div className="tag" key={index}>
                                <div className="tag__name">{P.dataTranslation(process.processName, language)}</div>
                                <div className="tag__close" onClick={() => deleteProcess(process)}>
                                  <i className="tag__close-icon icon zaia-icono-cerrar"/>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    } */}
                  </div>
                </div>

              </div>
            </div>
          </div>
          {/* <div className="panel__right-filter-options">
            {PanelRightOptions()}
          </div>   */}
        </div>
      </div>
    </S.DashboardFilterPanel>
    </>
  );
}

function ProcessFiltered({
  setFilterOptionsActive,
  processesFiltered,
  setProcessesFiltered,
  deleteProcess
}) {
  const { i18n: { language }, t } = useTranslation("global");
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );  

  const addProcess = (process) => {
    if(processesFiltered.indexOf(process) === -1) {
      setProcessesFiltered([...processesFiltered, process]);
    }
  }

  const addAllProcesses = () => {
    setProcessesFiltered(processData?.process);
  }

  const deleteAllProcesses = () => {
    setProcessesFiltered([]);
  }

  return (
    <S.ProcessFiltered>
      <div className="header">
        <div className="header__title">
        {t("filterPanel.programsPanel.title")}

        </div>
        <div className="header__close" onClick={() => setFilterOptionsActive(null)}>
          <i className="header__close-icon icon zaia-icono-cerrar"/>
        </div>
      </div>
      <div className="clear-filters" 
          onClick={() => deleteAllProcesses()}
      >
        <div className="clear-filters__text">{t("globally.clear")}</div>
        <i className="clear-filters__icon icon zaia-i-mop"/>
      </div>
      <div className="programs-list">
        <div className={`program-item ${ processesFiltered.length === processData?.process.length && "program-item--selected"}`}
          onClick={() => addAllProcesses()}
        >
          <i className={`program-item__check-icon icon ${ processesFiltered.length !== processData?.process.length ? "zaia-i-checkbox-off" : "zaia-i-checkbox-on"}`}/>
          <div className="program-item__text">{"All"}</div>
        </div>
        {
          processData?.process.map(
            process => (
              <div className={`program-item ${ processesFiltered.indexOf(process) !== -1 && "program-item--selected"}`} 
                onClick={
                  () => {
                    if(processesFiltered.indexOf(process) === -1) {
                      addProcess(process);
                    } else {
                      deleteProcess(process);
                    }
                  }
                }>
                <i className={`program-item__check-icon icon ${ processesFiltered.indexOf(process) === -1 ? "zaia-i-checkbox-off" : "zaia-i-checkbox-on"}`}/>
                <div className="program-item__text">{P.dataTranslation(process.processName, language)}</div>
              </div>
            )
          )
        }
      </div>
    </S.ProcessFiltered>
  )
}

function Trigger({
  isActive,
  title, 
  dataSelected = "1232/12/12 - 1234/12/12",
  clearFilter,
  openFilter
}) {

  const { t, i18n: { language } } = useTranslation("global");

  return (
    <S.Trigger 
      dataSelected={dataSelected}
    >
      <div className="trigger" onClick={openFilter}>
        <div className="trigger-title">{title}</div>
        <div className="trigger-data-selected">
          {dataSelected}
        </div>
      </div>
      {
        dataSelected &&
        <div 
          className="trigger-clear-button"
          onClick={clearFilter}
        >{t("globally.clear")}</div>
      }
    </S.Trigger>
  )
}

export default DashboardFilterPanel;
