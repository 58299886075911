import React, { useState } from "react";
import * as S from "./ModalUserAiNavigator.style";
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ProcessName from "../../../shared/processName";
import Popup from "reactjs-popup";
import { Actions } from "../../../../../utils/actionsIds";

// Routes
import { Routes } from "../../../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { useEffect } from "react";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";
import Modal from "../../../../../utils/modal";
import ModalAddOutcomesEvent from "../../../shared/modals/modalAddOutcomesEvent";
import ModalAddEventCalendar from "../../../agenda/components/modalAddEventCalendar";
import UserAINavigatorBoxes from "./userAINavigatorView";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient";
import { QUERY_GET_USERS_BY_USER_ID } from "../../../../../graphql/queries/User";
import { useQuery } from "@apollo/client";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalUserAiNavigator({ 
  setPatientActive,
  patient
}) {


  const {t, i18n: { language }} = useTranslation("global");
  const history = useHistory();
  const { AINAVIGATOR, DASHBOARD, AGENDA } = Routes;
  const [, dispatch] = useStateValue();
  const { selectPatient, removePatient } = useAssignSelectPatient();
  const { recordAction } = useRecordNavigation();
  const [addOutcomesEvent, setAddOutcomesEvent] = useState(false);
  const [addCalendarEvent, setAddCalendarEvent] = useState(false);

  const {data: userData, loading: userDataLoading, error: userDataError} = useQuery(QUERY_GET_USERS_BY_USER_ID, {
    variables: {
      patientId: patient.id
    }
  }); 

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27)  {
        closeModal();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    selectPatient(userData?.user[0].id);
  }, [])

  const closeModal = () => {
    removePatient();
    setPatientActive(null);
  }

  const openFlorence = () => {
    closeModal();
    dispatch({
      type: "SET_FLORENCE_VIEW",
      florenceView: 2,
    });
    dispatch({
      type: "SET_FLORENCE_PATIENT",
      florencePatient: userData?.user[0],
    });
    dispatch({
      type: "SET_FLORENCE_ACTIVE",
      florenceActive: userData?.user[0],
    });
  }

  const frequencyHours = (userToProcess) => {
    if(userToProcess.processStage.frequencyType === "days") {
      return (userToProcess.processStage.frequency * 24) 
    } else {
      return userToProcess.processStage.frequency
    }
  } 

  const followUpInfo = userData?.user[0]?.userToProcesses.map((userToProcess) => {
    return {
      id: userToProcess.id,
      processId: userToProcess.process?.id,
      processName: P.dataTranslation(userToProcess.process?.processName, language),
      stageId: userToProcess.stageId,
      lastFollowingDate: userToProcess.userToProcessHistories.length > 0 ? new Date(userToProcess.userToProcessHistories[0].created_at) : null,
      nextFollowingDate: new Date(userToProcess.nextUpdate),
      futureFollowingDate: new Date(new Date(userToProcess.nextUpdate).setHours(frequencyHours(userToProcess),0,0,0))
    }
  })

  if(userDataLoading) 
    return (
      <S.ModalUserAiNavigator>
        <div className="modal-background" onClick={() => closeModal()}>
        </div>
        <div className="modal-container">
          <div className="modal-container__reaction">
            <ZaiaReaction
              widthImg="150px"
              zaiaImg={logoZaiaLoading}
            /> 
          </div>
        </div>
      </S.ModalUserAiNavigator>
    )
    
    if(userDataError) 
      return (
        <S.ModalUserAiNavigator>
          <div className="modal-background" onClick={() => closeModal()}>
          </div>
          <div className="modal-container">
            <div className="modal-container__reaction">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                widthImg="200px"
                text={t("globally.genericError")}
                sizeText="18px"
                widthText="290px"
              />
            </div>
          </div>
        </S.ModalUserAiNavigator>
      )

  return (
    <S.ModalUserAiNavigator>
      <div className="modal-background" onClick={() => closeModal()}>
      </div>
      <div className="modal-container">
        <Modal open={addOutcomesEvent}>
          <ModalAddOutcomesEvent
            setCreateEventCalendarActive={setAddOutcomesEvent}
            patientID={userData?.user[0].id}
          />
        </Modal>
        <Modal open={addCalendarEvent}>
          <ModalAddEventCalendar
            event={null}
            setCardEventSelected={null}
            setCreateEventCalendarActive={setAddCalendarEvent}
            patientID={userData?.user[0].id}
          />
        </Modal>
        <div className="header">
          <div className="close" onClick={() => closeModal()}>
            <i className="close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="content">
          <div className="patient-card">
            <div className="patient-card__info">
              <div className="patient-image">
                <ZaiaImage
                  img={userData?.user[0].picture}
                  rounded={true}
                  size={72}
                />
              </div>
              <div className="patient-info">
                <div className="patient-info__name">{userData?.user[0].name} {userData?.user[0].lastname}</div>
                <div className="patient-info__program">
                  {
                    <Popup
                      trigger={
                        <div className="patient-info__program-detail">
                          {
                            userData?.user[0]?.userToProcesses.length > 1 
                            ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                            <div>{P.dataTranslation(userData?.user[0]?.userToProcesses[0].process?.processName, language)}</div>
                            
                          }
                        </div>
                      }
                      keepTooltipInside
                      nested
                      // position={"right bottom"}
                      on="hover"
                      disabled={userData?.user[0]?.userToProcesses.length > 1 ? false : true}
                    >
                      {
                        <ProcessName userToProcesses={userData?.user[0]?.userToProcesses}/>
                      }
                    </Popup>
                  }
                  
                </div>
                <div className="patient-info__email">{userData?.user[0].email}</div>
                <div className="patient-info__shortcuts">
                  <div className="shortcut"
                    onClick={
                      () => {
                        recordAction(Actions.AINAVIGATOR_MODAL.EVENT_OUTCOMES_MODAL)
                        setAddOutcomesEvent(true)
                      }
                    }
                  >
                    <div className="shortcut__name">{t("aiNavigator.patientModal.addOutcome")}</div>
                    <i className="shortcut__icon icon"></i>
                  </div>
                  <div className="shortcut"
                    onClick={
                      () => {
                        recordAction(Actions.AINAVIGATOR_MODAL.EVENT_AGENDA_MODAL)
                        setAddCalendarEvent(true)
                      }
                    }
                  >
                    <div className="shortcut__name">{t("aiNavigator.patientModal.createAppointment")}</div>
                    <i className="shortcut__icon icon"></i>
                  </div>
                  {/* <div className="shortcut">
                    <div className="shortcut__name">{t("aiNavigator.patientModal.sendMedications")}</div>
                    <i className="shortcut__icon icon"></i>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="patient-card__right">
              <div className="numbers">
                <div className="numbers__title">
                  <div className="numbers__title-text">{t("aiNavigator.patientModal.phoneNumbers")}</div>
                  <i className="numbers__title-icon icon zaia-i-phone"></i>
                </div>
                <div className="number">
                  <div className="number__title">
                    {t("globally.patient")}
                  </div>
                  <div className="number__phone">
                    {
                      userData?.user[0].carerPhoneCountry
                      ? "+" + userData?.user[0].phoneCountry?.phonecode + " "
                      : ""                      
                    }
                    {userData?.user[0].phone}
                  </div>
                </div>
                {
                  userData?.user[0].phoneCarer &&
                  <div className="number">
                    <div className="number__title">
                      {t("globally.caregiver")}
                    </div>
                    <div className="number__phone">
                      {
                        userData?.user[0].carerPhoneCountry
                        ? "+" + userData?.user[0].carerPhoneCountry?.phonecode + " "
                        : ""
                      } {userData?.user[0].phoneCarer}
                    </div>
                  </div>
                }
              </div>
              {/* <button className="button-email">
                <div className="button-email__text">{t("aiNavigator.patientModal.sendEmail")}</div>
                <i className="button-email__icon icon"></i>
              </button> */}
            </div>
          </div>
          <div className="user-ainavigator-boxes">
            <UserAINavigatorBoxes
              patient={patient}
              closeModal={closeModal}
              followUpInfo={followUpInfo}
            />
          </div>
        </div>
      </div>
    </S.ModalUserAiNavigator>
  );
}

export default ModalUserAiNavigator;
