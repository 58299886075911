import React, { useEffect, useMemo } from "react";
import * as S from "./GenericTemplate.style";
import * as P from "../../../../../../../utils/pipes";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

//componentList
import outcomesComponentsList from "../../outcomesComponentsList";

function GenericTemplate({
  title = "title temp",
  dataForm,
  insertfunction,
  customFormChangesList=[],
  typeView,
  isHistorical,
  noDefaultData
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation("global");

  useEffect(() => {}, [dataForm]);


  const userToCustomFormFieldsJoined = useMemo(() => {
    if (isHistorical) {
      const itemsJoined = [];
      dataForm?.customFormFields.forEach((customFormField) => {
        customFormField.userToCustomFormFields.forEach(
          (userToCustomFormFields) => {
            itemsJoined.push(userToCustomFormFields);
          }
        );
      });
      itemsJoined?.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      const itemsReordenated = itemsJoined.map((item) => {
        const customFormFieldTemp = JSON.parse(
          JSON.stringify(item.customFormField)
        );
        customFormFieldTemp.userToCustomFormFields = [item];
        return customFormFieldTemp;
      });
      return itemsReordenated;
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.GeneralTemplate>
      <div className="template-header">
        <div className="template-header-title">{title}</div>
        {
          dataForm &&
          <Popup
            trigger={
              <i className="template-header-item-icon icon zaia-alert"></i>
            }
            on={["hover", "focus"]}
            closeOnDocumentClick
            keepTooltipInside
            position={["bottom right", "bottom center", "bottom left"]}
            nested
          >
            <S.PopUpPayload>
              <p>{P.dataTranslation(dataForm?.descript, language)}</p>
            </S.PopUpPayload>
          </Popup>
        }
      </div>
      <div className="template-content">
        {!isHistorical
          ? dataForm?.customFormFields.map((field, index) => {
              const cmp = outcomesComponentsList.find(
                (cmp) => cmp.key === field.typeFieldValue
              );
              return (
                <div 
                  className="template-content-item" 
                  key={field.id}
                >
                  <cmp.component
                    item={field}
                    insertfunction={insertfunction}
                    customFormChangesList={customFormChangesList}
                    customFormChangeItem={customFormChangesList.find(
                      (item) => item.customFormFieldId === field.id
                    )}
                    customFormFields={dataForm?.customFormFields}
                    typeView={typeView}
                  />
                </div>
              );
            })
          : userToCustomFormFieldsJoined.map((field, index) => {
              const doctorInCharge = field.userToCustomFormFields[0]?.doctor;
              const cmp = outcomesComponentsList.find(
                (cmp) => cmp.key === field.typeFieldValue
              );
              return (
                <div 
                  className="template-content-item" 
                  key={field.id}
                >
                  <cmp.component
                    key={field.id}
                    item={field}
                    insertfunction={insertfunction}
                    customFormChangesList={customFormChangesList}
                    customFormChangeItem={customFormChangesList.find(
                      (item) => item.customFormFieldId === field.id
                    )}
                    customFormFields={dataForm?.customFormFields}
                    typeView={typeView}
                    isHistorical={isHistorical}
                  />
                  <div className="extra-data">
                    {P.shortDate(
                      field.userToCustomFormFields[0].created_at,
                      t,
                      true, 
                      language
                    ) +
                      (doctorInCharge
                        ? " - " +
                          doctorInCharge.name +
                          " " +
                          doctorInCharge.lastname
                        : "")}
                  </div>
                </div>
              );
            })}
      </div>
    </S.GeneralTemplate>
  );
}

export default GenericTemplate;
