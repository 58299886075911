import React from "react";
import * as S from "./AlertsList.style";
import { useTranslation } from "react-i18next";

// Pipes
import * as P from "../../../../../../utils/pipes"

// Hooks
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER } from "../../../../../../graphql/queries/SideEffects";
import { QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER } from "../../../../../../graphql/queries/VitalSigns";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../../graphql/queries/Process";
import { useMemo } from "react";
import ZaiaImage from "../../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import { ProcessName } from "../../dashboardFilterPanel/DashboardFilterPanel.style";
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";
import useAssignSelectPatient from "../../../../../../hooks/useAssignSelectPatient";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function AlertsList({
  sections,
  setSelectedSection
}) {
  
  const {t, i18n: { language }} = useTranslation("global");
  const { selectPatient } = useAssignSelectPatient();


  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES);

  const sideEffects = useMemo(() => {
    let sideEffects1 = [];
    let sideEffects2 = [];
    let sideEffects3 = [];
    processTypes?.process.forEach((process) => {
      process.processToSideEffects.forEach((sideEffect) => {
        if (sideEffect.severity === 1 || sideEffect.severity === 0) {
          sideEffects1.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });

          sideEffects2.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });

          sideEffects3.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });
        }
        if (sideEffect.severity === 2 || sideEffect.severity === 3) {
          sideEffects2.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });

          sideEffects3.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });
        }
        if (sideEffect.severity === 4 || sideEffect.severity === 5) {
          sideEffects3.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });
        }
      });
    }); 

    return [sideEffects1, sideEffects2, sideEffects3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypes]);

  const vitalSigns = useMemo(() => {
    const vitalSignsBySeverity = [[], [], []];
  
    processTypes?.process.forEach((process) => {
      process.processToVitalSigns.forEach((vitalSign) => {
  
        const vitalSignFilter = {
          _and: {
            measure1: {},
            vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
            user: {
              userToProcesses: {
                processId: { _eq: process.id }
              }
            }
          }
        };
  
        if (vitalSign.min) {
          vitalSignFilter._and.measure1._gte = vitalSign.min;
        }
  
        if (vitalSign.max) {
          vitalSignFilter._and.measure1._lte = vitalSign.max;
        }
  
        const severity = vitalSign.severity;
        if (severity >= 1 && severity <= 3) {
          vitalSignsBySeverity[severity - 1].push(vitalSignFilter);
        }
      });
    });
  
    return vitalSignsBySeverity;
  }, [processTypes]);

  const [inputText, setInputText] = useState('');
  const [dateNow] = useState(new Date(Date.now()));
  const [dateAMonthBefore] = useState(new Date(
    new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 30)).setHours(0, 0, 0, 0)
  ))

  const [usersAlertCounter, setUsersAlertCounter] = useState([]);

  const {
    data: sideEffectsData,
    loading: sideEffectsDataLoading,
    error: sideEffectsDataError,
  } = useQuery(
    QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER, {
    variables: {
      sideEffects1: sideEffects[0],
      severities1:  [0, 1],
      sideEffects2: sideEffects[1],
      severities2:  [2, 3],
      sideEffects3: sideEffects[2],
      severities3:  [4, 5],
      initialDate: dateAMonthBefore,
      finalDate: dateNow
    },
  });    

  const { 
    data: vitalSignsData, 
    loading: vitalSignsDataLoading, 
    error: vitalSignsDataError
  } = useQuery(
    QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER, {
    variables: {
      vitalSigns1:  vitalSigns[0],
      vitalSigns2:  vitalSigns[1],
      vitalSigns3:  vitalSigns[2],
      initialDate: dateAMonthBefore,
      finalDate: dateNow
    },
  });

  useEffect(() => {
    const vitalSignsCounts = vitalSignsData?.user.map((user) => {
      return {
        user: user, 
        mild: user.vitalSigns1Count.aggregate.count,
        medium: user.vitalSigns2Count.aggregate.count,
        severe: user.vitalSigns3Count.aggregate.count,
      }
    });

    // const sideEffectsCounts = sideEffectsData?.user.map((user) => {
    //   return {
    //     user: user,
    //     mild: user.sideEffects1Count.aggregate.count,
    //     medium: user.sideEffects2Count.aggregate.count,
    //     severe: user.sideEffects3Count.aggregate.count,
    //   }
    // });
    
    const allCounts = sideEffectsData?.user.map((user) => {
      return {
        user: user,
        mild: user.sideEffects1Count.aggregate.count + vitalSignsCounts?.find((count) => count.user.id === user.id)?.mild,
        medium: user.sideEffects2Count.aggregate.count + vitalSignsCounts?.find((count) => count.user.id === user.id)?.medium,
        severe: user.sideEffects3Count.aggregate.count + vitalSignsCounts?.find((count) => count.user.id === user.id)?.severe,
        color: "#4AA8FF"
      }
    });
    
    const order = allCounts?.sort(
      (a, b) => (b.severe - a.severe) || (b.medium - a.medium) || (b.mild - a.mild) 
    );

    if(order?.length > 0 && order[0]) order[0].color = "#FF4A4A";
    if(order?.length > 1 && order[1]) order[1].color = "#FF7A00";
    if(order?.length > 0 && order[2]) order[2].color = "#FF7A00";

    setUsersAlertCounter(order);

  }, [sideEffectsData, vitalSignsData, dateNow, dateAMonthBefore]);  

  const patientSelection = (patient) => {
    selectPatient(patient.id);
    setSelectedSection(sections[1]);
  }

  const getFilterText = (text) => {
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }

  return (
    <S.AlertsList>
      <div className="searcher">
        <div className="searcher__title">{t("dashboard.suggetions")}</div>
        <div className="searcher__container">
          <input 
            className={"searcher__input"} 
            type="text" 
            placeholder={t("globally.searchPatient")} 
            onChange={(event) => setInputText(event.target.value)}
          />
          <i className={"searcher__input-icon icon zaia-search"}></i>
        </div>
      </div>
      <div className="table-container">
        {
          !sideEffectsDataLoading &&
          !vitalSignsDataLoading &&
          !sideEffectsDataError &&
          !vitalSignsDataError &&
          <table className="suggestion-table">
            <thead>
              <tr>
                <th className="th-blue">
                  <i className="priority-icon icon zaia-i-outline-flag"/>
                  {t("aiNavigator.table.priority")}
                </th>
                <th  className="th-left-align">
                  {t("globally.patientName")}
                </th>
                <th>
                  {t("aiNavigator.table.alertCounter")}
                </th>
                {/* <th className="th-blue th-blue--center">
                  {t("aiNavigator.table.quickActions")}
                </th> */}
              </tr>
            </thead>
              {
                usersAlertCounter && usersAlertCounter?.length > 0 &&
                <tbody>
                  {/* <ZaiaReaction
                    zaiaImg={logoZaiaEmpty}
                    widthImg="100px"
                    text={"There's no patients with alerts recently"}
                    sizeText="15px"
                    widthText="200px"
                  /> */}
                  { usersAlertCounter.filter((patient) => getFilterText(`${patient.user.name} ${patient.user.lastname}`))?.slice(0, 5)?.map((patient, index) => {
                    return (
                      <tr 
                      key={patient.user.id}
                      onClick={
                          () => {
                            patientSelection(patient.user);
                            // selectPatient(patient.user)
                            // recordAction(Actions.AINAVIGATOR.SELECT_SUGGEST_PATIENT, patient.user.id)
                          }
                        }
                      >
                        <td>
                          <div className="priority">
                            <button 
                              className="priority__button"
                              style={{
                                "backgroundColor": patient.color
                              }}
                            >
                              <i className="priority__button__icon icon zaia-i-flag"/>
                            </button>
                            <div className="priority__separator"></div>
                          </div>
                        </td>
                        <td className="td-patient-info">
                          <div 
                            className="patient-content">
                            <ZaiaImage
                              img={patient?.user.picture}
                              alt=""
                              size={37}
                              rounded={true}
                            />
                            <div className="patient-content__info">
                              <div className="patient-content__info-name-psa">
                              {`${patient.user.name} ${patient.user.lastname}`}
                                {/* <strong>PSA 6</strong> */}
                              </div>
                              <div className="patient-content__info-program">
                                <strong>{t("globally.program")}</strong>
                                {
                                  <Popup
                                    trigger={
                                      <div className="patient-option__info-program-detail">
                                        {
                                          patient?.user.userToProcesses.length > 1 
                                          ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                          <div>{
                                            patient?.user.userToProcesses[0].process?.processName
                                            && P.dataTranslation(patient?.user.userToProcesses[0].process?.processName, language)
                                          }</div>
                                        }
                                      </div>
                                    }
                                    keepTooltipInside
                                    nested
                                    // position={"right bottom"}
                                    on="hover"
                                    disabled={patient?.user.userToProcesses.length > 1 ? false : true}
                                  >
                                    {
                                      <ProcessName userToProcesses={patient?.user.userToProcesses}/>
                                    }
                                  </Popup>
                                }
                                
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {/* {
                            patient?.userToSideEffects[0] &&
                            <div className="last-symptom-card">
                              <i className="last-symptom-card__icon icon zaia-i-eye" />
                              <div className="last-symptom-card__info">
                                <div className="last-symptom-card__info-name">{P.dataTranslation(patient?.userToSideEffects[0]?.typeSideEffectVal.comment, language)}</div>
                                <div className="last-symptom-card__info-date">{`${P.longDate(patient?.userToSideEffects[0]?.date, t)}  ${P.hoursIn12format(new Date(patient?.userToSideEffects[0]?.date).getMinutes(), new Date(patient.userToSideEffects[0]?.date).getHours())}`}</div>
                              </div>
                            </div>
                          } */}
                          <div className="alert-counters">
                            <div className="alert-counter alert-counter--first">
                              <div className="alert-counter__color" style={{backgroundColor: "#53C25D"}}></div>
                              <div className="alert-counter__text">{t("globally.mild")}</div>
                              <div className="alert-counter__number">{patient?.mild || 0}</div>
                            </div>
                            <div className="alert-counter">
                              <div className="alert-counter__color" style={{backgroundColor: "#FE6330"}}></div>
                              <div className="alert-counter__text">{t("globally.medium")}</div>
                              <div className="alert-counter__number">{patient?.medium || 0}</div>
                            </div>
                            <div className="alert-counter">
                              <div className="alert-counter__color" style={{backgroundColor: "#C92241"}}></div>
                              <div className="alert-counter__text">{t("globally.severe")}</div>
                              <div className="alert-counter__number">{patient?.severe || 0}</div>
                            </div>
                          </div>
                          
                        </td>
                        {/* <td>
                          <div className="quick-actions-container"> 
                            {
                              <Popup
                                trigger={
                                  <div className="quick-action">
                                    <i className="quick-action__icon icon zaia-i-message-outline"/>
                                  </div>
                                }
                              >
                                <TooltipChats patientID={patient.user.id}/>
                              </Popup>
                            }
                            <div className="quick-action" onClick={() => {setSelectedPatientId(patient.user.id); setCreateEventCalendarActive(true)}}>
                              <i className="quick-action__icon icon zaia-i-calender-check"/>
                            </div>
                            <div className="quick-action">
                              <i className="quick-action__icon icon zaia-i-add-medicine"/>
                            </div>
                          </div>
                        </td> */}
                      </tr>
                      )
                    })
                  } 
                </tbody>
              }
          </table>
        }
        {
          (sideEffectsDataLoading ||
            vitalSignsDataLoading) &&
          <div className="zaia-reaction">
            <ZaiaReaction
              widthImg="150px"
              zaiaImg={logoZaiaLoading}
            />  
          </div>
        }
        {
          (sideEffectsDataError ||
          vitalSignsDataError) &&
          <div className="zaia-reaction">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              widthImg="100px"
              text={"Error loading the patients"}
              sizeText="15px"
              widthText="240px"
            />
          </div>
        }
      </div>
    </S.AlertsList>
  );
}

export default AlertsList;
