import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalSymptoms.style";

// graphQL
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../../graphql/mutations/UserToProcess";

// Hooks
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";
import ZaiaButton from "../../../../../zaiaComponents/zaiaButton";
import ZaiaInput from "../../../../../zaiaComponents/zaiaInput";
import ZaiaModalResponseContent from "../../../../../zaiaComponents/zaiaModalResponseContent";

import SymptomsSearcher from "./components/symptomsSearcher";
import SymptomsDate from "./components/symptomDate";
import SymptomsSelected from "./components/symptomsSelected";
import { INSERT_USER_TO_SIDE_EFFECT } from "../../../../../../graphql/mutations/PatientActions";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalSymptoms({ 
  setModalActive,
  patient,
  // option=1
}) {

  const {
    t
  } = useTranslation("global");

  const [insertSymptom, {loading: symptomAddedLoading}] = useMutation(INSERT_USER_TO_SIDE_EFFECT);
  
  const [formSuccess, setFormSuccess] = useState(null); 
  const [summaryView, setSummaryView] = useState(false);

  const symptomsFormInitial = {
    symptomsList: {
      value: [],
      text: ""
    },
    date: {
      value: null,
      text: ""
    },
    description: {
      value: null,
      text: ""
    },
  }

  const symptomsTab = {
    id: 1,
    tabName: t("healthAddData.symptoms.title"),
    inputs: [
      {
        id: 1,
        placeholder: t("healthAddData.symptoms.symptomReport"),
        icon: "zaia-icono-sintomas",
        form: []
      },
      {
        id: 2,
        placeholder: t("healthAddData.symptoms.symptomAdded"),
        icon: "zaia-icono-sintomas",
        form: ["symptomsList"]
      },
      {
        id: 3,
        placeholder: t("globally.date"),
        icon: "zaia-i-time",
        form: ["date"]
      },
    ]
  }

  const [symptomsForm, setSymptomsForm] = useState(symptomsFormInitial);
  const [tabSelected, setTabSelected] = useState(symptomsTab);
  const [inputSelected, setInputSelected] = useState(symptomsTab.inputs[0])

  const changeFormField = (changesList) => {
    const symptomsFormTemp = JSON.parse(JSON.stringify(symptomsForm));
    for (const change of changesList) {
      if(symptomsFormTemp[change.fieldName]) {
        symptomsFormTemp[change.fieldName].value = change.value;
        symptomsFormTemp[change.fieldName].text = change.text;
      }
    }
    setSymptomsForm(symptomsFormTemp);
  }

  const handleSubmit = async () => {  
    if(summaryView === false) {
      setSummaryView(true);
    } else {
      console.log("123", symptomsForm);
      insertUserToSideEffectFunction()
    }
    // editUserToProcessFunction();
  };

  const insertUserToSideEffectFunction = async () => {
    const form = await symptomsForm;

    const dataSymptomsArray = form.symptomsList.value.map((item) => {
      return ({
        typeSideEffect: item.value,
        severity: item.severity,
        date: form.date.value,
        userId: patient.id
      })
    })

    try {
      await insertSymptom({
        variables: {
          data: dataSymptomsArray
        }
      });
      setFormSuccess(true)
    } catch (err) {
      setFormSuccess(false)
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const inputsVerification = useMemo(() => {

    let verification = true;
  
    tabSelected?.inputs.forEach((input) => {     
      if(input.form.filter((form) => symptomsForm[form].value !== null).length !== input.form.length) {
        verification = false;
      };
    })
    
    const descriptionValue = symptomsForm.description.value;

    if( descriptionValue === "" || descriptionValue === null) {
      verification = false;
    }

    return verification;
    
  }, [tabSelected, symptomsForm])

  const getSeverityText = (severity) => {
    switch (severity) {
      case 1:
        return t("globally.veryMild");
      case 2:
        return t("globally.mild");
      case 3:
        return t("globally.medium");
      case 4:
        return t("globally.severe");
      case 5:
        return t("globally.verySevere");
      default:
        return t("globally.severity");
    }
  } 
  
  const getDinamicInputsViews = () => {

    if(summaryView) {
      return (
        <div className="custom-form-inputs">
          <div className="summary">
            <div className="summary-title">
              {t("globally.summary")}
            </div>
            <div className="summary-items">
              <div className="summary-item">
                <div className="summary-item__title">
                  <i className={'summary-item__icon icon ' + tabSelected.inputs[1].icon} />
                  <div className="summary-item__name input-text__input-name">{tabSelected.inputs[1].placeholder}</div>
                </div>
                <div className="summary-item__info">
                  {symptomsForm.symptomsList?.value.map((item) => {
                    return (
                      <div className="summary-item__info item">
                        {`${item.text} (${getSeverityText(item.severity)})`}
                      </div> 
                    )
                  })}
                </div>
              </div>
              {
                tabSelected.inputs.slice(2, tabSelected.inputs.length).map((input) => {  
                  return (
                    <div className="summary-item" key={input.id}>
                      <div className="summary-item__title">
                        <i className={'summary-item__icon icon ' + input.icon} />
                        <div className="summary-item__name input-text__input-name">{input.placeholder}</div>
                      </div>
                      <div className="summary-item__info">
                        {input.form.map((form) => {return symptomsForm[form]?.text})}
                      </div>
                    </div>
                  )
                })
              }
              <div className="summary-item">
                <div className="summary-item__title">
                  <i className={'summary-item__icon icon zaia-i-message-outline'} />
                  <div className="summary-item__name input-text__input-name">{t("globally.description")}</div>
                </div>
                <div className="summary-item__info">
                  {symptomsForm.description.text}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
      
    return(
      <div className="custom-form-inputs">
        {
          <div className={`symptoms-inputs ${inputSelected.id === 1 && 'symptoms-inputs--selected'}`}>
            <SymptomsSearcher
              selectedSymptomsList={symptomsForm.symptomsList}
              changeFormField={changeFormField}
            />
          </div>
        }
        {
          <div className={`symptoms-inputs ${inputSelected.id === 2 && 'symptoms-inputs--selected'}`}>
            <SymptomsSelected
              selectedSymptomsList={symptomsForm.symptomsList}
              changeFormField={changeFormField}
            />
          </div>
        }
        {
          <div className={`symptoms-inputs ${inputSelected.id === 3 && 'symptoms-inputs--selected'}`}>
            <SymptomsDate
              selectedDate={symptomsForm.date}
              changeFormField={changeFormField}
            />
          </div>
        }
      </div>
    )    
  }

  if(
    symptomAddedLoading
  ) 
    return(
      <S.ModalSymptoms>
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <ZaiaReaction
            widthImg={"100px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalSymptoms>
    )

  if(formSuccess)
    return(
      <S.ModalSymptoms>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            title={t("healthAddData.symptoms.successfulAlertCreation")}
          />
        </div>
      </S.ModalSymptoms>
    )

  if(formSuccess === false)
    return(
      <S.ModalSymptoms>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={formSuccess}
            title={t("globally.errorForm")}
            button1Text={t("globally.accept")}               
            subtitle={t("healthAddData.symptoms.failedAlertCreation")}
          />
        </div>
      </S.ModalSymptoms>
    )

  return (
    <S.ModalSymptoms>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("healthAddData.symptoms.title")}</div>
            <div className="header__subtitle">{t("healthAddData.symptoms.subtitle")}</div>
          </div>
          <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="inputs">
          <div className="rows">
            <div className="principal-input">
              <SymptomsInputs
                inputsList={tabSelected.inputs}
                patientSelected={patient}
                inputSelected={inputSelected}
                setInputSelected={setInputSelected}
                symptomsForm={symptomsForm}
                changeFormField={changeFormField}
                setSummaryView={setSummaryView}
              />
            </div>
            {getDinamicInputsViews()}
          </div>  
        </div>
        <div className="button-container">
          <div onClick={() => handleSubmit()}>
            <ZaiaButton
              type="button"
              option="primary"
              marginTop="5"
              backgroundColor={ inputsVerification ? "#17AF54" : "#E0E6ED"}
              title={summaryView ? t("globally.createAlert") : t("globally.done")}
              // title={t("outcomes.tableOutcomes.modalNewEvent.add")}
              borderRadius="5"
              fontSize="32.25"
              height="64"
              fontFamily={"Nunito Sans"}
              shadow={false}
              border={false}
              color={inputsVerification ? "#FFFFFF" : "#8A939D"}
              disabled={!inputsVerification}
            />
          </div>
        </div>
      </div>
    </S.ModalSymptoms>
  );
}

const SymptomsInputs = ({
  patientSelected,
  inputsList, 
  inputSelected,
  setInputSelected,
  symptomsForm,
  changeFormField,
  setSummaryView
}) => {

  const {
    t
  } = useTranslation("global");

  return (
    <div className="principal-input__container">      
      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-person"/>
            {t("globally.patient")}
        </div>
        <div className="input-value">
          {`${patientSelected?.name} ${patientSelected?.lastname}`}
        </div>
      </div>
      {
        inputsList.map((input) => {
          const filledVerification = input.form.filter((form) => symptomsForm[form].value !== null && symptomsForm[form].value.length !== 0 ).length === input.form.length && input.form.length > 0;

          return (
            <div className="input-box__input" key={input.id} onClick={() => {
              setInputSelected(input)
              setSummaryView(false);
            }}>
              <S.InputOption
                selected={inputSelected?.id === input.id}
                filled={filledVerification}
              >
                <i className={'input-icon icon ' + input.icon} />
                <div className="input-text">
                  {input.placeholder}
                  <b>{input.form.map((form) => {return symptomsForm[form].text})}</b>
                </div>
                {
                  !filledVerification 
                  ? <i className={'input-icon-arrow icon zaia-icono-dropdown-light'} />
                  : <i className={'input-icon-check icon zaia-i-check-circle'} />
                }
                { 
                  inputSelected?.id === input.id &&
                  <div className="shape-container">
                    <div className="shape">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 25 32" fill="none">
                        <path d="M0 31.5C-2.16635e-07 26.544 4.32006 22.5263 9.64912 22.5263L15.3509 22.5263C20.6799 22.5263 25 26.544 25 31.5L25 0.499999C25 5.45603 20.6799 9.47368 15.3509 9.47368L9.64912 9.47368C4.32006 9.47368 -1.13842e-06 5.45603 -1.35505e-06 0.5L0 31.5Z" fill="#6C6BCC"/>
                      </svg>
                    </div>
                  </div>
                }
              </S.InputOption>
            </div>
          )
        })
      }

      <div className="input-box input-box--last">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-message-outline"/>
          {t("healthAddData.addNotes")}
        </div>
        <ZaiaInput
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          fontSize={"15px"}
          placeholder={t("globally.eventDescription")}
          type="text"
          name="description"
          padding={"20px"}
          borderRadius={"14px"}
          textarea={true}
          errorOption={false}
          height={"100%"}
          getText={(e) => 
            changeFormField(
              [
                {
                  value: e, 
                  text: e, 
                  fieldName: "description"
                }
              ]
            )
          }
          value={symptomsForm.description.text}
        />
      </div>
    </div>
  )
}


export default ModalSymptoms;
