import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalOutcomesEventHistory.style"
import { v4 as uuidv4 } from 'uuid';
import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../utils/pipes";
import { useQuery } from "@apollo/client";
import { QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID } from "../../../../../graphql/queries/CustomForm";
import { useMemo } from "react";

function ModalOutcomesEventHistory({ 
  setSeeEventHistoryActive,
  patientID,
  processID,
  cmpID,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  useEffect(() => {
    function handleKeyDown(e) {
      // console.log(e.keyCode);
      // console.log(e);
      if (e.keyCode === 27) {
        setSeeEventHistoryActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const { data: customFormHistory, loading: customFormHistoryLoading, error: customFormHistoryError } = useQuery(
    QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID, {
      variables: {
        customFormId: cmpID,
        userId: patientID
      }
    }
  );

  const customFormHistoryDates = useMemo(() => {
    const customFormHistoryDates = customFormHistory?.userToCustomFormField.map((item) => {
      return {
        date: P.formatNumberDate(item.created_at, true, language),
        value: item.created_at
      };
    })

    return [
      ...new
      Map(customFormHistoryDates?.map(item => [item["date"], item])).values()
    ]
  }, [customFormHistory]);

  console.log("DATESSSSS", customFormHistoryDates);

  function getFormattedValue(item) {
    const userField = item;
    let formattedValue = "–";
    if (userField) {
      formattedValue =
        item?.customFormField.typeFieldValue === "dateInput"
          ? P.formatNumberDate(userField.value, true, language)
          : userField.value;
    }
    return formattedValue;
  }

  const renderCustomFormGroupsindex1 = (item, language) => {
    console.log("UN ITEMMMM", item);
    return (
      <td key={uuidv4()}>
        {
        // formGroup.length > 0 && 
          // formGroup.map((item, index) => {
            // return (
              <div key={item.id}>
                {/* {
                  index !== 0 && 
                  <br/>
                } */}

                <div className="detail">
                  <div className="detail-item">
                    {P.dataTranslation(item.customFormField.fieldName, language)}
                    {item.customFormField.descript && (
                      <Popup
                        trigger={
                          <i className="template-header-item-icon icon zaia-alert"></i>
                        }
                        on={["hover", "focus"]}
                        closeOnDocumentClick
                        keepTooltipInside
                        position={["right center"]}
                        nested
                      >
                        <S.PopUpPayload>
                          <p>
                            {P.dataTranslation(item.descript, language)}
                          </p>
                        </S.PopUpPayload>
                      </Popup>
                    )}
                  </div>
                  {getFormattedValue(item)}
                  {item?.payload && (
                    <Popup
                      trigger={
                        <i className="template-header-item-icon icon zaia-alert"></i>
                      }
                      on={["hover", "focus"]}
                      closeOnDocumentClick
                      keepTooltipInside
                      position={["right"]}
                      nested
                    >
                      <S.PopUpPayload>
                        <p>{item.payload}</p>
                      </S.PopUpPayload>
                    </Popup>
                  )}
                </div>
              </div>
            // );
          // })
        }
      </td>
    );
  }

  const renderCustomFormGroupsIndex0 = (formGroup, language) => { 
    return (
      <td
        key={uuidv4()}
        style={{
          color: "",
          fontFamily: "Nunito ExtraBold",
          verticalAlign: "middle",
        }}
        className="td-index0"
      >
        <div className="event-type">
          <div className="event-type__name">
            {P.dataTranslation(formGroup.customFormName, language)}
            {formGroup.descript && (
              <Popup
                trigger={
                  <i className="template-header-item-icon icon zaia-alert"></i>
                }
                on={["hover", "focus"]}
                closeOnDocumentClick
                keepTooltipInside
                position={["right"]}
                nested
              >
                <S.PopUpPayload>
                  <p>
                    {P.dataTranslation(
                      formGroup.descript,
                      language
                    )}
                  </p>
                </S.PopUpPayload>
              </Popup>
            )}
          </div>
        </div>
      </td>
    );
  }

  const getOptionsWithPayload = (formFields) => {
    return formFields.map((option) => {
      const { customFormTypeField, userToCustomFormFields } = option;
  
      if (
        !customFormTypeField?.fieldOptions?.length ||
        !userToCustomFormFields?.length
      ) {
        return { ...option, payload: null };
      }
  
      const fieldOption = customFormTypeField.fieldOptions.find(
        (field) => field.key === userToCustomFormFields[0].key
      );
  
      if (!fieldOption?.payload) {
        return { ...option, payload: null };
      }
  
      let payloadFormated;
  
      try {
        payloadFormated = JSON.parse(fieldOption.payload);
        option.payload =
          "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        option.payload = null;
      }
  
      return option;
    });
  }

  const customFormHistoryOrder = useMemo(() => {
    return customFormHistory?.userToCustomFormField.map((item) => 
      [    
        // item.customFormField.customForm,    
        item,    
        (
          item.doctor 
          ? `${item.doctor.name} ${item.doctor.lastname}` 
          : "–"
        ),
        (
          item.created_at 
          ? P.formatNumberDate(item.created_at, true, language)
          : "–"
        ),
        // (
        //   item.descript 
        //   ? item.descript.split("-")[0]
        //   : "–"
        // ),
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormHistory]);

  return (
    <S.ModalOutcomesEventHistory>
      <div className="modal-background" onClick={() => setSeeEventHistoryActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__note">{t("outcomes.modalOutcomesHistory.note")}</div>
            <div className="header__title">{t("outcomes.modalOutcomesHistory.title")}</div>
          </div>
          <div className="header__close" onClick={() => setSeeEventHistoryActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="history-table">
        <table className="illness-control-table">
          <thead>
            <tr>
              {/* <th className="th-event-type">{t("globally.eventType")}</th> */}
              <th className="th-event-type">{t("outcomes.tableOutcomes.details")}</th>
              <th>{t("outcomes.tableOutcomes.doctorInCharge")}</th>
              {/* <th></th> */}
              <th>{t("globally.date")}</th>
              {/* <th>{t("globally.comment")}</th> */}
            </tr>
          </thead>
          {
            customFormHistoryDates.reverse()?.map((dateItem, index) => {
              return (
                  <tbody>
                    <td className="table-date-label" colspan="5">
                      {P.longDate(dateItem.value, t, language)}
                    </td>
                    {
                      customFormHistoryOrder?.filter((item) => item[2] === dateItem.date).map((item) => {
                        return (
                          <tr key={uuidv4()}>
                            {item.map((row, index) => {
                              if (index === 0) {
                                return(renderCustomFormGroupsindex1(row, language))
                              }
                              // if (index === 0) {
                              //   return(renderCustomFormGroupsIndex0(row, language))
                              // }
                              return (<td 
                                key={uuidv4()}
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: row === "–" ? "center" : "left",
                                }}                   
                              >{index === 2 ? row : row.toString()}</td>);
                            })}
                          </tr>
                        )
                      })
                    }
                  {index+1 !== customFormHistoryDates.length && <td className="tbody-content" colspan="5">
                  </td>}
                  </tbody>
              )
            })
          }
        </table>
        </div>
      </div>
    </S.ModalOutcomesEventHistory>
  );
}

export default ModalOutcomesEventHistory;
