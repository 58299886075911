import React from "react";
import * as S from "./CoverPage.style";
import Cookies from "js-cookie";

const zaiaImage = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/florence-health-logo-white.svg`;

function CoverPage() {
  const webView = Cookies.get('webView');

  return (
    <S.CoverPage webView={webView}>
      <img className="zaia-image" src={zaiaImage} alt="FLORENZ HEALTH" />
    </S.CoverPage>
  );
}

export default CoverPage;
