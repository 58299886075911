import styled from "styled-components";

export const ZaiaModalResponseContent = styled.div`
  .alert {
    align-items: center;
    display: flex;
    flex-direction: column;
    &__image {
      width: ${({formSuccess}) => formSuccess ? "300px" : "202px"}; 
      margin-right: ${({formSuccess}) => formSuccess && "10px"};
      margin-bottom: 20px;
    }
    &__title {
      color: ${({formSuccess}) => formSuccess ? "#36369B" : "#878787"} ;
      text-align: center;
      font-family: "Nunito Sans bold";
      font-size: 30px;
    }
    &__subtitle {
      color: #878787;
      text-align: center;
      font-family: "Nunito Sans";
      font-size: 20px;
    }
    &__button {
      width: 319px;
      padding: 0 10px;
    }
    &__buttons {
      margin-top: 40px;
      display: flex;
      width: 100%;
      justify-content: center;
    }
    &__second-button {
      width: 319px;
      /* width: auto; */
      padding: 0 10px;
    }
  }
`

