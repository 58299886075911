import React, { useState } from "react";
import * as S from "./SurveyView.style.js";
import { useStateValue } from "../../../../../contextAPI/StateProvider.js";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation.js";

// utils
import { Actions } from "../../../../../utils/actionsIds.js";
import * as P from "../../../../../utils/pipes.js";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput/ZaiaInput.jsx";
import SurveyBox from "../surveyBox/SurveyBox.jsx";
import { useQuery } from "@apollo/client";
import { QUERY_GET_SURVEY_WITH_FREQUENCY } from "../../../../../graphql/queries/Surveys";
import PeriodOfTimeSelect from "../periodOfTimeSelect";
import Modal from "../../../../../utils/modal.js";
import ModalSendSurvey from "../modalSendSurvey";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction/ZaiaReaction.jsx";
import { useMemo } from "react";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const zaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;

function SurveyView({
  surveySelected,
  setSurveySelected
}) {

  const { recordAction } = useRecordNavigation();
  const [{ selectedPatient }, dispatch] = useStateValue();
  const [processSelected, setProcessSelected] = useState(selectedPatient?.userToProcesses[0].process?.id);
  const { i18n: { language }, t } = useTranslation("global");
  const [filterText, setFilterText] = useState("");
  const [initialDateRange, setInitialDateRange] = useState();
  const [finishDateRange, setFinishDateRange] = useState();
  const [surveyPreview, setSurveyPreview] = useState()

  const getText = (e) => {
    console.log(e);
    setFilterText(e);
  }

  const { data: surveyData, loading: surveyDataLoading, error: surveyDataError } = useQuery(QUERY_GET_SURVEY_WITH_FREQUENCY, {
    variables: {
      customFormId: surveySelected,
      initialDate: initialDateRange,
      finalDate: finishDateRange
    }
  })

  const getSurveysInfoByFrequencies = () => {
    return surveyData?.customForm[0].customFormFrequency.map((frequencies) => {
      return {
        customFormId: frequencies?.customFormId,
        userId: frequencies?.user.id,
        user: frequencies?.user,
        date: frequencies?.created_at,
        frequency: frequencies?.frequency,
        frenquencyType: frequencies?.frequencyType,
        answersQuantity: frequencies?.user.customFormHistories.length
      }
    })
  }

  const getSurveysInfoByDoctorUpload = () => {

    const surveysHistoryList = []

    surveyData?.customForm[0].userCustomFormHistories.filter((history) => history.savedByDoctor).forEach((history) => {
      if(!surveysHistoryList.find(survey => survey.userId === history?.user.id)) {
        surveysHistoryList.push({
          customFormId: history?.customFormId,
          userId: history?.user.id,
          user: history?.user,
          date: history?.created_at,
          frequency: null,
          frenquencyType: null,
          answersQuantity: surveyData?.customForm[0].userCustomFormHistories.filter((item) => item?.user.id === history?.user.id).length
        })
      }
    })

    return surveysHistoryList;
  }

  const surveyListItems = useMemo(() => {

    let listItems = [];

    if(surveyData?.customForm[0].customFormFrequency.length > 0) {
      console.log("aslirio", getSurveysInfoByFrequencies());
      listItems = listItems.concat(getSurveysInfoByFrequencies());
    } 

    if(surveyData?.customForm[0].userCustomFormHistories.filter((history) => history.savedByDoctor).length > 0) {

      getSurveysInfoByDoctorUpload().forEach((surveyData) => {

        const existUserInList = listItems.find(survey => survey.userId === surveyData?.userId);
        if( existUserInList && new Date(surveyData.date) > new Date(existUserInList.date) ) {
          listItems = [...listItems.filter(survey => survey.userId !== surveyData?.userId), surveyData];
        }

        if(!existUserInList) {
          listItems.push(surveyData);
        }
      })
    }

    return listItems;

  }, [surveyData])
  
   return <S.SurveyView>
    <Modal open={surveyPreview}>
      <ModalSendSurvey
        setModalActive={setSurveyPreview}
        surveyForPreview={surveyPreview}
      />
    </Modal>
    <div className="survey-header">
      <div className="survey-header__left">
        <button className="back-button"
          onClick={() => setSurveySelected(false)}
        >
          <i className="back-button__icon icon zaia-icono-atras"></i>
        </button>
        <div className="survey-info">
          <div className="survey-info__title">{P.dataTranslation(surveyData?.customForm[0].customFormName, language)}</div>
          <div className="survey-info__process">{P.dataTranslation(surveyData?.customForm[0].processToCustomForms.find(process => process.process !== null).process.processName, language)}</div>
          <div className="survey-info__actions">
            {/* <div className="survey-action">Sent again</div> */}
            <div className="survey-action"
              onClick={
                () => {
                  setSurveyPreview(surveyData?.customForm[0].id)
                }
              }
            >{t("surveys.preview")}</div>
          </div>
        </div>
      </div>
      <div className="survey-header__right">
        {/* <div className="survey-date">
          Send on {P.longDate()}
        </div>
        <div className="survey-delicate">
          <div className="survey-delicate__icon-container">
            <i className="survey-delicate__icon icon zaia-alert"></i>
          </div>
          <div className="survey-delicate__text">
            Delicate Results <b>4</b>
          </div>
        </div> */}
      </div>
    </div>
    <div className="filters-section">
      <div className="filters-section__left">
        <PeriodOfTimeSelect
          initialDateRange={initialDateRange}
          finishDateRange={finishDateRange}
          setInitialDateRange={setInitialDateRange}
          setFinishDateRange={setFinishDateRange}
        />
        {/* <div className="filter-text">
          <ZaiaInput
            placeholder={"Search by survey name or patient name"}
            type={"text"}
            icon={"i-search"}
            errorOption={false}
            getText={getText}
            value={filterText}
            padding={"12.5px 10px"}
            borderRadius={"47px"}
            textColor={"#6C6BCC"}
            borderColor={"transparent"}
            backgroundColor={"#F3F3FC"}
            placeholderTextColor={"#878792"}
            fontSize="12px"
          />
        </div> */}
        {/* <button className="filter-favourite">
          <i className="filter-favourite__icon icon zaia-icon-star"></i>
          <div className="filter-favourite__text">
            Filtered by favorites
          </div>
        </button> */}
      </div>
      <div className="filters-section__right">
        {/* <button className="survey-option survey-option--selected">
          <div className="survey-option__text">
            On This Survey
          </div>
        </button>
        <button className="survey-option">
          <div className="survey-option__text">
            All Surveys
          </div>
          <i className="survey-option__icon icon zaia-icono-ayuda"></i>
        </button> */}
      </div>
    </div>
    <div className="surveys-section">
      <SurveysListContainer
        surveyListItems={surveyListItems}
        surveyDataError={surveyDataError}
        surveyDataLoading={surveyDataLoading}
      />
    </div>
  </S.SurveyView>
}

const SurveysListContainer = ({ 
  surveyListItems,
  surveyDataError,
  surveyDataLoading
 }) => {
  
  const { i18n: { language }, t } = useTranslation("global");

  if(surveyDataLoading) 
    return (
      <div className="survey-list survey-list--reaction">
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        /> 
      </div>
    )

  if(surveyDataError) 
    return (
      <div className="survey-list survey-list--reaction">
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={"Error loading the patients"}
          sizeText="15px"
          widthText="240px"
        />
      </div>
    )

  if(surveyListItems.length === 0) 
    return (
      <div className="survey-list survey-list--reaction">
        <ZaiaReaction
          zaiaImg={zaiaEmpty}
          text={t("surveys.noSurveys")}
          // subText={}
          sizeText="33.25px"
          sizeSubText="15px"
          textColor="var(--blue-color)"
          widthImg="210px"
        />
      </div>
    )
  

  return (
    <div className="survey-list">
      <table>
        <thead>
          <tr>
            <th>{t("globally.patientName")}</th>
            {/* <th>Status</th> */}
            <th>{t("surveys.lastSentDate")}</th>
            <th>{t("surveys.answeredTimes")}</th>
            <th>{t("globally.frequency")}</th>
            {/* <th>Delicate results</th> */}
          </tr>
        </thead>
        <tbody>
          {
            surveyListItems.map(
              (surveyinfo) => {
                return <SurveyBox 
                  key={surveyinfo.userId}
                  data={surveyinfo}
                />
              }
            )

          }
        </tbody>
      </table>
    </div>
  );
};

export default SurveyView;