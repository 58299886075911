import styled from "styled-components";

export const SurveyRange = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  .title {
    color: #3E3DA3;
    font-family: Nunito Sans;
    font-size: 18px;
    margin-top: 10px;
  }
  .calendar-inputs {
    margin: 20px 14px 28px;
    display: flex;
    justify-content: space-between;
    .input-container {
      display: flex;
      align-items: center;
      &__box {
        width: 140px;
      }
      .input-label {
        margin-right: 10px;
        color: #3E3E5B;
        font-family: "Nunito Sans";
        font-size: 16px;
      }
      &--unfocus {
        opacity: 0.3  ;
      }
    }
  }
  .calendar-range-container {
    display: flex;
    .calendar-range {
      margin: auto;
      width: 300px;
    }
  }
  .toogle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: "Nunito Sans Light";
      font-size: 15px;
      color: #526479;
      margin-right: 10px;
    }
  }
`