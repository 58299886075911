import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as S from "./ModalAddSurveyData.style";
import * as P from "../../../../../utils/pipes";
import { ThemeContext } from "styled-components";
import * as Yup from "yup";

// Components
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";
import { MUTATION_UPDATE_CUSTOM_FORMS, MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../graphql/queries/CustomForm";

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";
import usePatientList from "../../../../../hooks/usePatientList";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import GenericTemplate from "../../../outcomes/components/outcomesComponents/shared/genericTemplate";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { QUERY_GET_PROCESS_BY_ID, QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput";
import { MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD, MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD } from "../../../../../graphql/mutations/DoctorToUserProcessBoard";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import { QUERY_GET_SURVEYS_BY_IDS, QUERY_GET_SURVEYS_BY_ID_PROCESS } from "../../../../../graphql/queries/Surveys";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const defaultUserImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`;

function ModalAddSurveyData({ 
  patientID,
  processID,
  surveyId,
  setFormSuccess,
  setLoadingData
}) {

  const {patientList} = usePatientList()

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const [editUserToProcess, {loading: editUserToProcessLoading, error: editUserToProcessError}] = useMutation(
    MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS
  );

  const [addSurveysData, {loading: addSurveysDataLoading}] = useMutation(
    MUTATION_UPDATE_CUSTOM_FORMS
  );
  

  const [surveySelected, setSurveySelected] = useState(surveyId);
  const [processSelected, setProcessSelected] = useState(processID);
  const [patientSelected, setPatientSelected] = useState(patientID);
  const [descriptionSelected, setDescriptionSelected] = useState("");
  const [dateSelected, setDateSelected] = useState(P.formatNumberDateInput(new Date(Date.now())));
  const [timeSelected, setTimeSelected] = useState(P.formatNumberTimeInput(new Date(Date.now())));

  const {data: process} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processSelected,
      },
    }
  );

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected },
  });


  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError,
  } = useQuery(QUERY_GET_SURVEYS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: surveySelected,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });


  useEffect(() => {
    setSurveySelected(surveyId)
  }, [surveyId]);

  const [customFormChangesList, setCustomFormChangesList] = useState([]);

  const insertFormField = async (e, cmpId) => {
    let tempList = customFormChangesList.filter(
      (item) =>
        item.customFormFieldId !== e.customFormFieldId &&
        (item.customFormFieldParentId === e.customFormFieldId
          ? item.parentKey === e.key
          : true)
    );
    setCustomFormChangesList([...tempList, e]);
  };

  // useEffect(() => {

  //   const customFormFieldsInitTemp = customForm?.customForm_by_pk.formGroups?.find(
  //     (cmp) => cmp.id === cmpSelected?.id
  //   )?.customFormFields.map(
  //     (item) => {
  //       const parentKey = item?.customFormTypeField?.fieldOptions.find(
  //         (field) => field.key === item?.userToCustomFormFields[0]?.key
  //       )?.parentKey;
  //       let value;
  //       let key;
  //       if(item?.customFormTypeField?.value === "dateInput") {
  //         value = item?.userToCustomFormFields[0]?.value ? new Date(item?.userToCustomFormFields[0]?.value) : new Date(Date.now());
  //         key = item?.userToCustomFormFields[0]?.key ? new Date(item?.userToCustomFormFields[0]?.key) : new Date(Date.now());
  //       } else {
  //         value = item?.userToCustomFormFields[0]?.value;
  //         key = item?.userToCustomFormFields[0]?.key;
  //       }
  //       return {
  //         customFormFieldId: item.id,
  //         parentKey: parentKey ? parentKey : null,
  //         customFormFieldParentId: item?.customFormFieldParentId,
  //         value: value,
  //         key: key,
  //       };
  //     }
  //   );

  //   setCustomFormChangesList(customFormFieldsInitTemp);
  // }, [cmpSelected]);

  const handleSubmit = async () => {

    if(patientSelected && processSelected && surveySelected ) {
      const objectCustomFormChanges = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
        return {
          customFormFieldId: item.customFormFieldId,
          value: item.value,
          key: item.key,
          doctorId: doctorData.id,
          userId: patientSelected,
        };
      });
  
      const changesString = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
        if (item.value instanceof Date)
          return P.shortDate(item.value, t, true, language);
        else return item.value;
      });
  
      editUserToProcessFunction(
        changesString,
        objectCustomFormChanges
      );
    }
  };

  const editUserToProcessFunction = async (
    changesString,
    objectCustomFormChanges
  ) => {

    try {
      await addSurveysData({
        variables: {
          doctorId: doctorData.id,
          userId: patientSelected, 
          customFormId: surveySelected,
          objectsCustomForms: objectCustomFormChanges,
        },
      });
      setSurveySelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(true);
    } catch (err) {
      setSurveySelected(null);
      setDescriptionSelected("");
      setDateSelected(P.formatNumberDateInput(new Date(Date.now())));
      setTimeSelected(P.formatNumberTimeInput(new Date(Date.now())));
      if(!patientID) {
        setPatientSelected(null);
      }
      if(!processID) {
        setProcessSelected(null);
      }
      setFormSuccess(false);
    }
  }

  useEffect(() => {}, [surveySelected, descriptionSelected]);

  const customFormFieldLength = () => {
    let counter = 0;
    customForm?.customForm_by_pk.formGroups.forEach(element => {
      counter += element.customFormFields.length;
    });
    return counter;
  }

  useEffect(() => {
    setLoadingData(userToProcessDataLoading || customFormLoading || editUserToProcessLoading);
  }, [
    userToProcessDataLoading,
    customFormLoading,
    editUserToProcessLoading
  ])

  return (
    <S.ModalAddSurveyData>
      <div className="message">{t("surveys.fillOutSurveyModal.note")}</div>
      <div className="rows-container">
        <div className="rows">
          <div className="principal-input">
            {/* <Formik
              initialValues={{
                cmpId: "",
                description: descriptionSelected,
                name: null,
                patient: patientSelected,
                userToProcessId: null,
              }}
              validationSchema={
                Yup.object().shape(
                  {
                    cmpId: Yup.string()
                      .required(),
                    description: Yup.string()
                      .required(),
                    patient: Yup.string()
                      .required(),
                    userToProcessId: Yup.string()
                      .required(),
                  },
                )
              }
              enableReinitialize
              onSubmit={handleSubmit}
            >
              {(props) => (
                <>
                  <form 
                    className="principal-input__form"
                    onSubmit={props.handleSubmit}> */}
                    <EventOutcomesInputs
                      patientID={patientID}
                      processID={processID}
                      surveyId={surveyId}
                      setSurveySelected={setSurveySelected}
                      surveySelected={surveySelected}

                      patientSelected={patientSelected}
                      setPatientSelected={setPatientSelected}
                      processSelected={processSelected}
                      setProcessSelected={setProcessSelected}
                      setDescriptionSelected={setDescriptionSelected}
                      descriptionSelected={descriptionSelected}
                      dateSelected={dateSelected}
                      setDateSelected={setDateSelected}
                      timeSelected={timeSelected}
                      setTimeSelected={setTimeSelected}
                    />
                  {/* </form>
                </> */}
              {/* )}
            </Formik> */}
          </div>
            {
              // cmpSelected 
              // ? 
              (
                <div className="custom-form-inputs">
                  {
                    customForm?.customForm_by_pk.formGroups?.map(
                      (cmp) => {
                        return (
                          <GenericTemplate
                            title={P.dataTranslation(
                              cmp?.customFormName,
                              language
                            )}
                            dataForm={cmp}
                            noDefaultData={true}
                            insertfunction={(e) => insertFormField(e, cmp.id)}
                            customFormChangesList={customFormChangesList}
                          />
                        )
                      }
                    )
                  }
                </div>  
              )
              // : null
            }
        </div>
      </div>
      <div className="footer">
        <div className="footer__text">
          {/* {footerMsg()} */}
        </div>
        <div className="button-container">
          <div onClick={() => customFormFieldLength() === customFormChangesList.length ? handleSubmit() : null}>
            <ZaiaButton
              title={t("surveys.fillOutSurveyModal.sendSurveyData")}
              backgroundColor={customFormFieldLength() === customFormChangesList.length ? "#17AF54" : "#EEEDF4"}
              type="button"
              option="primary"
              marginTop="5"
              iconSize={"20"}
              iconMargin={"5"}
              borderRadius="75"
              fontSize="14"
              height="36"
              fontFamily={"Nunito Sans"}
              shadow={false}
              border={false}
              color={customFormFieldLength() === customFormChangesList.length ? "#FFFFFF" : "#3E3DA3"}
              icon={customFormFieldLength() === customFormChangesList.length ? "" : "zaia-i-lock-chat"}
              disabled={
                !patientSelected || !processSelected || !surveySelected ? true : false
              }
            />
          </div>
        </div>
      </div>
      {/* <div className="button-container">
        <div onClick={() =>  handleSubmit()}>
          <ZaiaButton
            type="button"
            option="primary"
            marginTop="5"
            backgroundColor={"#17AF54"}
            title={t("surveys.fillOutSurveyModal.sendSurveyData")}
            // title={t("outcomes.tableOutcomes.modalNewEvent.add")}
            borderRadius="5"
            fontSize="32.25"
            height="64"
            fontFamily={"Nunito Sans"}
            shadow={false}
            disabled={
              !patientSelected || !processSelected || !surveySelected ? true : false
            }
          />
        </div>
      </div> */}
    </S.ModalAddSurveyData>
  );
}

const EventOutcomesInputs = ({
  patientID,
  processID,
  setSurveySelected,
  surveySelected,
  surveyId,
  patientSelected,
  setPatientSelected,
  processSelected,
  setProcessSelected,
  setDescriptionSelected,
  descriptionSelected,
  dateSelected,
  setDateSelected,
  timeSelected,
  setTimeSelected,
  // customFormsList
}) => {
  const { data: userData, error } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientSelected },
  });
  const { doctorData } = useDoctorData();
  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const [customFormsList, setCustomFormsList] = useState();
  const { t, i18n: { language } } = useTranslation("global");

  const {data: process, loading: processLoading, error: processError} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processSelected,
      },
    }
  );

  const { data: surveysData, loading: surveysDataLoading } = useQuery(QUERY_GET_SURVEYS_BY_ID_PROCESS, {
    variables: {
      processId: processSelected
    }
  })

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientSelected, processId: processSelected }, 
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientSelected,
      customFormId: surveySelected,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {
    if(customForm) {
      setCustomFormsList([...customForm?.customForm_by_pk.formGroups]);
    } else {
      setCustomFormsList([]);
    }
  }, [customForm])


  const {patientList} = usePatientList();

  useEffect(() => {
  }, [surveySelected]);

  return (
    <div className="principal-input__container">
      {/* <div className="input-box-time">
        <i className="input-box-time__icon icon zaia-timeline"/>
        <div className="input-content">
          <span className="input-content__label-bold">{t("globally.date")}</span>
          <ZaiaInput
            placeholder={''}
            type="date"
            backgroundColor="#EFF3F6"
            textColor="#36369B"
            placeholderTextColor="#888888"
            activeBorder={false}
            fontSize={"17px"}
            height={"33px"}
            errorOption={false}
            borderRadius={"4px"}
            padding={"5px 10px"}
            getText={(e) => setDateSelected(e)}
            value={dateSelected}
            // disabled={event && editionEvent ? true : false}
          />
        </div>
        <div className="input-content input-content--margin-left">
          <span className="input-content__label-bold">{t("globally.hour")}</span>
          <ZaiaInput
            placeholder={''}
            type="time"
            backgroundColor="#EFF3F6"
            textColor="#36369B"
            placeholderTextColor="#888888"
            activeBorder={false}
            fontSize={"17px"}
            height={"33px"}
            errorOption={false}
            borderRadius={"4px"}
            padding={"5px 10px"}
            getText={(e) => setTimeSelected(e)}
            value={timeSelected}
            // disabled={event && editionEvent ? true : false}
          />
        </div>
        <div className="input-box-time__separator"></div>
        <button className="button-add-favourite">
          <i className="button-add-favourite__icon icon zaia-star-off"/>
        </button>
      </div> */}
      
      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-person"/>
          {t("globally.selectPatient")}
        </div>
        <ZaiaSelect
          type="select"
          name="patient"
          placeholder={t("globally.selectPatient")}
          padding={"12px 25px"}
          borderRadius={"10px"}
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          setValue={(e) => setPatientSelected(e)}
          value={patientSelected}
          items={
            patientList?.map(
              patient => {
                return {
                  value: patient.id, 
                  text: `${patient.name} ${patient.lastname}`
                }
              }
            ).sort(function(a,b){
              return a.text.localeCompare(b.text);
            })
          }
          shadow={false}
          fontSize="15px"
          errorOption={false}
          disabled={patientID? true : false}
          heightList={"300px"}
        />
      </div>

      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-save-book"/>
          {t("globally.selectProgram")}
        </div>
        <ZaiaSelect
          type="select"
          name="userToProcessId"
          placeholder={t("globally.selectProgram")}
          padding={"12px 25px"}
          borderRadius={"10px"}
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          setValue={(e) => setProcessSelected(e)}
          value={processSelected}
          items={userData?.user[0]?.userToProcesses?.map(
            user => {
              return {
                value: user?.process?.id, 
                text: `${P.dataTranslation(user?.process?.processName, language)}`
              }
            }
          ).sort(function(a,b){
            return a.text.localeCompare(b.text);
          })}
          shadow={false}
          fontSize="15px"
          errorOption={false}
          disabled={(!patientSelected || processID)? true : false}
          heightList={"300px"}
        />
      </div>

      <div className="input-box input-box--border">
        <div className="label-and-button">
          <div className="input-box__label">
            <i className="input-box__label-icon icon zaia-add-book"/>
            {t("globally.survey")}
          </div>
        </div>
        <ZaiaSelect
          type="select"
          name="cmpId"
          placeholder={t("outcomes.modalOutcomes.event")}
          padding={"12px 25px"}
          borderRadius={"10px"}
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          heightList={"200px"}
          items={
            surveysData?.customForm.map((survey) => {
              return (
                {
                  value: survey.id,
                  text: P.dataTranslation(survey.customFormName, language),
                }
              ) 
            })
          }
          setValue={(e) => setSurveySelected(e)}
          value={surveySelected}
          shadow={false}
          fontSize="15px"
          disabled={(!patientSelected || !processSelected || surveysData?.customForm === 0)? true : false}
          errorOption={false}
        />
        <div className="shape-container" 
          style={{
            // opacity 0.5
            opacity: (!patientSelected || !processSelected || surveysData?.customForm === 0)? 0.5 : 1
          }}
        >
          <div className="shape">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 25 32" fill="none">
              <path d="M0 31.5C-2.16635e-07 26.544 4.32006 22.5263 9.64912 22.5263L15.3509 22.5263C20.6799 22.5263 25 26.544 25 31.5L25 0.499999C25 5.45603 20.6799 9.47368 15.3509 9.47368L9.64912 9.47368C4.32006 9.47368 -1.13842e-06 5.45603 -1.35505e-06 0.5L0 31.5Z" fill="#6C6BCC"/>
            </svg>
          </div>
        </div>
      </div>
      {/* <div className="input-box input-box--last">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-message-outline"/>
          {t("outcomes.modalOutcomes.addNotes")}
        </div>
        <ZaiaInput
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          fontSize={"15px"}
          placeholder={t("globally.eventDescription")}
          type="text"
          name="description"
          padding={"20px"}
          borderRadius={"14px"}
          textarea={true}
          errorOption={false}
          height={"100%"}
          getText={(e) => setDescriptionSelected(e)}
          value={descriptionSelected}
        />
      </div> */}
    </div>
  )
}


export default ModalAddSurveyData;
