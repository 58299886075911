import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./ChatbotGeneralCase.style"
import { ThemeContext } from 'styled-components';
import { useLazyQuery } from "@apollo/client";
import { CONSULT_GENERAL_CHAT_IN_FLORENCE_MEDPALM } from "../../../../../../graphql/mutations/Chat";
import useSize from "../../../../../../hooks/useSize";
import { useTranslation } from "react-i18next";
import useDoctorData from "../../../../../../hooks/useDoctorData";
import ZaiaLoadingPoints from "../../../../../zaiaComponents/zaiaLoadingPoints";
import ChatbotInput from "../../chatbotInput";
import Message from "../../message";

const bgChatbot = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/chat-background.svg`

function ChatbotGeneralCase() {

  const footerRef = useRef();
  const { height } = useSize(footerRef);
  const element = document.getElementById("chatbot-container");
  const [t] = useTranslation("global");
  const { doctorData } = useDoctorData();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    patientMessage(values.msg, 'doctor');
    sendBotMessage(values.msg, setSubmitting, resetForm);
    autoScroll();
  };

  const sendBotMessage = async (msg, setSubmitting, resetForm) => {    
    try {
      if(resetForm) resetForm();
      if(setSubmitting) setSubmitting(false);
      sendChatbotMessage(msg);
    } catch (error) {
      console.log(error);
    }
  }


  const patientMessage = (msg, type) => {
    let allMessages = messages;
    allMessages.push(
      {
        id: Date.now(),
        message: msg,
        senderName: `${doctorData.name} ${doctorData.lastname}`,
        type: type
      }
    )
    setMessages([...allMessages])
  }

  const autoScroll = () => {
    if(element) element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  const [chatBotGeneral, {loading: chatBotGeneralLoading}] = useLazyQuery(
    CONSULT_GENERAL_CHAT_IN_FLORENCE_MEDPALM,
    {
      fetchPolicy: "network-only"
    }
  );

  const sendChatbotMessage = async (msg) => {
    const {
      data: {
        consult_palm2_prediction_model_from_vertexAI,
      },
    } = await chatBotGeneral({
      variables: {
        prompt: msg,
      },
    });

    if (!chatBotGeneralLoading) {
      setMessages(
        [
          ...messages,
          {
            id: Date.now(),
            message: consult_palm2_prediction_model_from_vertexAI.answer,
            senderName: "Florence",
            type: 'bot',
          }
        ]
      )
      autoScroll()
    }
  }
  
  const [messages, setMessages] = useState([]);

  return (
    <S.ChatbotGeneralCase footerHeight={height}>
      <div className="chatbot-container" id="chatbot-container">
        {
          messages.length === 0 &&
          <div className="chatbot-container__empty">
            <img src={bgChatbot} alt="" className="chatbot-container__empty-image" />
            <div className="chatbot-container__empty-msg">
              {t("chatbotAI.general.letsTalk")}
            </div>
          </div>
        }
        {messages.map((message, index) => (
          <div key={message.id}>
            { 
              <Message
                key={index}
                message={message.message?.replace('0chatgpt', '')}
                senderName={message.senderName}
                option={message.type}
              />
            }
          </div>
        ))}
        { 
          chatBotGeneralLoading
          ? <Message
            senderName={"Florence"}
          >
            <ZaiaLoadingPoints />
          </Message> 
          : null
        }
      </div>
      <div className="chatbot-footer" ref={footerRef}>
        <ChatbotInput
          chatbotDisabled={false}
          handleSubmit={handleSubmit}
        />
      </div>
    </S.ChatbotGeneralCase>
  );

}

export default ChatbotGeneralCase;
