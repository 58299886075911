import React, { useEffect, useState } from "react";
import * as S from "./SurveyList.style.js";
import { useStateValue } from "../../../../../contextAPI/StateProvider.js";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation.js";

// utils
import { Actions } from "../../../../../utils/actionsIds.js";
import * as P from "../../../../../utils/pipes.js";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput/ZaiaInput.jsx";
import SurveyBox from "../surveyBox/SurveyBox.jsx";
import Modal from "../../../../../utils/modal.js";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ALL_SURVEYS_BY_ID_PROCESS_RANGE_DATES } from "../../../../../graphql/queries/Surveys.js";
import useDoctorData from "../../../../../hooks/useDoctorData.js";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process.js";
import PeriodOfTimeSelect from "../periodOfTimeSelect/PeriodOfTimeSelect.jsx";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction/ZaiaReaction.jsx";
import ModalSurveys from "../modalSurveys/ModalSurveys.jsx";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect/ZaiaSelect.jsx";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const zaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;

function SurveyList({
  setSurveySelected
}) {

  const { recordAction } = useRecordNavigation();
  const [{ selectedPatient }, dispatch] = useStateValue();
  const [processSelected, setProcessSelected] = useState(selectedPatient?.userToProcesses[0].process?.id);
  const { i18n: { language }, t } = useTranslation("global");
  const [sentListActive, setSentListActive] = useState(true);  
  const [inactiveListActive, setInactiveListActive] = useState(true);  
  const { doctorData } = useDoctorData();
  const [modalSendSurveyActive, setModalSendSurveyActive] = useState(false);
  const [inputText, setInputText] = useState('');
  const [surveyPreview, setSurveyPreview] = useState();
  const [initialDateRange, setInitialDateRange] = useState();
  const [finishDateRange, setFinishDateRange] = useState();

  const { data: surveysData, loading: surveysDataLoading, error: surveysDataError } = useQuery(QUERY_GET_ALL_SURVEYS_BY_ID_PROCESS_RANGE_DATES, {
    variables: {
      processId: processSelected,
      initialDate: initialDateRange,
      finalDate: finishDateRange
    }
  })
  
  const { data: processData } = useQuery(QUERY_GET_PROCESS_NAMES);
  
  useEffect(() => {
    setProcessSelected(processData?.process.filter((process) => process.processToCustomForms.length > 1)[0]?.id)
  }, [processData]);

  const getFilterText = (text) => {
    return inputText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase())
      : true
  }

  if(!processData) return null;

  return <S.SurveyList>
    <Modal open={surveyPreview}>
      <ModalSurveys
        setModalActive={setSurveyPreview}
        surveyForPreview={surveyPreview}
      />
    </Modal>
    <Modal open={modalSendSurveyActive}>
      <ModalSurveys
        setModalActive={setModalSendSurveyActive}        
      />
    </Modal>
    <div className="header">
      <div className="header__select">
        <div className="principal-selects-container">
          <div className="select-container">
            <div className="select-container__label">
              {t("globally.programName")}
            </div>
            <ZaiaSelect
              type="select"
              setValue={(e) => {
                  recordAction(Actions.OUTCOMES.SELECT_PROGRAM, e);
                  setProcessSelected(e)
                }
              }
              value={processSelected}
              items={
                processData?.process.filter((process) => process.processToCustomForms.length > 1).map((process) => {
                  return {
                    value: process?.id,
                    text: P.dataTranslation(process?.processName, language)
                  }
                })
              }
              shadow={false}
              fontSize="13px"
              backgroundColor={"#36369B"}
              borderRadius={"10px"}
              heightList={"200px"}
              textColor={"#FFFFFF"}
              arrowColor={"#FFFFFF"}
              borderColor={"#36369B"}
              textItemsColor={"#36369B"}
            />
          </div>
        </div>      
      </div>
      <div className="header__options">
        <div className="filter-time">
          <PeriodOfTimeSelect 
            initialDateRange={initialDateRange}
            finishDateRange={finishDateRange}
            setInitialDateRange={setInitialDateRange}
            setFinishDateRange={setFinishDateRange}
          />
        </div>
        <button className="send-survey"
          onClick={() => setModalSendSurveyActive(true)}
        >
          <div className="send-survey__text">
            {t("globally.newSurvey")}
          </div>
          <i className="send-survey__icon icon zaia-icono-agregar"></i>
        </button>
      </div>
    </div>
    
    <div className="labels">
      <div className="survey-label">
        {t("surveys.surveyName")}
      </div>
      <div className="survey-label">
        {t("surveys.surveyData")}
      </div>
      <div className="survey-label">
        {t("globally.quickActions")}
      </div>
    </div>
    {
      // !filterText.trim() &&
      <div className="surveys-section">
        {/* <div className="surveys-section__sent"> */}
          {/* <div className="survey-list-header"
            onClick={() => setSentListActive(!sentListActive)}
          >
            <div className="survey-list-header__left">
              <div className="survey-list-header__point"></div>
              <div className="survey-list-header__name">Sent</div>
              <div className="survey-list-header__number">9</div>
            </div>
            <div className="survey-list-header__right">
              <i className={`survey-list-header__icon ${!sentListActive && "survey-list-header__icon--rotate"} icon zaia-i-down`}></i>
            </div>
          </div> */}
          {
            sentListActive &&
            <SurveysListContainer
              surveys={surveysData?.customForm.filter((survey) => getFilterText(P.dataTranslation(survey.customFormName, language)))}
              surveysError={surveysDataError}
              surveysLoading={surveysDataLoading}
              setSurveySelected={setSurveySelected}
              setSurveyPreview={setSurveyPreview}
            />
          }
      </div>
    } 
  </S.SurveyList>
}


const SurveysListContainer = ({ 
  surveys,
  surveysError,
  surveysLoading,
  setSurveySelected,
  setSurveyPreview
 }) => {
  
  const { i18n: { language }, t } = useTranslation("global");
  
  if(surveysLoading) 
    return (
      <div className="survey-list__reaction">
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        /> 
      </div>
    )

  if(surveysError) 
    return (
      <div className="survey-list__reaction">
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={"Error loading the patients"}
          sizeText="15px"
          widthText="240px"
        />
      </div>
    )

  if(surveys.length === 0) 
    return (
      <div className="survey-list__reaction">
        <ZaiaReaction
          zaiaImg={zaiaEmpty}
          text={t("surveys.noSurveys")}
          // subText={}
          sizeText="33.25px"
          sizeSubText="15px"
          textColor="var(--blue-color)"
          widthImg="210px"
        />
      </div>
    )
    
    return (
      <div className="survey-list">
      {
        surveys.map((survey) => {
          return (
            <div className="survey-item" key={survey.id}>
              {/* {
                survey.DoctorToUserProcessBoards[0] ? (
                  <div className="survey-item__favourite survey-item__favourite--active">
                    <i
                      className="survey-item__favourite-icon icon zaia-star-on"
                      onClick={() =>
                        deleteBoardData(
                          survey.DoctorToUserProcessBoards[0].id
                        )
                      }
                    ></i>
                  </div>
                ) : (
                  <div className="survey-item__favourite">
                    <i
                      className="survey-item__favourite-icon icon zaia-star-off"
                      onClick={() => insertBoardData(survey.id)}
                    ></i>
                  </div>
                )
              } */}
              <div className="survey-item__info">
                <div className="survey-item__info-title">
                  {P.dataTranslation(survey.customFormName, language)}
                </div>
                {/* <div className="survey-item__info-date">
                  <b>12 . 2077. </b>
                  Monday las time sent
                </div> */}
              </div>
              <div className="survey-item__center">
                <div className="survey-item__number-sendings">
                  {t("surveys.sentTo")} 
                  <b> {survey.customFormFrequency.length} </b> 
                  {
                    survey.customFormFrequency.length === 1 
                    ? t("globally.patient")
                    : t("globally.patients")
                  }
                </div>
                <div className="survey-item__number-answers">
                  {t("surveys.answeredTimes")} 
                  <b> {survey.userCustomFormHistories.length} </b> 
                  {
                    survey.userCustomFormHistories.length === 1 
                    ? t("surveys.answeredTime2")
                    : t("surveys.answeredTimes2")
                  }
                </div>
                <div className="survey-item__delicate-results">
                  <div className={`survey-item__delicate-results-icon-container icon ${false && "survey-item__delicate-results-icon-container--alert"}`}>
                    <i className={`survey-item__delicate-results-icon icon ${true ? "zaia-i-check-circle" : "zaia-alert"}`}></i>
                  </div>
                  <div className="survey-item__delicate-results-text">
                    {t("surveys.delicateResults")} <b>0</b>
                  </div>
                </div>
              </div>
              <div className="survey-item__buttons">
                <button className="button-preview"
                  onClick={() => setSurveyPreview(survey.id)}
                >{t("surveys.preview")}</button>
                {/* <button className="button-preview">Sent again</button> */}
                <button className={`button-preview button-preview--last ${survey.userCustomFormHistories.length === 0 && "button-preview--disabled"}`}
                  onClick={() => survey.userCustomFormHistories.length > 0 && setSurveySelected(survey.id)}
                >{t("surveys.results")}</button>
              </div>
            </div>
          )
        })
      }
      <div className="survey-list__no-more">
        {t("surveys.noMoreInfo")}
      </div>
    </div>
  );
};

export default SurveyList;